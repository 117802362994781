import axios from "axios";
import useLocalStorageHandler from "../hooks/useLocalStorageHandler";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

function salesOrdersController() {
  const localStorage = useLocalStorageHandler();
  const getSalesOrders = (
    options: any = { page: 0, size: 10, openOnly: true }
  ) => {
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();

    let requestUrl =
      "sales-orders/listJson/?page=" +
      options["page"] +
      "&size=" +
      options["size"];

    if (options?.searchByCode) {
      requestUrl = requestUrl + "&searchByCode=" + options.searchByCode;
    }
    if (options?.openOnly === true) {
      requestUrl = requestUrl + "&openOnly=true";
    }

    if(options?.orderBy){
      requestUrl = requestUrl + "&orderBy=" + options.orderBy;
    }

    if(options?.orderDir){
      requestUrl = requestUrl + "&orderDir=" + options.orderDir;
    }

    if(options?.includeClientData){
      requestUrl = requestUrl + "&includeClientData=true";
    }
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {}
    );
  };

  return { getSalesOrders };
}

export default salesOrdersController;
