import GenericListJsonService from "../services/genericListJsonService";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
function inventoryEvaluationOrderProductsController() {

  const finish = (data:any) => {
    
    let requestUrl = "inventory-evaluation-order-products/inventory-counting/finish";


    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      data
    );
  }
  return { finish };
}

export default inventoryEvaluationOrderProductsController;
