import { useEffect, useState } from "react";
import irregularityRecordsController from "../../../../../../../../../../barrel/controllers/irregularityRecordsController";
import useFeedbackService from "../../../../../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../../../../../barrel/hooks/useHxfTranslation";
import ProductService from "../../../../../../../../../../barrel/services/productService";
import HxfDigitKeyboard from "../../../../../../../../../CustomElements/HxfDigitKeyboard/HxfDigitKeyboard";
import HxfOverlayLoading from "../../../../../../../../../CustomElements/HxfOverlayLoading/HxfOverlayLoading";

import styles from "./PGTAskOccurrencesView.module.scss";


interface IProps{
    autoConfirmMode?:any;
    irregularityData?:any;

    onClose?:any;
    onConfirm?:any;
}

const PGTAskOccurrencesView = (props:IProps) => {


    const [didMount, setDidMount] = useState(false);
    const [step, setStep] = useState<"ASK_IRREGULARITIES_OCCURRENCES_QTY" | null>(null);
    const {t} = useHxfTranslation();
    const feedbackService = useFeedbackService();

    const [isLoading, setIsLoading] = useState(false);
    const [errorQty, setErrorQty] = useState(false);

    const processExecuteIrregularity = (nrOccurrences:any) => {


        let mapWastes:any = {};
        
        let irregularityData = props?.irregularityData;
        let ignoreProductsWithoutPvs = irregularityData?.resultingWaste_ignoreProductsWithoutPVS === 1;

        if(props?.irregularityData?.resultingWaste === 1){
            /*
            if(props?.irregularityData?.resultingWaste_selfProduct === 1){
                let idProduct = props.productionRecordData.productData.Id_Product;
             
                let arrayPPVVS = [];
                if(props?.productionRecordData?.productionOrderProduct_pvvsData && props?.productionRecordData?.productionOrderProduct_pvvsData?.length > 0){
                    arrayPPVVS = props?.productionRecordData?.productionOrderProduct_pvvsData;
                }

                
                let productKey = ProductService().buildProductKey(idProduct, arrayPPVVS);
                mapWastes[productKey] = {Id_Product:idProduct, productParameterizedVariablesData:arrayPPVVS,Quantity: nrOccurrences};

            }

            if(props?.irregularityData?.resultingWaste_calculateFromBomAndOccurrences === 1){
                let productBom = props?.productBom ? props.productBom : [];

                for(let i = 0; i<productBom.length; i++){
                    let idProduct = productBom[i].Id_ProductChild;
                    let arrayPPVVS = productBom[i]?.arrayProductParameterizedVariables;
                    let qty = productBom[i].Quantity;
                    let productKey = ProductService().buildProductKey(idProduct, arrayPPVVS);

                    let ignoreThisProduct = ignoreProductsWithoutPvs && arrayPPVVS.length === 0;
                    if(ignoreThisProduct){
                        continue;
                    }
                    mapWastes[productKey] = {Id_Product:idProduct, productParameterizedVariablesData:arrayPPVVS,Quantity: qty*nrOccurrences};
                }
            }*/
        }

        let irrObj = {
            Id_Irregularity:props.irregularityData.Id,
            filledIrregularityData:{
                quantity:nrOccurrences,
                mapWastes:mapWastes
            }

        };
        /*
        let idPop = props.productionRecordData.Id_ProductionOrder_Product;
        let idPr = props.productionRecordData.Id;
        */
        let irrOccurrenceObject:any = {
            //Id_Irregularity:props.irregularityData.Id,
            irregularity:irrObj,
            /*Id_ProductionRecord:idPr,
            Id_ProductionOrder_Product:idPop*/
        };
        /*let irregularTransformations = props?.operationProductActionData?.action_config?.adjustedProducts?.[0]?.irregularTransformations;
        if(irregularTransformations){
            irrOccurrenceObject["irregularTransformations"] = irregularTransformations;
        }*/
        setIsLoading(true);
        props.onConfirm(irrOccurrenceObject);
    }

    useEffect(() => {
        if(!didMount){

            setStep("ASK_IRREGULARITIES_OCCURRENCES_QTY");


            if(props?.autoConfirmMode === "OCCURS_ONE_AT_A_TIME"){
                //this specific action alreayd handles the occurrences calculation in the backend
                processExecuteIrregularity(1); 
            }
            setDidMount(true);
        }
    },[didMount]);


    if(step == "ASK_IRREGULARITIES_OCCURRENCES_QTY"){
        return (
            <>
            {isLoading && (
                <HxfOverlayLoading/>
            )}
            <div className={styles.centeredKeyboard}>
                {props.autoConfirmMode !== null ? (
                    <>
                    {t('confirming')}
                    </>
                ) : (
                    <>
                     <HxfDigitKeyboard
                        placeholder={t('occurrences.nr')}
                        defaultValue={""}
                        onChange={(res:any) => {
                            setErrorQty(false);
                        }}
                        showErrorInput={errorQty}
                        onConfirmation={(res:any) => {

                            let willAddIrregularity = true;
                            if (res === "" || res === 0) {
                                willAddIrregularity = false;
                                setErrorQty(true);
                                return;
                            } else if (isNaN(res) || res < 0) {
                                setErrorQty(true);
                                return;
                            }
                        
                        
                            processExecuteIrregularity(res);
                        
                        }}
                        enableButtonOnMount={true}
                    />
                    </>
                )}
               </div>
            </>
        )
    }
    return (<></>)

}

export default PGTAskOccurrencesView