
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
function inventoryEvaluationOrders() {
  const evaluateProduct = (
    options: any = {}
  ) => {
    let requestUrl = "inventory-evaluation-order/evaluate-product";
    if(!options){
        options = {};
      }
      return BarrelWrapper().appPostRequest(
        HxfAppServersService().getBackend() +
        requestUrl,
        { ...options }
      );
  };

  return { evaluateProduct };
}

export default inventoryEvaluationOrders;
