import {
  IonButton,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonSpinner
} from "@ionic/react";
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { ellipsisVerticalCircleOutline, searchCircleOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import productionOrdersController from "../../../../barrel/controllers/productionOrdersController";
import ProductionOrdersDao from "../../../../barrel/dataAccessObjects/ModelObjects/Objects/productionOrdersDao";
import MultiProductionRecordDto from "../../../../barrel/dataTransferObjects/multiProductionRecordDto";
import ProductionRecordDto from "../../../../barrel/dataTransferObjects/productionRecordDto";
import { useCancelToken } from "../../../../barrel/hooks/useCancelToken";
import useFeedbackService from "../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import useLocalStorageHandler from "../../../../barrel/hooks/useLocalStorageHandler";
import usePunchclock from "../../../../barrel/hooks/usePunchclock";
import useStateCallback from "../../../../barrel/hooks/useStateCallback";
import useSyncSettings from "../../../../barrel/hooks/useSyncSettings";
import AvailableWorkTodoService from "../../../../barrel/services/availableWorkTodoService";
import MobileWrapperCommunicationService from "../../../../barrel/services/mobileWrapperCommunicationService";
import ScannablesService from "../../../../barrel/services/ScannablesService";
import {
  IHxfBarcodeScanFormat,
  IHxfBarcodeSettings
} from "../../../../components/HxfBarcodeScanner/HxfBarcodeScanner";
import LoadingSpinnerImg from "../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../../CustomElements/CustomArrowBack/CustomArrowBack";
import CustomUnavailableErrorNotice from "../../../CustomElements/CustomUnavailableErrorNotice/CustomUnavailableErrorNotice";
import ErrorNoticeRequiresNetworkLoadObject from "../../../CustomElements/ErrorNoticeRequiresNetworkLoadObject/ErrorNoticeRequiresNetworkLoadObject";
import HxfInfiniteScroll from "../../../CustomElements/HxfInfiniteScroll/HxfInfiniteScroll";
import { dispatchCustomPopupsStore } from "../../../CustomElements/ProductionUI/CustomPopups/CustomPopupsStore";
import ProgressBar from "../../../CustomElements/ProgressBar/ProgressBar";
import TaskProgressBar from "../../../CustomElements/TaskProgressBar/TaskProgressBar";
import InAppTemplate from "../../../InAppTemplate/InAppTemplate";
import styles from "./ProductionOrdersStandard.module.scss";
import ScanBarcodePopup from "./ScanBarcodePopup/ScanBarcodePopup";
import CachedIcon from '@mui/icons-material/Cached';
import Ripples from "react-ripples";
import OnGoingProductionsPopup from "../../../CustomElements/ProductionUI/CustomPopups/OnGoingProductionsPopup/OnGoingProductionsPopup";
import CustomSearchBar from "../../../CustomElements/CustomSearchBar/CustomSearchBar";
function ProductionOrdersStandard(props: any) {
  const posContainerRef = useRef<any>(null);
  const timerStartSearching:any = useRef(null);
  const RECHECK_TIME_SECONDS = 20000;
  const myLocalStorage = useLocalStorageHandler();
  const { cancelToken, isCancel } = useCancelToken({
    alias: "ProductionOrdersStandard",
  });

  const loadMoreRequestingIndex = useRef(0);
  const feedbackService = useFeedbackService();
  const punchclock = usePunchclock();
  const { sessionState } = useGlobalState();
  const [searchbarInput, setSearchbarInput] = useState("");
  const syncSettings = useSyncSettings();
  const [didMount, setDidMount] = useState(false);
  const {t} = useHxfTranslation();
  const sizePage = 25;
  const [loadedPOS, setLoadedPOS] = useStateCallback(null);
  const loadedWorkerTeamAcessibleProductOperations = useRef(null);
  const loadedPOSRef = useRef<any>(null);
  const loadedOperationsAccessData = useRef<any>(null);
  const productionOrdersDao = ProductionOrdersDao();
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
    useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [showOnGoingPopup, setShowOnGoingPopup] = useState<any>(null);
  const [noProductionOrdersAvailable, setNoProductionOrdersAvailable] = useState(false);
  const productionOrdersSearchFilters = useRef({});
  const timerRecheckRef = useRef<any>(null);

  const [activeProductionsButtonData, setActiveProductionsButtonData] =
    useState<any>(null);
  const [simultaneousOperationPopsData, setSimultaneousOperationPopsData] =
    useState<any>(null);
  const [
    activeMultiProductionsButtonData,
    setActiveMultiProductionsButtonData,
  ] = useState<any>(null);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const infiniteScrollEnabledRef = useRef(false);
  const allowedInfiniteScrollLoadPageRef = useRef(0);
  const [indexReRender, setIndexReRender] = useState(0);

  const [showBarcodeScannerPopup, setShowBarcodeScannerPopup] = useState(false);

  const autoSelScannerSettings = useRef<IHxfBarcodeSettings>({
    useCamera: false,
    useExternalDevice: false,
    allowedScanFormats: [],
  });
  function isHidden(el: any) {
    return el.offsetParent === null;
  }

  const restartSearch = (requestinIndex:any) => {
    loadedPOSRef.current = [];
    setIsLoadingMore(true);
    allowedInfiniteScrollLoadPageRef.current = 0;

    loadMoreProductionOrders(requestinIndex);
  }
  const initiateFilterCode = (val:any) => {
    loadMoreRequestingIndex.current = loadMoreRequestingIndex.current + 1;
    let requestinIndex = loadMoreRequestingIndex.current;
    setSearchbarInput(val);
    clearTimeout(timerStartSearching.current); //stops spam requests
    timerStartSearching.current = setTimeout(
      () => {
        productionOrdersSearchFilters.current = {
          ...productionOrdersSearchFilters.current,
          searchBarFilter: val,
        };
        restartSearch(requestinIndex);
      },
      500,
      val,
      requestinIndex
    );
  };
  const getTodoWork = (obj:any) => {
    let totalTimesDoneIgnoredOverflow = 0;
    let totalTasksDone = 0;
    let totalTasksMustBeDone = 0;
    let operationsAccessData = loadedOperationsAccessData.current;

    let shouldIncludeDailyDistributionData = sessionState?.userSessionData?.operationsDistribution === "DAILY_DISTRIBUTION";
 
    let workerTeamAcessibleProductOperations = loadedWorkerTeamAcessibleProductOperations.current;
  
    let productionOrderProducts = obj?.productionOrderProducts;
    if(productionOrderProducts && operationsAccessData){
      for(let i = 0; i<productionOrderProducts.length; i++){

        let operationsData = productionOrderProducts[i].operationsData;
        let operationsWorkDoneInfo = AvailableWorkTodoService().getWorkAvailableAndWorkDoneInfo(operationsData, operationsAccessData,workerTeamAcessibleProductOperations);
        
        let totalTimesDonePop = 0;
        let totalTimesMustBeDonePop = 0;
      
        if(shouldIncludeDailyDistributionData){
          totalTimesDonePop = operationsWorkDoneInfo.totalTodayTimesDone;
          totalTimesMustBeDonePop = operationsWorkDoneInfo.totalTodayTimesMustBeDone;
  
          totalTasksDone += totalTimesDonePop;
          totalTasksMustBeDone += totalTimesMustBeDonePop;
          totalTimesDoneIgnoredOverflow += operationsWorkDoneInfo.totalTodayTimesDoneIgnoredOverflow;
        }else{
          //default behaviour
          totalTimesDonePop = operationsWorkDoneInfo.totalTimesDone;
          totalTimesMustBeDonePop = operationsWorkDoneInfo.totalTimesMustBeDone;
  
          totalTasksDone += totalTimesDonePop;
          totalTasksMustBeDone += totalTimesMustBeDonePop;
          totalTimesDoneIgnoredOverflow += operationsWorkDoneInfo.totalTimesDoneIgnoredOverflow;
        }
      }
    }

    return {
      totalTimesDoneIgnoredOverflow:totalTimesDoneIgnoredOverflow,
      totalTasksMustBeDone:totalTasksMustBeDone,
      totalTasksDone:totalTasksDone
    }
  }

  const getProperTaskbar = (obj:any) => {
    
    let todoWork = getTodoWork(obj);
    let totalTimesDoneIgnoredOverflow = todoWork.totalTimesDoneIgnoredOverflow;
    let totalTasksMustBeDone = todoWork.totalTasksMustBeDone;
    let totalTasksDone = todoWork.totalTasksDone;

    return (
      <TaskProgressBar  showMinimumFill={true} minimumFillPct={10} data-tasks-done={totalTasksDone} tasksDone={totalTasksDone} data-total-tasks={totalTasksMustBeDone} totalTasks={totalTasksMustBeDone} width={'100%'} progressBarContainerWidth={'121%'} height={20} fontSize={17} fillerRadius={'0px'}/>
    )
  }

  const isNotCompleteButOperationComplete = (obj:any) => {

    if(obj.productionOrder_completePct <= 100){
      let todoWork = getTodoWork(obj);
      let totalTimesDoneIgnoredOverflow = todoWork.totalTimesDoneIgnoredOverflow;
      let totalTasksMustBeDone = todoWork.totalTasksMustBeDone;
      let totalTasksDone = todoWork.totalTasksDone;
      if(totalTasksDone >= totalTasksMustBeDone){
        return true;
      }
    }
    return false;
  }

  const loadMoreProductionOrders = useCallback((requestinIndex = null) => {
    let currentPage = allowedInfiniteScrollLoadPageRef.current;
    //redirPunchclockMode
  
    let shouldIncludeDailyDistributionData = sessionState?.userSessionData?.operationsDistribution === "DAILY_DISTRIBUTION";
    productionOrdersController()
      .getActiveProductionOrders(
        {
          page: currentPage,
          size: sizePage,
          openOnly: true,
          getAllWorkerOnGoingProductions: true,
          getProductionOrdersSimultaneousOperations: true,
          getAllWorkerOnGoingMultiProductions: true,
          getViewRelevantFlags: true,
          includeOperationsAccessData:true,
          includeOperationsWorkToBeDone:true,
          includeDailyDistributionData:shouldIncludeDailyDistributionData,
          workerIdentificationCode:myLocalStorage.getWorkerIdentificationCode(),
          getWorkerTeamAccessibleProductOperations:true,
          includeSubcontracts:sessionState?.userSessionData?.globalSettings?.featureExpander?.operationProductsSubcontracting === 1,
          includePopsOperationsProgressData:true,
          orderBy:'po.priorityIndex,po.dateEnd',
          orderDir:'ASC,ASC',
          ...productionOrdersSearchFilters.current
        },
        cancelToken
      )
      .then((resp) => {
        syncSettings.check(resp);

        if(requestinIndex){
          if(requestinIndex !== loadMoreRequestingIndex.current){
            console.log("Aborted");
            return;
          }
        }
        
        let posData = resp?.data?.response?.data?.return;
        if (!posData) {
          throw "unexpected data posData";
        }
        let requiresPunchclockIn =
          resp?.data?.response?.data?.extra?.requirePunchclockIn;
        if (requiresPunchclockIn) {
          punchclock.redirRequirePunchclockIn();
          return;
        }
      
        let posTotalSize = resp.data.response.data.totalSize;
        if(posTotalSize === 0 && noProductionOrdersAvailable === false){
          setNoProductionOrdersAvailable(true);
        }else if(posTotalSize > 0 && noProductionOrdersAvailable){
          setNoProductionOrdersAvailable(false);
        }
        let posExtraData = resp.data.response.data.extra;

        if (currentPage === 0) {
          //first load only
          //loads the extraData that loads in the backend only when the page = 0
          //this is done to avoid double requests just to gather extra unrelated data
          let allWorkerOnGoingProductionRecords =
            posExtraData?.getAllWorkerOnGoingProductions;

          let productionOrderProductsSimultaneousOperations =
            posExtraData?.getProductionOrderProductsSimultaneousOperations;

          let allowedToWorkOnMultipleProductionsAtTheSameTime = true; //sessionState.userSessionData.allowMinimizeMultipleOperations === 1;
          let hasMinimizedProductionsToFinish = false;

          loadedWorkerTeamAcessibleProductOperations.current = posExtraData?.workerTeamAcessibleProductOperations;
          if (allWorkerOnGoingProductionRecords) {
            /*let workerOnGoingProductionRecords =
              ProductionRecordDto().listProductionRecordsToActiveProductionRecords(
                allWorkerOnGoingProductionRecords
              );*/
            let workerOnGoingProductionRecords = allWorkerOnGoingProductionRecords;
            /*let dispatchData = {
              type: "SHOW_ON_GOING_PRODUCTIONS_POPUP",
              payload: {
                onGoingProductionsPopupData: workerOnGoingProductionRecords,
                onGoingProductionsPopupSettings: { filters:{}}
                //onGoingProductionsPopupSettings: { onCloseGoToPath: "/" },
              },
            };*/

          
            //if is not allowed to work on multiple productions at the same time, we open the popup to continue it right when loading the production orders
            if (
              !allowedToWorkOnMultipleProductionsAtTheSameTime &&
              workerOnGoingProductionRecords.length > 0
            ) {
              //dispatchCustomPopupsStore(dispatchData);

              //since mnimized is not allowed, it instantly loads up the already "fetched" on going prs data
              setShowOnGoingPopup({doNotReloadOnStartup:true, defaultOnGoingPrsData:workerOnGoingProductionRecords});
              hasMinimizedProductionsToFinish = true; //guarantee we do not also open multi-productions popup
            } else {
              setActiveProductionsButtonData({
                onGoingPrs:workerOnGoingProductionRecords,
 
              });
            }
          }

          let allowedScans: IHxfBarcodeScanFormat[] = [];
          autoSelScannerSettings.current = {
            useExternalDevice:
              posExtraData?.flags
                ?.flag_shopfloor_productionOrders_autoSel_useExternal === 1
                ? true
                : false,
            useCamera:
              posExtraData?.flags
                ?.flag_shopfloor_productionOrders_autoSel_useCamera === 1
                ? true
                : false,
            allowedScanFormats: [],
            initCameraOnStartUp: false,
          };

          if (
            autoSelScannerSettings.current.useCamera &&
            !autoSelScannerSettings.current.useExternalDevice
          ) {
            autoSelScannerSettings.current.initCameraOnStartUp = true;
          }
          //checking for auto selection scan
          if (
            posExtraData?.flags
              ?.setting_shopfloor_productionOrders_autoSel_labelScan_barcode39
          ) {
            let scanType: IHxfBarcodeScanFormat = {
              type: "barcode39",
              pattern:
                posExtraData?.flags
                  ?.setting_shopfloor_productionOrders_autoSel_labelScan_barcode39,
            };
            allowedScans.push(scanType);
            //allow fallback method to production order Id only, ex: if it accepts [poid,popid] and doesnt find popid only poid
            if (
              posExtraData?.flags
                ?.setting_shopfloor_productionOrders_autoSel_labelScan_barcode39 !==
              "[productionorder.id]"
            ) {
              let scanType2: IHxfBarcodeScanFormat = {
                type: "barcode39",
                pattern: "[productionorder.id]",
              };
              allowedScans.push(scanType2);
            }
          }

          if (
            posExtraData?.flags
              ?.setting_shopfloor_productionOrders_autoSel_labelScan_barcode128
          ) {
            let scanType: IHxfBarcodeScanFormat = {
              type: "barcode128",
              pattern:
                posExtraData?.flags
                  ?.setting_shopfloor_productionOrders_autoSel_labelScan_barcode128,
            };
            allowedScans.push(scanType);
            //allow fallback method to production order Id only, ex: if it accepts [poid,popid] and doesnt find popid only poid
            if (
              posExtraData?.flags
                ?.setting_shopfloor_productionOrders_autoSel_labelScan_barcode128 !==
              "[productionorder.id]"
            ) {
              let scanType2: IHxfBarcodeScanFormat = {
                type: "barcode128",
                pattern: "[productionorder.id]",
              };
              allowedScans.push(scanType2);
            }
          }

          if (
            posExtraData?.flags
              ?.setting_shopfloor_productionOrders_autoSel_labelScan_qr
          ) {
            let scanType: IHxfBarcodeScanFormat = {
              type: "qrcode",
              pattern:
                posExtraData?.flags
                  ?.setting_shopfloor_productionOrders_autoSel_labelScan_qr,
            };
            allowedScans.push(scanType);

            //allow fallback method to production order Id only, ex: if it accepts [poid,popid] and doesnt find popid only poid
            if (
              posExtraData?.flags
                ?.setting_shopfloor_productionOrders_autoSel_labelScan_qr !==
              "[productionorder.id]"
            ) {
              let scanType2: IHxfBarcodeScanFormat = {
                type: "qrcode",
                pattern: "[productionorder.id]",
              };
              allowedScans.push(scanType2);
            }
          }
          autoSelScannerSettings.current.allowedScanFormats = allowedScans;
          //-----------------

          let allWorkerOnGoingMultiProductionRecords =
            posExtraData?.getAllWorkerOnGoingMultiProductions;

          if (allWorkerOnGoingMultiProductionRecords) {
            let workerOnGoingMultiProductionRecords =
              MultiProductionRecordDto().listMultiProductionRecordsToActiveMultiProductionRecords(
                allWorkerOnGoingMultiProductionRecords
              );
            let dispatchData = {
              type: "SHOW_ON_GOING_MULTI_PRODUCTIONS_POPUP",
              payload: {
                onGoingMultiProductionsPopupData:
                  workerOnGoingMultiProductionRecords,
                onGoingMultiProductionsPopupSettings: { onCloseGoToPath: "/" },
              },
            };

            ///page-redirector
            let openMultisIfExist = props.history.location.state?.onLoadOpenMultis;
  
            //if its not allowed to work on multiple productions at the same time and didnt open the popup for on going productions, open the one for multi productions
            if (
              (!allowedToWorkOnMultipleProductionsAtTheSameTime || openMultisIfExist) &&
              !hasMinimizedProductionsToFinish &&
              workerOnGoingMultiProductionRecords.length > 0
            ) {
              dispatchCustomPopupsStore(dispatchData);
            } else {
              setActiveMultiProductionsButtonData({
                ...dispatchData,
                payload: {
                  ...dispatchData.payload,
                  onGoingMultiProductionsPopupSettings: {
                    onCloseGoToPath: null,
                  },
                },
              });
            }
          }

          if (productionOrderProductsSimultaneousOperations) {
            let dispatchData = {
              type: "SHOW_MULTI_POP_PRODUCTIONS_STARTER_POPUP",
              payload: {
                multiPopProductionsStarterPopupData:
                  productionOrderProductsSimultaneousOperations,
                multiPopProductionsStarterPopupSettings: {
                  onCloseGoToPath: "/feature/production-orders",
                },
              },
            };

            setSimultaneousOperationPopsData(dispatchData);
          }
        }

        let newArrayLoadedPOS: any = [];

        if (loadedPOSRef.current !== null) {
          newArrayLoadedPOS = [...loadedPOSRef.current, ...posData];
        } else {
          newArrayLoadedPOS = posData;
        }
      
        loadedPOSRef.current = newArrayLoadedPOS;

        loadedOperationsAccessData.current = posExtraData?.operationsAccessData;

        let hasMoreElements =
          newArrayLoadedPOS.length < posTotalSize && posData.length !== 0;
        if (hasMoreElements) {
          allowedInfiniteScrollLoadPageRef.current =
            allowedInfiniteScrollLoadPageRef.current + 1;
          infiniteScrollEnabledRef.current = true;
        } else {
          infiniteScrollEnabledRef.current = false;
        }
        if (currentPage === 0) {
          setLoadingPage(false);
        }
        setLoadedPOS(JSON.parse(JSON.stringify(loadedPOSRef.current)));
        setIsLoadingMore(false);
        setIndexReRender(indexReRender + 1);


  
        
      })
      .catch((resp) => {
        console.log("THE ERROR: ", resp);
        if (resp["error"] && resp["error"]["error_type"] == "offline_fail") {
          if (failedToLoadPosError) {
            setFailedToLoadPosError(false);
          }
          setRequiresNetworkLoadError(true);
        } else {
          if (requiresNetworkLoadError) {
            setRequiresNetworkLoadError(false);
          }
          setFailedToLoadPosError(true);
        }
        console.log("FAILED::", resp);

        setLoadingPage(false);
      });

  //eslint-disable-next-line
  }, [
    myLocalStorage,
    cancelToken,
    syncSettings,
    punchclock,
    failedToLoadPosError,
    indexReRender,
    setLoadedPOS,
    requiresNetworkLoadError,
    sessionState.userSessionData.allowMinimizeMultipleOperations,
    sessionState?.userSessionData?.operationsDistribution,
    noProductionOrdersAvailable
  ]);

  useEffect(() => {
    if (!didMount) {
      let containerScrollable = document.getElementById("app-content-container");
      posContainerRef.current = containerScrollable;
      loadMoreProductionOrders();
      setDidMount(true);
    }
  }, [
    didMount,
    failedToLoadPosError,

    productionOrdersDao,
    requiresNetworkLoadError,
    sessionState,
    loadMoreProductionOrders,
  ]);



  return (
    <InAppTemplate>
      {showOnGoingPopup && (
        <>
          <OnGoingProductionsPopup
           executionResponsability={"ALL"}
           onClose={() => {
            setShowOnGoingPopup(null);
           }}
          />
        </>
      )}
      <div style={{marginTop:10}}>
      <CustomArrowBack pushUrl="/home" />
      </div>
      
      <div className={styles.pageTitle}>{t("productionorders")}</div>

      
      {showBarcodeScannerPopup && (
        <ScanBarcodePopup
        freeScannerMode={true}
          title="Scanner"
          visible={true}
          barcodeScannerSettings={autoSelScannerSettings.current}
          onClosedCamera={() => {
            if (
              MobileWrapperCommunicationService().isDeviceMobileWrapper() &&
              autoSelScannerSettings.current.useCamera &&
              !autoSelScannerSettings.current.useExternalDevice
            ) {
              setShowBarcodeScannerPopup(false);
            }
          }}
          onInvalidScan={() => {
            feedbackService.notifyToast("Invalid code scanned","warning");
          }}
          onFinishedScan={(result: any) => {
            console.log("RESULT: ", result);
         
            let format = result.resultFormat;
            let resultScan = result.resultScan;
            let delimiter = null;

            if (format.type === "barcode39") {
              delimiter = ScannablesService().hxfBarcode39Delimiter;
            }
            if (format.type === "barcode128") {
              delimiter = ScannablesService().hxfBarcode128Delimiter;
            }

            if (format.type === "qrcode") {
              delimiter = ScannablesService().hxfQrcodeDelimiter;
            }

            let pattern = format.pattern;
            let splittedPattern = pattern.split(delimiter);

            let indexThatIdentifiesTheProductionOrder = -1;
            for (let i = 0; i < splittedPattern.length; i++) {
              if (splittedPattern[i] === "[productionorder.id]") {
                indexThatIdentifiesTheProductionOrder = i;
                break;
              }
            }

            let indexThatIdentifiesTheProductionOrderProductId = -1;
            for (let i = 0; i < splittedPattern.length; i++) {
              if (splittedPattern[i] === "[productionorder.poproduct.id]") {
                indexThatIdentifiesTheProductionOrderProductId = i;
                break;
              }
            }

            if (indexThatIdentifiesTheProductionOrder !== -1) {
            
              let splittedResult = resultScan.split(delimiter);
              if (splittedResult.length !== splittedPattern.length) {
             
                console.log("Scan does not match the pattern");
                feedbackService.notifyToast("Invalid code scanned","warning");
              } else {
             
                let productionOrderIdentifiableValue =
                  splittedResult[indexThatIdentifiesTheProductionOrder];
                console.log(
                  "Now redir to po with id val",
                  productionOrderIdentifiableValue
                );
                //todo check if it is po ID then simply redirect to the proper url, if its a code then check for production orders by code etc
                let redirLinkBuilder =
                  "/feature/production-order/" +
                  productionOrderIdentifiableValue;

                if (indexThatIdentifiesTheProductionOrderProductId !== -1) {
                  if (
                    splittedResult[
                      indexThatIdentifiesTheProductionOrderProductId
                    ]
                  ) {
                    redirLinkBuilder =
                      redirLinkBuilder +
                      "/po-product/" +
                      splittedResult[
                        indexThatIdentifiesTheProductionOrderProductId
                      ];
                  }
                }

                props.history.push(redirLinkBuilder);
              }
            }else{
              feedbackService.notifyToast("Invalid code scanned","warning");
            }
          }}
          onClosePopup={() => {
            setShowBarcodeScannerPopup(false);
          }}
        />
      )}
      {loadingPage && <LoadingSpinnerImg />}
      {!loadingPage && (
      <div>

        <div className={styles.topSearchbar}>
        
            <div className={styles.customSearchBarContainer}>
                <CustomSearchBar
                value={searchbarInput}
                onResetTextClick={() => {
                  initiateFilterCode("");
                }}
                onChange={(evt:any) => {
                  initiateFilterCode(evt.target.value);
                }}
                iconElement={
                  <div className={styles.searchIconStyles}>
                    <IonIcon icon={searchCircleOutline} />
                  </div>
                }
                placeholder={t('search.productionorder')}
              />
          
          </div>

        </div>


      </div>
      )}
      <div className={styles.topActionsContainer}>
      {!loadingPage &&
          simultaneousOperationPopsData?.payload
            ?.multiPopProductionsStarterPopupData?.length > 0 ? (
            <div className={styles.btnsContainer}>
              <IonButton
                disabled={activeMultiProductionsButtonData?.payload?.onGoingMultiProductionsPopupData?.length > 0}
                className={styles.confirmButton}
                onClick={() => {
                  let dispatchData = simultaneousOperationPopsData;
                  dispatchData.payload.multiPopProductionsStarterPopupData = []; //will refetch on the popup opening
                  dispatchCustomPopupsStore(dispatchData);
                }}
              >
                <IonIcon
                  slot="start"
                  size="large"
                  icon={ellipsisVerticalCircleOutline}
                />
                {t("start.multi.productions")}
              </IonButton>
            </div>
          ) : (
            <></>
          )}
      </div>
            
        {noProductionOrdersAvailable ? (
                <div className={styles.centerAvPo}>
                <Ripples
              
                  onClick={() => {
                    props.history.push({
                      pathname: "/page-redirector",
                      state: { redirectTo: '/feature/production-orders' },
                    });
                  }}
                >
                    <div onClick={() => {
              
                    }}>
                      {isLoadingMore ? (
                        <LoadingSpinnerImg/>
                      ) : ( <>
                        <div className={styles.noPosAvailable}>{t('no.productionorders.found')}</div>
                        <div className={styles.reloadIcon}><CachedIcon fontSize="large"/></div>
                      </>)}

                    </div>
                </Ripples>
              </div>
        ) : (<>
      <div className={styles.productionOrdersContainer}>
        <div
          
          id="productionOrdersSelectionDiv"
          key={"rerender_ref_selection_" + indexReRender}
          className={styles.productionOrdersSelection}
        >
          {!failedToLoadPosError && loadedPOS !== null ? (
            <>
              {activeProductionsButtonData?.onGoingPrs?.length > 0 && (
                <IonCardHeader
                  className={`${styles.elemElement} ${styles.pendingActiveProductions}`}
                  
                  onClick={() => {
                    //OPEN HERE ONGOING
                    setShowOnGoingPopup({});
                    /*
                    let dispatchData = activeProductionsButtonData;
                    dispatchCustomPopupsStore(dispatchData);*/
                  }}
                >
                  <div className={styles.elemBackgroundContainer}>
                    <PrecisionManufacturingIcon />
                  </div>
                  <IonCardSubtitle>
                    <div className={styles.elemElementTitle} style={{display:'flex',alignItems:'center'}}>
                      <span style={{textAlign:'center',borderRadius:25,width:45,backgroundColor:'#344980',color:'white',fontSize:20,padding:5,marginRight:5}}>{
                        activeProductionsButtonData.onGoingPrs.length > 99 ? "99+" : activeProductionsButtonData.onGoingPrs.length
                      }</span>
                      {activeProductionsButtonData.onGoingPrs.length > 1 ? t("minimized.productions.lower") : t('minimized.production.lower')}
                    </div>
                  </IonCardSubtitle>
                  <IonCardTitle className={styles.elemElementDescription}>
                    {t("my.minimized.productions")}
                  </IonCardTitle>
                </IonCardHeader>
              )}
              {activeMultiProductionsButtonData?.payload
                ?.onGoingMultiProductionsPopupData?.length > 0 && (
                <IonCardHeader
                  className={`${styles.elemElement} ${styles.simultaneousOperations}`}
                  onClick={() => {
                    let dispatchData = activeMultiProductionsButtonData;
                    dispatchCustomPopupsStore(dispatchData);
                  }}
                >
                  <div className={styles.elemBackgroundContainer}>
                    <PrecisionManufacturingIcon />
                  </div>
                  <IonCardSubtitle>
                    <div className={styles.elemElementTitle} style={{display:'flex',alignItems:'center'}}>
                      <span style={{textAlign:'center',borderRadius:25,width:45,backgroundColor:'#1f6d50',color:'white',fontSize:20,padding:5,marginRight:5}}>{
                        activeMultiProductionsButtonData?.payload?.onGoingMultiProductionsPopupData?.length > 99 ? "99+" : activeMultiProductionsButtonData?.payload?.onGoingMultiProductionsPopupData?.length
                      }</span>
                      {t("running.multi.productions")}
                    </div>
                  </IonCardSubtitle>
                  <IonCardTitle className={styles.elemElementDescription}>
                  {t("multi.productions")}
                  </IonCardTitle>
                </IonCardHeader>
              )}
              {autoSelScannerSettings?.current?.allowedScanFormats && autoSelScannerSettings?.current?.allowedScanFormats?.length >
                0 &&
                (MobileWrapperCommunicationService().isDeviceMobileWrapper() ||
                  (!MobileWrapperCommunicationService().isDeviceMobileWrapper() &&
                    autoSelScannerSettings.current.useExternalDevice)) && (
                  <IonCardHeader
                    className={`${styles.elemElement}`}
                    onClick={() => {
                      setShowBarcodeScannerPopup(true);
                    }}
                  >
                    <div className={styles.elemBackgroundContainer}>
                    <QrCodeScannerIcon />
                    </div>
                    <IonCardSubtitle>
                      <div className={styles.scannerDeviceElemTitle}><b>{t("scanner")}</b></div>
                    </IonCardSubtitle>
                    <IonCardTitle
                      className={styles.elemElementDescription}
                    ></IonCardTitle>
                  </IonCardHeader>
                )}
{/* isNotCompleteButOperationComplete(obj) ? styles.fadedPo : "" */}
{/* commented because if the PO is not complete 100% then do not show grayed */}
              {loadedPOSRef.current.map((obj: any, index: number) => (
                
                <IonCardHeader
                  key={index}
                  className={`${styles.elemElement}`} 
                  onClick={() => {
                    props.history.push("/feature/production-order/" + obj.Id);
                  }}
                  id={"productionOrderBtnIndex_" + index}
                  data-po-code={obj.code}
                >
                  <div className={styles.elemBackgroundContainer}>
                    <PrecisionManufacturingIcon />
                  </div>
                  <IonCardSubtitle>
                    <div className={styles.progressbarReposition}>
                      <ProgressBar pgValue={obj.productionOrder_completePct} size={"medium"} minFillerWidth={22} customWidth={"118%"} customBorderStyle={{borderRadius:'0px'}}/>
                    </div>
                  
                    <div className={styles.elemElementTitle}>
                      <div className={styles.poCode} >{obj.code}</div>
                      <div>{obj.name}</div>
                      
                    
                    </div>
                  
 
                  </IonCardSubtitle>
                  <IonCardTitle className={styles.elemElementDescription}>
                   
                  </IonCardTitle>
                  <div>
              
                  <div className={styles.otherProgressBar}>
                      <div className={styles.taskbarContainer}>
                        {getProperTaskbar(obj)}
                      </div>
                   
                  </div>
                  </div>
                 
                </IonCardHeader>
              ))}{" "}
            </>
          ) : (
            <></>
          )}

          {isLoadingMore && (
            <IonCardHeader className={styles.elemElement} onClick={() => {}}>
              <div className={styles.elemBackgroundContainer}>
                <PrecisionManufacturingIcon />
              </div>
              <IonCardSubtitle>
                <div className={styles.elemElementTitle}>Loading...</div>
              </IonCardSubtitle>
              <IonCardTitle className={styles.elemElementDescription}>
                <IonSpinner />
              </IonCardTitle>
            </IonCardHeader>
          )}

          <HxfInfiniteScroll
            containerElementRef={posContainerRef}
            requiresContainerHavingScrollbar={true}
            onLoadMore={() => {
              /*let allowingVal = allowedInfiniteScrollLoadPageRef.current + 1;
            
              if(allowingVal > 7){
                return;
              }
              console.log("allowing more :", allowingVal);
               allowedInfiniteScrollLoadPageRef.current = allowedInfiniteScrollLoadPageRef.current + 1;
              */

              if (infiniteScrollEnabledRef.current && !isLoadingMore) {
                setIsLoadingMore(true);
                console.log("CALLED LOADING MORE");
                loadMoreProductionOrders();
              }
            }}
            allowedLoadPageRef={allowedInfiniteScrollLoadPageRef}
          />
        </div>
      </div>
        </>)}


      {failedToLoadPosError ? (
        <div className={styles.errorArea}>
          <CustomUnavailableErrorNotice />
        </div>
      ) : (
        <></>
      )}

      {requiresNetworkLoadError ? (
        <div className={styles.errorArea}>
          <ErrorNoticeRequiresNetworkLoadObject />
        </div>
      ) : (
        <></>
      )}
    </InAppTemplate>
  );
}

export default ProductionOrdersStandard;
