import { IonButton, IonIcon, useIonAlert } from "@ionic/react";
import { TextField } from "@mui/material";
import { add, remove } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import lotsController from "../../../../../../barrel/controllers/lotsController";
import stockManagementController from "../../../../../../barrel/controllers/stockManagementController";
import warehouseLocationsController from "../../../../../../barrel/controllers/warehouseLocationsController";
import warehouseController from "../../../../../../barrel/controllers/warehousesController";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import usePrevious from "../../../../../../barrel/hooks/usePrevious";
import useProductivityScale from "../../../../../../barrel/hooks/useProductivityScale";
import MobileWrapperCommunicationService from "../../../../../../barrel/services/mobileWrapperCommunicationService";
import ProductService from "../../../../../../barrel/services/productService";
import ScannablesService from "../../../../../../barrel/services/ScannablesService";
import { IHxfBarcodeSettings } from "../../../../../../components/HxfBarcodeScanner/HxfBarcodeScanner";
import DefineStockLocationPopup from "../../../../../CustomElements/statePopups/defineStockLocationPopup/DefineStockLocationPopup";
import ScanBarcodePopup from "../../../../../FeaturePages/ProductionOrders/Standard/ScanBarcodePopup/ScanBarcodePopup";
import { useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import HxfSelectorField from "../../../../HxfSelectorField/HxfSelectorField";
import DefineProductQuantityPopup from "../../../../statePopups/defineProductQuantityPopup/DefineProductQuantityPopup";
import TaskProgressBarV2 from "../../../../TaskProgressBarV2/TaskProgressBarV2";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import ActionScreenTopBar from "../Components/ActionScreenTopBar";
import styles from "./ActionScreenAskBomConsumptions.module.scss";
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ActionScreenAskBomConsumptionsConfirmationButtons from "./ActionScreenAskBomConsumptionsConfirmationButtons";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import {
  dispatchActionScreenAskBomConsumptionsStore,
  useActionScreenAskBomConsumptionsStore
} from "./ActionScreenAskBomConsumptionsStore";
import StagedLotsPopup from "./Subpopups/StagedLotsPopup/StagedLotsPopup";
import ExistingLotsPopup from "./Subpopups/ExistingLotsPopup/ExistingLotsPopup";

function ActionScreenAskBomConsumptions(props: any) {
  const [present] = useIonAlert();

  const ALLOW_BARCODESCANNER_TO_ALSO_SCAN_QRCODES = true; //for physical scanners that scan qr and barcodes
  const currentActionScreenManager = useActionScreenManagerStore();
  const autoConfirmAfterDispatchingConsumptions = useRef(false);
  const [didMount, setDidMount] = useState(false);
  const screenStore = useActionScreenAskBomConsumptionsStore();
  const productionOrderSelectionStore = useProductionOrderSelectionStore();
  const [warehouseSearchFilters, setWarehouseSearchFilters] = useState({});
  const [lotSearchFilters, setLotSearchFilters] = useState({});
  const {t} = useHxfTranslation();
  const productivityScale = useProductivityScale();
  const feedbackService = useFeedbackService();
  const triggerFinishScanBarcodeSettings = useRef<any>(null);
  const [triggerOnFinishScan,setTriggerOnFinishScan] = useState(null);
  const prevTriggerOnFinishScan = usePrevious(triggerOnFinishScan);
  const [askStagedLotSelection,setAskStagedLotSelection] = useState<any>(null);
  const [askExistingLotSelection,setAskExistingLotSelection] = useState<any>(null);

  console.log(
    "Production order selection store ",
    productionOrderSelectionStore
  );
  console.log("SCREEN STORE: ", screenStore);
  const [rerenderListKey, setRerenderListKey] = useState(0);

  const [addingFillConsumption, setAddingFillConsumption] = useState<any>(null);


  const findConsumptionByKey  = (mapConsumptions:any, key: string) => {
    for (let i = 0; i < mapConsumptions.length; i++) {
     
      if (mapConsumptions[i].key === key) {
        return mapConsumptions[i];
      }
    }

    return null;
  }

  const replaceConsumptionObjByKey = (
    mapConsumptions: any,
    key: string,
    updatedConsumptionObj: any
  ) => {
    for (let i = 0; i < mapConsumptions.length; i++) {
      if (mapConsumptions[i].key === key) {
        mapConsumptions[i].consumptionObj = updatedConsumptionObj;
      }
    }
    return mapConsumptions;
  }

  const getMapArrayFromBom = useCallback(() => {
    let consumeSpecificMaterial = currentActionScreenManager?.currentActionConfig?.consumeSpecificMaterial === 1 ? true : false;
    // let specificMaterialToConsume = currentActionScreenManager?.currentActionConfig?.selectedProductComponentsConsume;
    
    let currentIdOperationProduct = productionOrderSelectionStore.currentOperation.Id;
    let productBomArray = productionOrderSelectionStore?.product?.arrayProductBom;
    let arrayBom = [];
    for (let i = 0; i < productBomArray.length; i++) {
      if(productBomArray[i]?.Id_OperationProduct === null || productBomArray[i].Id_OperationProduct === currentIdOperationProduct){

        arrayBom.push(
          productBomArray[i]
        );
      }
    }
    
    return arrayBom;
  }, [productionOrderSelectionStore,currentActionScreenManager?.currentActionConfig?.consumeSpecificMaterial]);

  const getMapArrayFixedVariables = (indexBoMElement: number) => {
    let arrayProductBom = getMapArrayFromBom();

    let bomChild = arrayProductBom[indexBoMElement];
    if (bomChild?.productFixedVariables) {
      let bomChildFixedVariables = bomChild["fixedVariables"];
      bomChildFixedVariables = JSON.parse(bomChildFixedVariables);

      let bomChildProductFixedVariables = bomChild["productFixedVariables"];
      bomChildProductFixedVariables = JSON.parse(bomChildProductFixedVariables);

      for (let i = 0; i < bomChildProductFixedVariables.length; i++) {
        let labelBuilder = "";

        let idFixedVariable = parseInt(
          bomChildProductFixedVariables[i].Id_FixedVariable
        );
        for (let j = 0; j < bomChildFixedVariables.length; j++) {
          if (idFixedVariable === parseInt(bomChildFixedVariables[j]["Id"])) {
            labelBuilder =
              "(" +
              bomChildFixedVariables[j]["code"] +
              ") " +
              bomChildFixedVariables[j]["name"];
            break;
          }
        }
        bomChildProductFixedVariables[i]["temp_labelPv"] = labelBuilder;
      }
      return bomChildProductFixedVariables;
    }
    return [];
  };
  
  const getArrayParameterizedVariables = (indexBoMElement: number) => {
    let arrayProductBom = getMapArrayFromBom();

    let bomChild = arrayProductBom[indexBoMElement];
    return bomChild?.arrayProductParameterizedVariables ? bomChild?.arrayProductParameterizedVariables : [];
    if (bomChild?.productComponent_ParameterizedVariables) {
      let bomChildProductComponentParameterizedVariables =
        bomChild["productComponent_ParameterizedVariables"];
      bomChildProductComponentParameterizedVariables = JSON.parse(
        bomChildProductComponentParameterizedVariables
      );

      let bomChildParameterizedVariables = bomChild["parameterizedVariables"];
      bomChildParameterizedVariables = JSON.parse(
        bomChildParameterizedVariables
      );

      let bomChildProductParameterizedVariables =
        bomChild["productParameterizedVariables"];
      bomChildProductParameterizedVariables = JSON.parse(
        bomChildProductParameterizedVariables
      );
      //complete data with parameterized variable label
      for (
        let i = 0;
        i < bomChildProductComponentParameterizedVariables.length;
        i++
      ) {
        let labelBuilder = "";
        let idProductParameterizedVariable = parseInt(
          bomChildProductComponentParameterizedVariables[i]
            .Id_Product_ParameterizedVariable
        );
        let idParameterizedVariable = null;
        for (let j = 0; j < bomChildProductParameterizedVariables.length; j++) {
          if (
            idProductParameterizedVariable ===
            parseInt(bomChildProductParameterizedVariables[j]["Id"])
          ) {
            idParameterizedVariable = parseInt(
              bomChildProductParameterizedVariables[j][
                "Id_ParameterizedVariable"
              ]
            );
            break;
          }
        }

        for (let j = 0; j < bomChildParameterizedVariables.length; j++) {
          if (
            parseInt(bomChildParameterizedVariables[j]["Id"]) ===
            idParameterizedVariable
          ) {
            labelBuilder =
              "(" +
              bomChildParameterizedVariables[j]["code"] +
              ") " +
              bomChildParameterizedVariables[j]["name"];
            break;
          }
        }

        bomChildProductComponentParameterizedVariables[i]["temp_labelPv"] =
          labelBuilder;
      }

      return bomChildProductComponentParameterizedVariables;
    }

    return [];
  };

  const finishAddingFillConsumption = (productKey:any,newFillConsumption:any) => {
    let currentMapFillConsumptions = JSON.parse(JSON.stringify(screenStore.mapFillerConsumptions));

    let currentArrConsumptions = currentMapFillConsumptions?.[productKey] ? currentMapFillConsumptions?.[productKey] : [];

    currentArrConsumptions.push(newFillConsumption);

    currentMapFillConsumptions[productKey] = currentArrConsumptions;

    dispatchActionScreenAskBomConsumptionsStore({
      type: "SET_MAP_FILLER_CONSUMPTIONS",
      payload: currentMapFillConsumptions,
    });

    setAddingFillConsumption(null);
  }
  
  const getShowInsufficientError = (index:any) => {
    let arrayProductBom = getMapArrayFromBom();
    let bomChild = arrayProductBom[index];
    let currentMapConsumptions = screenStore.mapConsumptions;
    let consumption = null;
    let loadedQty = 0;
    consumption = findConsumptionByKey(
      currentMapConsumptions,
      buildKeyForChildProduct(bomChild)
    );

    if (consumption === null) {
      //didnt manually change
      //loadedQty = bomChild.Quantity;
      loadedQty = 0;
    } else {
      loadedQty = consumption.consumptionObj.Quantity;
    }
    return (
      <div>
      {consumption?.consumptionObj
        ?.showInsufficientQuantityAtLocationError === true && (
        <div className={styles.notEnoughtAvailableQtyLabel}>
          {t("not.enought.qty.available.at.this.location")}
        </div>
      )}
      </div>
    )
  }


  const getFilledQuantity = (indexBomChild:number) => {
    let currentActionConfig = currentActionScreenManager?.currentActionConfig;

    let arrayProductBom = getMapArrayFromBom();
    let bomChild = arrayProductBom[indexBomChild];
    let productKey = buildKeyForChildProduct(bomChild);
    let currentMapFillConsumptions = screenStore.mapFillerConsumptions;
    let currentArrConsumptions = currentMapFillConsumptions?.[productKey] ? currentMapFillConsumptions?.[productKey] : [];

    let totalQty = 0;
    for(let i = 0; i<currentArrConsumptions.length; i++){
      totalQty += currentArrConsumptions[i].Quantity;
    }
    
    return totalQty;
  }

  const getRequiredQuantity = (indexBoMChild:number) => {
    let currentActionConfig = currentActionScreenManager?.currentActionConfig;

    let arrayProductBom = getMapArrayFromBom();
    let bomChild = arrayProductBom[indexBoMChild];
    let key = buildKeyForChildProduct(bomChild);
    let currentMapConsumptions = screenStore.mapConsumptions;
    let loadedQty = 0;

    let consumption = null;
    consumption = findConsumptionByKey(
      currentMapConsumptions,
      buildKeyForChildProduct(bomChild)
    );

    if (consumption === null) {
      //didnt manually change
      //loadedQty = bomChild.Quantity;
      loadedQty = 0;
    } else {
      loadedQty = consumption.consumptionObj.Quantity;
    }

    return loadedQty;
  }
  const getFieldQuantity = (indexBoMChild: number) => {
    let currentActionConfig = currentActionScreenManager?.currentActionConfig;

    let arrayProductBom = getMapArrayFromBom();
    let bomChild = arrayProductBom[indexBoMChild];
    let key = buildKeyForChildProduct(bomChild);
    let currentMapConsumptions = screenStore.mapConsumptions;
    let loadedQty = getRequiredQuantity(indexBoMChild);

    let consumption = null;
    consumption = findConsumptionByKey(
      currentMapConsumptions,
      buildKeyForChildProduct(bomChild)
    );

    
    if (currentActionConfig?.allowConsumptionsQuantityChanging) {
      return (
        <div className={styles.qtyFieldContainer}>
          <TextField
            type={"number"}
            disabled={false}
            error={
              consumption?.consumptionObj
                ?.showInsufficientQuantityAtLocationError
            }
            onChange={(evt) => {
              let newQty = evt.target.value;
              if (parseFloat(newQty) <= 0) {
              } else {
                let newMapConsumptions = screenStore.mapConsumptions;

                let consumption = findConsumptionByKey(
                  newMapConsumptions,
                  key
                );
                let exists = true;

                if (consumption === null) {
                  exists = false;
                  consumption = {
                    key: key,
                    consumptionObj: { Id_Product: bomChild["Id_ProductChild"] },
                  };
                }

                consumption.consumptionObj.Quantity = newQty;
                consumption.consumptionObj.showInsufficientQuantityAtLocationError =
                  false;
                if (!exists) {
                  newMapConsumptions.push(consumption);
                } else {
                  newMapConsumptions = replaceConsumptionObjByKey(
                      newMapConsumptions,
                      key,
                      consumption.consumptionObj
                    );
                }

                dispatchActionScreenAskBomConsumptionsStore({
                  type: "SET_MAP_CONSUMPTIONS",
                  payload: [...newMapConsumptions],
                });
              }
            }}
            value={loadedQty}
            label={t("quantity.short")}
            variant="outlined"
          />
        </div>
      );
    } else {
      return loadedQty;
    }
  };

  const processScanSelectionByProductAndLot = (idLot:any,idProduct:any) => {

    let productKey = buildKeyForChildProduct({
      Id_ProductChild:idProduct,
      arrayProductParameterizedVariables:[] // sfs02 does not support ppvvs
    }); 
    
    let productCons:any = null;
    let arrayBom = getMapArrayFromBom();
    for(let i = 0; i<arrayBom.length; i++){
      let bomPCKey = buildKeyForChildProduct(arrayBom[i]);
      if(bomPCKey === productKey){
        productCons = arrayBom[i];
      }
    }

   
    if(!productCons){
      feedbackService.notifyToast(t('product.not.part.of.bom'),"danger");
      return;
    }
    //todo check if product exists in the list if not show error

  
    lotsController().getLot({includeDefaultNoLot:true,includeWhLocation:true},idLot,null).then((res) => {

      let lotObj = res?.data;
      if(!lotObj?.Id){
        throw "unexpected product obj";
      }
     

      let whId = lotObj?.warehouse?.Id_Warehouse;

      let whLocId = lotObj?.warehouse?.warehouseLocation?.Id;

      let newFillCons = {
        productKey:productKey,
        productName:productCons.childProduct_name,
        productCode:productCons.childProduct_code,
        Id_Product:idProduct,
        Id_Lot:idLot,
        Id_Warehouse:whId,
        Id_WarehouseLocation:whLocId,
        lotData:{
          name:lotObj.name,
          code:lotObj.code
        },
        warehouseData:{
          name:lotObj.warehouse.name,
          code:lotObj.warehouse.code
        },
        warehouseLocationData:{
          name:lotObj.warehouse.warehouseLocation.name,
          code:lotObj.warehouse.warehouseLocation.code
        },
        _step:"QUANTITY_SET"
      };
      
      
      //TODO lotdata
      /*
      if(result?.lotData){
        newFillCons.lotData = {code:result?.lotData?.code};
      }
  
      if(result?.warehouseData){
        newFillCons.warehouseData = {code:result?.warehouseData?.code};
      }*/
  
      setAddingFillConsumption(newFillCons);
    }).catch((res) => {

      if(res?.response?.status === 404){
        feedbackService.notifyToast(t("lot.not.found"),"error");
        return;
      }
   
      feedbackService.notifyToast(t("generic.critical.error") + "!","error");
    });

  }
  const getMissingQty = (productKey:any) => {
    let arrayProductBom = getMapArrayFromBom();
    let totalRequiredQty = 0;
    for(let i = 0; i<arrayProductBom.length; i++){
    
      let key = buildKeyForChildProduct(arrayProductBom[i]);
      if(key === productKey){
        totalRequiredQty = arrayProductBom[i].Quantity;
      }
     
    }


    let currentMapFillConsumptions = screenStore.mapFillerConsumptions;
    let currentArrConsumptions = currentMapFillConsumptions?.[productKey] ? currentMapFillConsumptions?.[productKey] : [];

    let addedQty = 0;
    for(let i = 0; i<currentArrConsumptions.length; i++){
      addedQty += currentArrConsumptions[i].Quantity;
    }
    
    let missingQty = 0;
    if(totalRequiredQty <= addedQty){
      return 0;
    }

    return totalRequiredQty - addedQty;
  }

  const getWarehouseLocationConsumptionField = (key: any) => {
    let currentMapConsumptions = screenStore.mapConsumptions;
    let consumption = findConsumptionByKey(
      currentMapConsumptions,
      key
    );

    if (
      consumption !== null &&
      consumption.consumptionObj.Id_WarehouseLocation !== 0
    ) {
      let renderObj = {
        label: consumption.consumptionObj.temp_warehouseLocationLabel,
        value: consumption.consumptionObj.Id_WarehouseLocation,
      };
      return renderObj;
    }
    return null;
  };

  const getWarehouseConsumptionField = (key: any) => {
    let currentMapConsumptions = screenStore.mapConsumptions;
    let consumption = findConsumptionByKey(
      currentMapConsumptions,
      key
    );

    if (consumption !== null && consumption.consumptionObj.Id_Warehouse !== 0) {
      let renderObj = {
        label: consumption.consumptionObj.temp_warehouseLabel,
        value: consumption.consumptionObj.Id_Warehouse,
      };
      return renderObj;
    }
    return null;
  };

  const isPhysScannerEnabled = () => {
    return currentActionScreenManager?.currentActionConfig?.scannerSFS02 === 1;
  }
  
  const getPhysScanner = () => {
      
      let allowedScanFormats:any = [];

      if(currentActionScreenManager?.currentActionConfig?.scannerSFS02 === 1){
        allowedScanFormats.push({"type":"barcode39","pattern":"SFS02-[lot.id]-[product.id]"});
      }

      if(ALLOW_BARCODESCANNER_TO_ALSO_SCAN_QRCODES){
        if(currentActionScreenManager?.currentActionConfig?.scannerSFS02 === 1){
          allowedScanFormats.push({"type":"qrcode","pattern":"SFS02,[lot.id],[product.id]"});
        }
      }
  
      let barcodeSettings:IHxfBarcodeSettings = {
        initCameraOnStartUp:false,
        useCamera: false, //only show camera btn opt if qrcode is enabled
        useExternalDevice: true,
        allowedScanFormats: allowedScanFormats,
      };
  
      triggerFinishScanBarcodeSettings.current = barcodeSettings;
  
      return (
        <ScanBarcodePopup
        freeScannerMode={true}
        title="Scanner"
        ghostMode={true}
        visible={true}
        barcodeScannerSettings={barcodeSettings}
        onClosedCamera={() => {

        }}
        onFinishedScan={(result: any) => {
          setTriggerOnFinishScan(result);//Locate scanbarcodepopup, as scanbarcodepopup works on timer, eventually make this functionality inside hxfbcarcodescanner, create hxfbarcodescanner v2 or something
          //the onFinishedScan finished scan must not be called from a timer, to fix later, or it would be accessing unsynced states
          //this temporarily fixes it 
  
        }}
        onClosePopup={() => {
         
        }}
      />
      )
    
  }

  const getFillerColor = (index:any) => {

    let val1 = getFilledQuantity(index);
    let val2 = getRequiredQuantity(index);
    console.log("FC: ",val1, " ---- ", val2);

    let retVal = val1 >= val2 ? '#a0dca070' : null ;
    console.log("RET VAL: ", retVal);
    return retVal;
  }

  const getLotConsumptionFieldSelector = (key: any) => {
    let currentMapConsumptions = screenStore.mapConsumptions;
    let consumption = findConsumptionByKey(
      currentMapConsumptions,
      key
    );

    if (consumption !== null && consumption.consumptionObj.Id_Lot !== 0) {
      let renderObj = {
        label: consumption.consumptionObj.temp_lotLabel,
        value: consumption.consumptionObj.Id_Lot,
      };
      return renderObj;
    }
    return null;
  };
  const getLotConsumptionField = (key: any) => {
    let currentMapConsumptions = screenStore.mapConsumptions;
    let consumption = findConsumptionByKey(
      currentMapConsumptions,
      key
    );

    if (consumption !== null && consumption.consumptionObj.Id_Lot !== 0) {
      return consumption.consumptionObj.newLotCode;
    }
    return null;
  };
  const forceRerenderView = () => {
    setRerenderListKey(rerenderListKey + 1); //addreses listjsonOptions hxfselector useref detection
  };

  const finishConfirmStoreResult = (storeResult:any) => {
    let currentActionConfig = currentActionScreenManager?.currentActionConfig;
    let moloniDoNotSyncConsSMs = currentActionConfig?.moloniDoNotSyncConsSMs;

    if(moloniDoNotSyncConsSMs === 1){
      storeResult.moloniDoNotSyncConsSMs = 1;
    }
    props.callbackSuccessConfirm(storeResult);
  }

  const clickConfirm = () => {
    let currentActionConfig = currentActionScreenManager?.currentActionConfig;
    let isFillerMode = currentActionScreenManager?.currentActionConfig?.isFillerMode === 1;
    if(isFillerMode){
      let storeResult = confirmFillerConsumptions();
      if(storeResult !== false){
        finishConfirmStoreResult(storeResult);
      }
    }else{
      let allowConsumptionRegardlessOfAvailableStock = currentActionConfig?.allowConsumptionRegardlessOfAvailableStock === 1 ? true : false;
        
      let storeResult = confirmConsumptions();
      if (storeResult !== false) {
        if (allowConsumptionRegardlessOfAvailableStock) {
          finishConfirmStoreResult(storeResult);
        } else {
          let currentMapConsumptions = screenStore.mapConsumptions;
          let preparedProductsFilterList:any = prepareProductsFilterList();
        
          stockManagementController()
            .getProductsStockAtLocation(
              preparedProductsFilterList,
              { lotTraceability: true }
            )
            .then((res) => {
              console.log("response: ", res);
              let stockResponse = res?.data?.response?.data;
              let qty = 0;
              let hasErrors = false;
    
              if (stockResponse) {
                for (let i = 0; i < stockResponse.length; i++) {
                  let resultingQty = 0;
                  let stockProduct = stockResponse[i];
                  if (stockProduct.stockResult) {
                    if (stockProduct.stockResult.length > 1) {
                      //throw error, returned more than expected stock results
                      //exact params passed should return only 1
                      hasErrors = true;
                      throw "error calcx, returned multiple results, some grouping might be missing";
                    }
    
                    resultingQty = stockProduct.stockResult[0].Quantity;
                  }
    
                  if (resultingQty <= 0) {
                    //show error
                    hasErrors = true;
                    currentMapConsumptions[
                      i
                    ].consumptionObj.showInsufficientQuantityAtLocationError =
                      true;
                    console.log("not enough qty");
                  } else {
                    //the current i, matches the local array map in positions.
                    if (
                      resultingQty <
                      parseFloat(
                        currentMapConsumptions[i].consumptionObj.Quantity
                      )
                    ) {
                      hasErrors = true;
                      console.log(
                        "not enough qty " +
                          resultingQty +
                          " < " +
                          currentMapConsumptions[i].consumptionObj.Quantity
                      );
                      currentMapConsumptions[
                        i
                      ].consumptionObj.showInsufficientQuantityAtLocationError =
                        true;
                    } else {
                      console.log("enough qty");
                    }
                  }
                }
                if (!hasErrors) {
                  finishConfirmStoreResult(storeResult);
                } else {
                  dispatchActionScreenAskBomConsumptionsStore({
                    type: "SET_MAP_CONSUMPTIONS",
                    payload: [...currentMapConsumptions],
                  });
                }
              } else {
                //throw error
              }
            })
            .catch((err) => {
              console.log("error: ", err);
            });
          //todo , ajax call check stock if errors show errors else props.callbackSuccessConfirm
        }
      }
    }

  }
  const isWarehouseFieldEnabled = (key: string) => {
    let mapConsumptions = screenStore.mapConsumptions;

    let consumption = findConsumptionByKey(
      mapConsumptions,
      key
    );
    if (
      consumption?.consumptionObj?.Id_WarehouseLocation &&
      consumption.consumptionObj.Id_WarehouseLocation !== 0
    ) {
      return true;
    }
    return false;
  };

  const isLotFieldEnabled = (key: string) => {
    let mapConsumptions = screenStore.mapConsumptions;

    let consumption = findConsumptionByKey(
      mapConsumptions,
      key
    );
    if (
      consumption?.consumptionObj?.Id_Warehouse &&
      consumption.consumptionObj.Id_Warehouse !== 0
    ) {
      return true;
    }
    return false;
  };

  const validationStockLocation = (consumptionObj: any) => {
    let isWhLocDefined = false;
    let isWhDefined = false;
    let isLotDefined = false;

    let validation = {
      warehouseErrors: false,
      warehouseLocationErrors: false,
      lotErrors: false,
    };
    if (
      consumptionObj?.Id_WarehouseLocation &&
      consumptionObj?.Id_WarehouseLocation !== 0
    ) {
      isWhLocDefined = true;
    }
    if (consumptionObj?.Id_Warehouse && consumptionObj?.Id_Warehouse !== 0) {
      isWhDefined = true;
    }
    if (consumptionObj?.Id_Lot && consumptionObj?.Id_Lot !== 0) {
      if(consumptionObj?.Id_Lot === "new" && (!consumptionObj?.newLotCode || consumptionObj?.newLotCode === "")){
        isLotDefined = false;
      }else{
        isLotDefined = true;
      }
      
    }

    let isAtLeastOneDefined = false;
    if (isWhLocDefined || isWhDefined || isLotDefined) {
      isAtLeastOneDefined = true;
    }

    if (isAtLeastOneDefined) {
      if (!isWhLocDefined) {
        validation.warehouseLocationErrors = true;
      }
      if (!isWhDefined) {
        validation.warehouseErrors = true;
      }
      if (!isLotDefined) {
        validation.lotErrors = true;
      }
    }

    return validation;
  };

  const confirmFillerConsumptions = () => {
    let hasErrors = false;
    let hasErrorNotAllFilled = false;
    let mapFillerConsumptions = JSON.parse(JSON.stringify(screenStore.mapFillerConsumptions));
   
    const isRequiredAllFilled = true;

    let arrayBom = getMapArrayFromBom();
    for(let i = 0; i<arrayBom.length; i++){
      let bomPcKey = buildKeyForChildProduct(arrayBom[i]);
      let requiredQuantity = arrayBom[i].Quantity;
      let filledConsumptions = mapFillerConsumptions?.[bomPcKey] ? mapFillerConsumptions?.[bomPcKey] : [];
      let listedQuantity = 0;
      for(let j = 0; j<filledConsumptions.length; j++){
        listedQuantity += filledConsumptions[j].Quantity;
      }

      if(isRequiredAllFilled && listedQuantity < requiredQuantity){
        //error
        hasErrors = true;
        hasErrorNotAllFilled = true;
      }
    }


    dispatchActionScreenAskBomConsumptionsStore({
      type: "SET_MAP_FILLER_CONSUMPTIONS",
      payload: mapFillerConsumptions,
    });

    if(!hasErrors){
      let returnObj:any = {mapFillerConsumptions:mapFillerConsumptions};
      let shouldMultiplyByScale = currentActionScreenManager?.currentActionConfig?.ignoreProductivityScaleMultiplyEnd === 1;
      if(shouldMultiplyByScale){
        returnObj.postMultiplyAllConsumptionsBy = productivityScale.getProductivityScale();
      }
      return returnObj;
    }

    if(hasErrorNotAllFilled){
      feedbackService.notifyToast(t('not.all.products.were.filled'), "error");
    }
    return false;
  }

  const confirmConsumptions = () => {
    let hasErrors = false;

    let returnObj:any = { mapConsumptions: screenStore.mapConsumptions };


    let shouldMultiplyByScale = currentActionScreenManager?.currentActionConfig?.ignoreProductivityScaleMultiplyEnd === 1;
    if(shouldMultiplyByScale){
      returnObj.postMultiplyAllConsumptionsBy = productivityScale.getProductivityScale();
    }

    let mapConsumptions = returnObj.mapConsumptions;
    for (let i = 0; i < mapConsumptions.length; i++) {
      let consumption = mapConsumptions[i];
      if (consumption.consumptionObj.Quantity <= 0) {
        //set show error
        consumption.consumptionObj.hasQuantityError = true;
      }

      let stockLocationValidation = validationStockLocation(
        consumption.consumptionObj
      );
      if (
        stockLocationValidation.warehouseLocationErrors == true ||
        stockLocationValidation.warehouseErrors == true ||
        stockLocationValidation.lotErrors == true
      ) {
        hasErrors = true;
      }
      consumption.consumptionObj = {
        ...consumption.consumptionObj,
        ...stockLocationValidation,
      };
      mapConsumptions[i] = consumption;
    }

    dispatchActionScreenAskBomConsumptionsStore({
      type: "SET_MAP_CONSUMPTIONS",
      payload: [...mapConsumptions],
    });
    returnObj = { mapConsumptions: mapConsumptions }; //updates return obj

    if (hasErrors) {
      return false;
    }

    return returnObj;
  };

  const prepareProductsFilterList = () => {
    let currentMapConsumptions = screenStore.mapConsumptions;

    let preparedProductsFilterList = [];
    for (let i = 0; i < currentMapConsumptions.length; i++) {
      let consumption = currentMapConsumptions[i];
      let prepareStockMovementProductParameterizedVariableValues = consumption.consumptionObj?.arrayProductParameterizedVariables ? consumption.consumptionObj?.arrayProductParameterizedVariables : [];

      /*
      if (
        consumption.consumptionObj.productComponent_ParameterizedVariables !==
        null
      ) {
        prepareStockMovementProductParameterizedVariableValues =
          consumption.consumptionObj.productComponent_ParameterizedVariables;

        prepareStockMovementProductParameterizedVariableValues = JSON.parse(
          prepareStockMovementProductParameterizedVariableValues
        );

        let productParameterizedVariables =
          consumption.consumptionObj.productParameterizedVariables;
        productParameterizedVariables = JSON.parse(
          productParameterizedVariables
        );
        //remove unneeded data
        for (
          let j = 0;
          j < prepareStockMovementProductParameterizedVariableValues.length;
          j++
        ) {
          delete prepareStockMovementProductParameterizedVariableValues[j][
            "Id_ProductComponent"
          ];
          prepareStockMovementProductParameterizedVariableValues[j]["value"] =
            prepareStockMovementProductParameterizedVariableValues[j]["Value"];
          delete prepareStockMovementProductParameterizedVariableValues[j][
            "Value"
          ];
          let idProductParameterizedVariable =
            prepareStockMovementProductParameterizedVariableValues[j][
              "Id_Product_ParameterizedVariable"
            ];
          let idParameterizedVariable = null;

          for (let k = 0; k < productParameterizedVariables.length; k++) {
            if (
              parseInt(productParameterizedVariables[k]["Id"]) ==
              parseInt(idProductParameterizedVariable)
            ) {
              idParameterizedVariable =
                productParameterizedVariables[k]["Id_ParameterizedVariable"];
              break;
            }
          }
        }
      }*/

      let stockLocation = null;

      if (
        consumption?.consumptionObj.Id_WarehouseLocation &&
        consumption?.consumptionObj?.Id_WarehouseLocation !== 0
      ) {
        stockLocation = {
          Id_WarehouseLocation: consumption.consumptionObj.Id_WarehouseLocation,
          Id_Warehouse: consumption.consumptionObj.Id_Warehouse,
          Id_Lot: consumption.consumptionObj.Id_Lot,
        };
      }

      let newElement = {
        key: consumption.key,
        Id_Product: consumption.consumptionObj.Id_Product,
        stockMovement_productParameterizedVariables: prepareStockMovementProductParameterizedVariableValues,
        exactProductParameterizedVariables: true,
        exactProductStockLocation: true,
        stockLocation: {},
      };
      if (stockLocation !== null) {
        newElement = { ...newElement, stockLocation: { ...stockLocation } };
      }
      preparedProductsFilterList.push(newElement);
    }

    return preparedProductsFilterList;
  };


  useEffect(() => {
    if(prevTriggerOnFinishScan !== triggerOnFinishScan && triggerOnFinishScan){
      let result:any = triggerOnFinishScan;
      setTriggerOnFinishScan(null);
      console.log("RESULT: ", result);
     
      let format = result.resultFormat;
      let resultScan = result.resultScan;
      let barcodeSettings = triggerFinishScanBarcodeSettings.current;

      let pattern = format.pattern;

      let scannedWithQrcode = false;
      let scannedWithBarcode = false;
      let delimiter = null;

      let currentAllowedScanFormats = barcodeSettings.allowedScanFormats;

      if(ALLOW_BARCODESCANNER_TO_ALSO_SCAN_QRCODES){
        if(format.type === "barcode39" || format.type === "barcode128"){
        
            for(let i = 0; i<currentAllowedScanFormats.length; i++){
              if(!currentAllowedScanFormats[i]?._skipMatchFindDelimiterProcessing && currentAllowedScanFormats[i].pattern === pattern){
                  //match found
                  let realType = currentAllowedScanFormats[i]?.type;
                
                  //SWAP type and process as if it was scanned with qrcode (in case it was qrcode pattern the one scanned)
                  format.type = realType;
                
              }
            }
          
        }
      }
      if (format.type === "barcode39") {
        delimiter = ScannablesService().hxfBarcode39Delimiter;
        scannedWithBarcode= true;
      }

      if (format.type === "barcode128") {
        delimiter = ScannablesService().hxfBarcode128Delimiter;
        scannedWithBarcode= true;
      }

      if (format.type === "qrcode") {
        delimiter = ScannablesService().hxfQrcodeDelimiter;
        scannedWithQrcode= true;
      }

    
    
      let splittedPattern = pattern.split(delimiter);
     
      let indexThatIdentifiesTheProductId:any = -1;
      let indexThatIdentifiesTheLotId = -1;
   
      let indexThatIdentifiesTheProductionRecord = -1;
      for (let i = 0; i < splittedPattern.length; i++) {
     
        if (splittedPattern[i] === "[product.id]") {
          indexThatIdentifiesTheProductId = i;        
        }

        if (splittedPattern[i] === "[lot.id]") {
          indexThatIdentifiesTheLotId = i;
         
        }
      }
      
      let isIdentifyingByProductAndLot = indexThatIdentifiesTheLotId !== -1 && indexThatIdentifiesTheProductId !== -1;

      if(isIdentifyingByProductAndLot){
        
        //identified with product id and lot id
        let splittedResult = resultScan.split(delimiter);
        let idLot = splittedResult[indexThatIdentifiesTheLotId];
        let idProduct = splittedResult[indexThatIdentifiesTheProductId];

        let arrayProductParameterizedVariables:any = []; //this type of scan does not support PPVVS so set as empty
        

       
        processScanSelectionByProductAndLot(idLot, idProduct);


      }


    }
  },[triggerOnFinishScan]);

  useEffect(() => {


    if(autoConfirmAfterDispatchingConsumptions.current === true){
      
      console.log("triggering autoconfirm");
      autoConfirmAfterDispatchingConsumptions.current = false;
    
      clickConfirm();// eslint-disable-line
    }
  },[screenStore]);// eslint-disable-line

  useEffect(() => {
    if (!didMount) {



      let currentMapConsumptions = screenStore.mapConsumptions;
 
      let currentActionConfig = currentActionScreenManager?.currentActionConfig;

      let shouldIgnoreProductivityScale = currentActionScreenManager?.currentActionConfig?.ignoreProductivityScaleMultiplyEnd === 1;

      let isSilentModeOn = currentActionConfig?.silentMode === 1;
      autoConfirmAfterDispatchingConsumptions.current = isSilentModeOn;

      if (currentMapConsumptions.length === 0) {

        
        //if didnt load to the screenstate from the consumptions map before

        let loadingBaseConsumptionsData = getMapArrayFromBom();
        let newConsumptionsMap = [];
        for (let i = 0; i < loadingBaseConsumptionsData.length; i++) {
          let key = loadingBaseConsumptionsData[i]["key"];
          let idProduct = loadingBaseConsumptionsData[i]["Id_ProductChild"];
          let idProductComponent = loadingBaseConsumptionsData[i]["Id_ProductComponent"];
          let productComponentsParameterizedVariables =
            loadingBaseConsumptionsData[i][
              "productComponent_ParameterizedVariables"
            ]
              ? loadingBaseConsumptionsData[i][
                  "productComponent_ParameterizedVariables"
                ]
              : null;
          let productParameterizedVariables = loadingBaseConsumptionsData[i][
            "productParameterizedVariables"
          ]
            ? loadingBaseConsumptionsData[i]["productParameterizedVariables"]
            : null;
       
          let quantity = parseFloat(loadingBaseConsumptionsData[i]["Quantity"]);
 
    
          let consumeFromSelectedMachine = loadingBaseConsumptionsData?.[i]?.["consumeFromSelectedMachine"] && parseInt(loadingBaseConsumptionsData[i]["consumeFromSelectedMachine"]) === 1 ? 1 : 0;

          //check if product qty was mentioned previously
          //to properly calculate the consumption to show

          if (productionOrderSelectionStore?.production?.persistedStateResult) {
            let persistedStateResultObj = JSON.parse(
              productionOrderSelectionStore.production.persistedStateResult
            );
            if (
              persistedStateResultObj["PRODUCE_PRODUCT_QUANTITY"]?.quantity > 0
            ) {
              quantity =
                quantity *
                persistedStateResultObj["PRODUCE_PRODUCT_QUANTITY"]?.quantity;
            }
          }
          // check if defined product qty on the current operation
          if(shouldIgnoreProductivityScale){
            quantity = quantity*1;
          }else if (props?.productionOperationHandler) {
            let currentPostingObject =
              props.productionOperationHandler.getCurrentPostingProductionDataObject();
            if (currentPostingObject?.PRODUCE_PRODUCT_QUANTITY?.quantity) {
              let postingQty =
                currentPostingObject?.PRODUCE_PRODUCT_QUANTITY?.quantity;
              if (!postingQty) {
                postingQty = 0;
              }
              quantity = quantity * postingQty;
            
            } else {
              //calculate from scape productivity

              quantity = quantity * productivityScale.getProductivityScale();
            }
          }

        
          let currentActionConfig = currentActionScreenManager?.currentActionConfig;

          if (typeof currentActionConfig === "string") {
            currentActionConfig = JSON.parse(currentActionConfig);
          }

          let consumption = {
            key: buildKeyForChildProduct(loadingBaseConsumptionsData[i]),
            consumptionObj: {
              Id_ProductComponent:idProductComponent,
              Id_Product: idProduct,
              productComponent_ParameterizedVariables:
                productComponentsParameterizedVariables,
              productParameterizedVariables: productParameterizedVariables,
              arrayProductParameterizedVariables:loadingBaseConsumptionsData[i].arrayProductParameterizedVariables,
              Quantity: quantity,
              Id_WarehouseLocation:currentActionConfig?.Id_WarehouseLocation ? currentActionConfig.Id_WarehouseLocation : null,
              Id_Warehouse:currentActionConfig?.Id_Warehouse ? currentActionConfig.Id_Warehouse : null,
              Id_Lot:currentActionConfig?.Id_Lot ? currentActionConfig.Id_Lot : null,
              consumeFromSelectedMachine:consumeFromSelectedMachine,
              temp_lot_code:currentActionConfig?.temp_lot_code,
              temp_lot_name:currentActionConfig.temp_lot_name,
              temp_lotLabel:currentActionConfig?.temp_lot_code,
              temp_warehouseLabel:currentActionConfig?.temp_warehouse_code,
              temp_warehouseLocationLabel:currentActionConfig?.temp_warehouseLocation_code,
            },
          };
          newConsumptionsMap.push(consumption);
          
        }
     

        dispatchActionScreenAskBomConsumptionsStore({
          type: "SET_MAP_CONSUMPTIONS",
          payload: [...newConsumptionsMap],
        });
      }

      setDidMount(true);
    }
  }, [
    didMount,
    props.productionOperationHandler,
    getMapArrayFromBom,
    screenStore.mapConsumptions,
    productionOrderSelectionStore,
    productivityScale,
    currentActionScreenManager
  ]);

  const buildKeyForChildProduct = (productComponent:any) => {
    
    let idProduct = productComponent.Id_ProductChild;
    let arrPpvs = productComponent?.arrayProductParameterizedVariables;
    if(!arrPpvs){
      arrPpvs = [];
    }
    let keyStr = idProduct;

    for(let i = 0; i<arrPpvs.length; i++){
      let idProductParameterizedVariable = arrPpvs[i].Id_Product_ParameterizedVariable;
      let value = arrPpvs[i].Value;
      keyStr += "_idppv_" + idProductParameterizedVariable + "_val_" + value;
    }
    return keyStr.toString();
  }

  const updateLotName = (bomChild:any,lotValue:any) => {
    let key = buildKeyForChildProduct(bomChild);
    let newLotValue = lotValue;

    let newMapConsumptions = screenStore.mapConsumptions;
    let consumption = null;
    consumption = findConsumptionByKey(
      newMapConsumptions,
      key
    );
    let exists = true;

    if (consumption === null) {
      exists = false;
      consumption = {
        key: key,
        consumptionObj: {
          Id_Product: 0,
          Quantity: 0,
          Id_WarehouseLocation: 0,
          Id_Warehouse: 0,
          Id_Lot: 0,
          temp_warehouseLocationLabel: "",
          temp_warehouseLabel: "",
          temp_lotLabel: "",
          newLotCode:"",
        },
      };

      consumption.consumptionObj.Id_Product = bomChild.Id_ProductChild;
      consumption.consumptionObj.Quantity = bomChild.Quantity;
    }
    consumption.consumptionObj.lotErrors = false;

    consumption.consumptionObj.Id_Lot = "new";
    consumption.consumptionObj.newLotCode = newLotValue;
    consumption.consumptionObj.warehouseErrors = false;
    consumption.consumptionObj.showInsufficientQuantityAtLocationError =
      false;
    if (!exists) {
      newMapConsumptions.push(consumption);
    } else {
      newMapConsumptions = replaceConsumptionObjByKey(
          newMapConsumptions,
          key,
          consumption.consumptionObj
        );
    }

    dispatchActionScreenAskBomConsumptionsStore({
      type: "SET_MAP_CONSUMPTIONS",
      payload: [...newMapConsumptions],
    });
  }

  const getFillerModeStockLocations = (indexBoMChild:any) => {
    let arrayProductBom = getMapArrayFromBom();
    let bomChild = arrayProductBom[indexBoMChild];
 
    let childKey = buildKeyForChildProduct(bomChild);
    let currentMapConsumptions = screenStore.mapFillerConsumptions;

    let listConsumptions = currentMapConsumptions?.[childKey] ? currentMapConsumptions?.[childKey] : [];

    return (
    <>
      <div className={styles.addBtnContainer}>
          <IonButton
              data-btn-define-cons-location-idx={indexBoMChild}
              onClick={() => {
                setAddingFillConsumption({
                  Quantity:bomChild?.Quantity,
                  productKey:childKey,
                  Id_Product:bomChild.Id_ProductChild,
                  arrayProductParameterizedVariables:bomChild.arrayProductParameterizedVariables,
                  productName:bomChild.childProduct_name,
                  productCode:bomChild.childProduct_code,
                  _step:"STOCK_LOCATION"
                });
              }}
          >
              <IonIcon slot="icon-only" size="large" icon={add} />
          </IonButton>
      </div>

      {listConsumptions.map((objCons:any, indexCons:any) => (
        <div key={"subcons_" + indexBoMChild + "_" + indexCons} className={styles.consumptionLine}>
          <div>
          <IonButton
              color="danger"
              onClick={() => {

                present({
                  cssClass: "my-css",
                  header: t("consumption.cancellation"),
                  message:
                  t("consumption.cancel.confirm.sure"),
                  buttons: [
                    t("cancel"),
                    {
                      text: t("yes"),
                      handler: (d:any) => {
                        let currentMapConsumptions = screenStore.mapFillerConsumptions;
                
                        let listConsumptions = currentMapConsumptions?.[childKey] ? currentMapConsumptions?.[childKey] : [];
        
                        listConsumptions.splice(indexCons, 1);
                        currentMapConsumptions[childKey] = listConsumptions;
                        dispatchActionScreenAskBomConsumptionsStore({
                          type: "SET_MAP_FILLER_CONSUMPTIONS",
                          payload: JSON.parse(JSON.stringify(currentMapConsumptions)),
                        });
        
                      },
                    },
                  ],
                  onDidDismiss: (e:any) => {},
                });
              }}
          >
              <IonIcon slot="icon-only" size="large" icon={remove} />
          </IonButton>
          </div>
          {objCons?.lotData ? (
            <>
              <div className={styles.smallLabel}><b>{t('warehouse')}:</b></div>
              <div>{objCons.warehouseData.code}</div>
              <div className={styles.smallLabel}><b>{t('lot')}:</b></div>
              <div>{objCons.lotData.code}</div> 
            </>
          ) : (<>{t('shopfloor')}</>)}
          <div className={styles.smallLabel}><b>{t('quantity')}:</b></div>
          <div className={styles.addedQty}>+{objCons.Quantity}</div>
        </div>
      ))}
    </>
    )
  }
  const getStockLocationElement = (indexBoMChild: number) => {
    let arrayProductBom = getMapArrayFromBom();
    let bomChild = arrayProductBom[indexBoMChild];
    let childKey = buildKeyForChildProduct(bomChild);
    let currentActionConfig = currentActionScreenManager?.currentActionConfig;

    if (typeof currentActionConfig === "string") {
      currentActionConfig = JSON.parse(currentActionConfig);
    }
    let allowManualStockLocationSelection = false;

    let allowChangeWarehouseLocation = false;
    let allowChangeWarehouse = false;
    let allowChangeLot = false;
 
    //follow documentation for this action
    /*
        if user allowManualStockLocationSelection, allows selecting anywhere in the company where
        the stock is beeing taken from. If not allowManualStockLocationSelection then it selects from the configured
        wh location , wh and lot (if none of those is set then it takes from the default shopfloor location)

        If wh Location is set and ! allowManualStockLocationSelection, it doesnt allow changing wh location but allows other 2
        same logic if whlocation and wh is set. Allows only lot changing
        */
   
    if (currentActionConfig?.allowManualStockLocationSelection) {
      allowManualStockLocationSelection = currentActionConfig?.allowManualStockLocationSelection;
      allowChangeWarehouseLocation = true;
      allowChangeWarehouse = true;
      allowChangeLot = true;
    }

    if (!currentActionConfig?.allowManualStockLocationSelection) {
      /*if (
        currentActionConfig?.Id_WarehouseLocation &&
        currentActionConfig?.Id_WarehouseLocation !== 0
      ) {
        allowChangeWarehouseLocation = false;
        allowChangeWarehouse = true;
        allowChangeLot = true;
      }*/

      if (
        currentActionConfig?.allowManualLotSet
      ) {
   
        allowChangeLot = true;
      }
    }

    let idWarehouseLocation = currentActionConfig?.Id_WarehouseLocation
      ? currentActionConfig?.Id_WarehouseLocation
      : 0;
    let idWarehouse = currentActionConfig?.Id_Warehouse
      ? currentActionConfig?.Id_Warehouse
      : 0;
    let idLot = currentActionConfig?.Id_Lot ? currentActionConfig?.Id_Lot : 0;
   
    let currentMapConsumptions = screenStore.mapConsumptions;

    let consumption = null;
    consumption = findConsumptionByKey(
      currentMapConsumptions,
      childKey
    );
 
  
    return (
      <>
        <div key={"rerenderListKey_" + rerenderListKey}>
          {askStagedLotSelection && (
            <StagedLotsPopup
            topBar={{label:askStagedLotSelection?.productName}}
            selectableLots={askStagedLotSelection?.locationsQuantity}
             onClose={() => {
              setAskStagedLotSelection(null);
             }}
             onConfirm={(result:any) => {
              
              let selectedLot = result?.selectedLot;
              if(!selectedLot){
                return;
              }
              updateLotName(askStagedLotSelection.bomChild,selectedLot.lotData.code);
              setAskStagedLotSelection(null);
             }}
            />
          )}
          {askExistingLotSelection && (
            <ExistingLotsPopup
            topBar={{label:askExistingLotSelection?.productName}}
            selectableLots={askExistingLotSelection?.locationsQuantity}
             onClose={() => {
              setAskExistingLotSelection(null);
             }}
             onConfirm={(result:any) => {
              
              let selectedLot = result?.selectedLot;
              if(!selectedLot){
                return;
              }
              updateLotName(askExistingLotSelection.bomChild,selectedLot.lotData.code);
              setAskExistingLotSelection(null);
             }}
            />
          )}
          {!allowManualStockLocationSelection && idWarehouseLocation === 0 && idWarehouse === 0 && idLot === 0 ? (
            <div>{t("default.shopfloor")}</div>
          ) : (
            <div>
              {allowManualStockLocationSelection ||
              allowChangeWarehouseLocation ? (
                <div className={styles.selectorElem}>
                  <HxfSelectorField
                    error={
                      consumption?.consumptionObj?.warehouseLocationErrors ===
                      true
                    }
                    onChange={(evt, val) => {
                      let key = buildKeyForChildProduct(bomChild);
                      if (val === null) {
                        let currentMapConsumptions =
                          screenStore.mapConsumptions;

                        let consumption = null;
                        consumption = findConsumptionByKey(
                          currentMapConsumptions,
                          key
                        );
                        if (consumption !== null) {
                          consumption.consumptionObj.Id_WarehouseLocation = 0;
                          consumption.consumptionObj.warehouseLocationErrors =
                            false;
                          consumption.consumptionObj.Id_Warehouse = 0;
                          consumption.consumptionObj.warehouseErrors = false;
                          consumption.consumptionObj.Id_Lot = 0;
                          consumption.consumptionObj.lotErrors = false;
                          consumption.consumptionObj.showInsufficientQuantityAtLocationError =
                            false;
                        }

                        currentMapConsumptions = replaceConsumptionObjByKey(
                            currentMapConsumptions,
                            key,
                            consumption.consumptionObj
                          );
                        dispatchActionScreenAskBomConsumptionsStore({
                          type: "SET_MAP_CONSUMPTIONS",
                          payload: [...currentMapConsumptions],
                        });

                        setWarehouseSearchFilters({});
                      } else {
                        let targetLabel = val.label;
                        let targetValue = val.value;

                        let newMapConsumptions = screenStore.mapConsumptions;

                        let consumption = null;
                        consumption = findConsumptionByKey(
                          newMapConsumptions,
                          key
                        );
                        let exists = true;

                        if (consumption === null) {
                          exists = false;
                          consumption = {
                            key: key,
                            consumptionObj: {
                              Id_Product: 0,
                              Quantity: 0,
                              Id_WarehouseLocation: 0,
                              Id_Warehouse: 0,
                              Id_Lot: 0,
                              temp_warehouseLocationLabel: "",
                              temp_warehouseLabel: "",
                              temp_lotLabel: "",
                            },
                          };
                        }
                 
                        if(val?.fetchedData?.code){
                          targetLabel = val.fetchedData.code;
                        }

                        consumption.consumptionObj.Id_Product =
                          bomChild.Id_ProductChild;

                        consumption.consumptionObj.Quantity = bomChild.Quantity;
                        consumption.consumptionObj.Id_WarehouseLocation =
                          targetValue;
                        consumption.consumptionObj.temp_warehouseLocationLabel =
                          targetLabel;
                        consumption.consumptionObj.lotErrors = false;
                        consumption.consumptionObj.showInsufficientQuantityAtLocationError =
                          false;

                        setWarehouseSearchFilters({
                          Id_WarehouseLocation: targetValue,
                        });

                        if (!exists) {
                          newMapConsumptions.push(consumption);
                        } else {
                          newMapConsumptions = replaceConsumptionObjByKey(
                              newMapConsumptions,
                              key,
                              consumption.consumptionObj
                            );
                        }

                        dispatchActionScreenAskBomConsumptionsStore({
                          type: "SET_MAP_CONSUMPTIONS",
                          payload: [...newMapConsumptions],
                        });

                        forceRerenderView();
                      }
                    }}
                    value={getWarehouseLocationConsumptionField(
                      buildKeyForChildProduct(bomChild)
                    )}
                    listJsonFunction={
                      warehouseLocationsController()
                        .getWarehouseLocationsListJson
                    }
                    uniqueId={"whLoc_cons_tgt_stock_" + bomChild["key"]}
                    labelPlaceholder={t("warehouselocation")}
                  />
                  
                </div>
              ) : (
                <div>
                  <b>{t("warehouselocation")}: </b> (
                  {currentActionConfig?.temp_warehouseLocation_code}){" "}
                  {currentActionConfig?.temp_warehouseLocation_name}
                </div>
              )}
              {allowManualStockLocationSelection || allowChangeWarehouse ? (
                <div className={styles.selectorElem}>
                  <HxfSelectorField
                    disabled={!isWarehouseFieldEnabled(buildKeyForChildProduct(bomChild))}
                    error={
                      consumption?.consumptionObj?.warehouseErrors === true
                    }
                    onChange={(evt, val) => {
                      let key = buildKeyForChildProduct(bomChild);
                      if (val === null) {
                        let currentMapConsumptions =
                          screenStore.mapConsumptions;

                        let consumption = null;
                        consumption = findConsumptionByKey(
                          currentMapConsumptions,
                          key
                        );
                        if (consumption !== null) {
                          consumption.consumptionObj.Id_Warehouse = 0;
                          consumption.consumptionObj.warehouseErrors = false;
                          consumption.consumptionObj.Id_Lot = 0;
                          consumption.consumptionObj.lotErrors = false;
                          consumption.consumptionObj.warehouseErrors = false;
                          consumption.consumptionObj.showInsufficientQuantityAtLocationError =
                            false;
                        }

                        currentMapConsumptions = replaceConsumptionObjByKey(
                            currentMapConsumptions,
                            key,
                            consumption.consumptionObj
                          );
                        dispatchActionScreenAskBomConsumptionsStore({
                          type: "SET_MAP_CONSUMPTIONS",
                          payload: [...currentMapConsumptions],
                        });
                      } else {
                        let targetLabel = val.label;
                        let targetValue = val.value;

                        let newMapConsumptions = screenStore.mapConsumptions;

                        let consumption = null;
                        consumption = findConsumptionByKey(
                          newMapConsumptions,
                          key
                        );
                        let exists = true;

                        if (consumption === null) {
                          exists = false;
                          consumption = {
                            key: key,
                            consumptionObj: {
                              Id_Product: 0,
                              Quantity: 0,
                              Id_WarehouseLocation: 0,
                              Id_Warehouse: 0,
                              Id_Lot: 0,
                              temp_warehouseLocationLabel: "",
                              temp_warehouseLabel: "",
                              temp_lotLabel: "",
                            },
                          };
                        }

                        if(val?.fetchedData?.code){
                          targetLabel = val.fetchedData.code;
                        }


                        consumption.consumptionObj.Id_Product =
                          bomChild.Id_ProductChild;
                        consumption.consumptionObj.Quantity = bomChild.Quantity;
                        consumption.consumptionObj.Id_Warehouse = targetValue;
                        consumption.consumptionObj.temp_warehouseLabel =
                          targetLabel;
                        consumption.consumptionObj.warehouseErrors = false;
                        consumption.consumptionObj.lotErrors = false;
                        consumption.consumptionObj.showInsufficientQuantityAtLocationError =
                          false;
                        if (!exists) {
                          newMapConsumptions.push(consumption);
                        } else {
                          newMapConsumptions = replaceConsumptionObjByKey(
                              newMapConsumptions,
                              key,
                              consumption.consumptionObj
                            );
                        }
                        setLotSearchFilters({ Id_Warehouse: targetValue });

                        dispatchActionScreenAskBomConsumptionsStore({
                          type: "SET_MAP_CONSUMPTIONS",
                          payload: [...newMapConsumptions],
                        });

                        forceRerenderView();
                      }
                    }}
                    value={getWarehouseConsumptionField(buildKeyForChildProduct(bomChild))}
                    listJsonFunction={
                      warehouseController().getWarehousesListJson
                    }
                    listJsonOptions={{ ...warehouseSearchFilters }}
                    uniqueId={"wh_cons_tgt_stock_" + buildKeyForChildProduct(bomChild)}
                    labelPlaceholder={t("warehouse")}
                  />
                </div>
              ) : (
                <div>
                  <b>{t("warehouse")}:</b> ({currentActionConfig?.temp_warehouse_name}){" "}
                  {currentActionConfig?.temp_warehouse_name}
                </div>
              )}
              {allowManualStockLocationSelection || (allowChangeLot) ? (
                <div className={styles.selectorElem}>
                  {/* //deprecated
                  <HxfSelectorField
                    disabled={!isLotFieldEnabled(buildKeyForChildProduct(bomChild))}
                    error={consumption?.consumptionObj?.lotErrors === true}
                    onChange={(evt, val) => {
                 
                      let key = buildKeyForChildProduct(bomChild);
                      if (val === null) {
                        let currentMapConsumptions =
                          screenStore.mapConsumptions;
                        let consumption = null;
                        consumption = findConsumptionByKey(
                          currentMapConsumptions,
                          key
                        );
                        if (consumption !== null) {
                          consumption.consumptionObj.Id_Lot = 0;
                          consumption.consumptionObj.lotErrors = false;
                          consumption.consumptionObj.warehouseErrors = false;
                          consumption.consumptionObj.showInsufficientQuantityAtLocationError =
                            false;
                        }
                        currentMapConsumptions = replaceConsumptionObjByKey(
                            currentMapConsumptions,
                            key,
                            consumption.consumptionObj
                          );
                        dispatchActionScreenAskBomConsumptionsStore({
                          type: "SET_MAP_CONSUMPTIONS",
                          payload: [...currentMapConsumptions],
                        });
                      } else {
                        let targetLabel = val.label;
                        let targetValue = val.value;
                        if(val?.fetchedData?.code){
                          targetLabel = val.fetchedData.code;
                        }

                        let newMapConsumptions = screenStore.mapConsumptions;
                        let consumption = null;
                        consumption = findConsumptionByKey(
                          newMapConsumptions,
                          key
                        );
                        let exists = true;

                        if (consumption === null) {
                          exists = false;
                          consumption = {
                            key: key,
                            consumptionObj: {
                              Id_Product: 0,
                              Quantity: 0,
                              Id_WarehouseLocation: 0,
                              Id_Warehouse: 0,
                              Id_Lot: 0,
                              temp_warehouseLocationLabel: "",
                              temp_warehouseLabel: "",
                              temp_lotLabel: "",
                            },
                          };
                        }

                        consumption.consumptionObj.Id_Product =
                          bomChild.Id_ProductChild;
                        consumption.consumptionObj.Quantity = bomChild.Quantity;
                        consumption.consumptionObj.Id_Lot = targetValue;
                        consumption.consumptionObj.temp_lotLabel = targetLabel;
                        consumption.consumptionObj.warehouseErrors = false;
                        consumption.consumptionObj.showInsufficientQuantityAtLocationError =
                          false;
                        if (!exists) {
                          newMapConsumptions.push(consumption);
                        } else {
                          newMapConsumptions = replaceConsumptionObjByKey(
                              newMapConsumptions,
                              key,
                              consumption.consumptionObj
                            );
                        }

                        dispatchActionScreenAskBomConsumptionsStore({
                          type: "SET_MAP_CONSUMPTIONS",
                          payload: [...newMapConsumptions],
                        });

                        forceRerenderView();
                      }
                    }}
                    value={getLotConsumptionFieldSelector(buildKeyForChildProduct(bomChild))}
                    listJsonFunction={lotsController().getLotsListJson}
                    listJsonOptions={{ ...lotSearchFilters }}
                    uniqueId={"lot_cons_tgt_stock_" + buildKeyForChildProduct(bomChild)}
                    labelPlaceholder={t("lot")}
                  />

                  */}

                  <TextField
                  
                      className={styles.input}
                        fullWidth
                        error={consumption?.consumptionObj?.lotErrors === true}
                        onChange={(evt:any) => {
                          updateLotName(bomChild,evt.target.value);
  
                        }}
                        value={
                          getLotConsumptionField(buildKeyForChildProduct(bomChild)) ?  getLotConsumptionField(buildKeyForChildProduct(bomChild)) : ""
                        }
                        label={t("lot")}
                        variant="outlined"
                      
                        
                    />
                    <div style={{display:'flex',flexWrap:'wrap'}}>
                    {currentActionScreenManager?.currentActionConfig?.stagedLotSuggestions === 1 && (
                      <div className={styles.lotToolsContainer}>
                        <div className={styles.lotToolBtn} onClick={() => {
                          let keyCurrent = buildKeyForChildProduct(bomChild);
        
                          let stagedArrayMaterialComponentsData = currentActionScreenManager?.currentActionConfig?._stagedArrayMaterialComponentsData;
                          let cleanStagedLocationQtyForThisProduct = [];
                          if(stagedArrayMaterialComponentsData){
                            for(let i = 0; i<stagedArrayMaterialComponentsData.length; i++){
                              let idProduct = stagedArrayMaterialComponentsData[i].Id_Product;
                              let arrayProductParameterizedVariables = stagedArrayMaterialComponentsData[i]?.arrayProductParameterizedVariables ? stagedArrayMaterialComponentsData[i].arrayProductParameterizedVariables : [];
                              let key = buildKeyForChildProduct({Id_ProductChild:idProduct, arrayProductParameterizedVariables:arrayProductParameterizedVariables});
  
                              if(keyCurrent === key){
                                let locationsQuantity = stagedArrayMaterialComponentsData[i]?.locationsQuantity ? stagedArrayMaterialComponentsData[i].locationsQuantity : [];
                                if(locationsQuantity){
                                  cleanStagedLocationQtyForThisProduct.push(...locationsQuantity)
                                }
                                
                              }
          
                            }
                          }

                          if(cleanStagedLocationQtyForThisProduct.length === 0){
                            feedbackService.notifyToast(t('no.staged.lots.available'),"error");
                            return;
                          }

                          
                          setAskStagedLotSelection({
                            bomChild:bomChild,
                            productName:bomChild?.childProduct_name,
                            locationsQuantity:cleanStagedLocationQtyForThisProduct
                          });
                        }}>
                          <div><DesignServicesIcon/></div>
                          <div>{t('allocated.lots')}</div>
                        </div>
                      </div>
                      )
                    }
                    

                    {currentActionScreenManager?.currentActionConfig?.existingLots === 1 && (
                      <div className={styles.lotToolsContainer}>
                        <div className={styles.lotToolBtnExistingLots} onClick={() => {

                          stockManagementController().checkProductsLotsExistance({
                            lotPrecisionPage:1,
                            productFilter:{
                              Id_Product:bomChild.Id_ProductChild,
                              stockMovement_productParameterizedVariables:bomChild?.arrayProductParameterizedVariables ? bomChild.arrayProductParameterizedVariables : [],
                              exactProductParameterizedVariables:true,
                              exactProductStockLocation:false
                            }
                          }).then((res:any) => {
                              
                              let existingLotsStockData =res?.data?.response?.data?.[0]?.stockResult;
                              if(existingLotsStockData === undefined || existingLotsStockData === null){
                                throw "unexpected res";
                              }
                              if(existingLotsStockData.length === 0){
                                feedbackService.notifyToast(t('no.existing.lots.available.product'), "error");
                                return;
                              }
                              let cleanLocationQtyForThisProduct = [];
                              for(let i = 0; i<existingLotsStockData.length; i++){
                                if(!existingLotsStockData[i]?.Id_Lot){
                                  continue; //skip shopfloor
                                }
                                cleanLocationQtyForThisProduct.push({
                                  Id_WarehouseLocation:existingLotsStockData[i].Id_WarehouseLocation,
                                  Id_Warehouse:existingLotsStockData[i].Id_Warehouse,
                                  Id_Lot:existingLotsStockData[i].Id_Lot,
                                  warehouseLocationData:{
                                    name:existingLotsStockData[i].warehouseLocationName,
                                    code:existingLotsStockData[i].warehouseLocationCode
                                  },
                                  warehouseData:{
                                    name:existingLotsStockData[i].warehouseName,
                                    code:existingLotsStockData[i].warehouseCode
                                  },
                                  lotData:{
                                    name:existingLotsStockData[i].lotName,
                                    code:existingLotsStockData[i].lotCode,
                                    expirationDate: existingLotsStockData[i].lotExpirationDate,
                                   
                                  },
                                  availableQuantity:existingLotsStockData[i].Quantity
                                });
                              }

                              if(cleanLocationQtyForThisProduct.length === 0){
                                feedbackService.notifyToast(t('no.existing.lots.available.product'), "error");
                                return;
                              }
    
                              
                              setAskExistingLotSelection({
                                bomChild:bomChild,
                                productName:bomChild?.childProduct_name,
                                locationsQuantity:cleanLocationQtyForThisProduct
                              });

                          }).catch((res:any) => {
                            feedbackService.notifyToast(t('error.unexpected'), "error");
                          });
                        
                        }}>
                          <div><SavedSearchIcon/></div>
                          <div>{t('existing.lots')}</div>
                        </div>
                      </div>
                      )
                    }
                    </div>
                </div>
              ) : (
                <div>
                  <b>Lot:</b> ({currentActionConfig?.temp_lot_code}){" "}
                  {currentActionConfig?.temp_lot_name}
                </div>
              )}
            </div>
          )}



          {allowManualStockLocationSelection ||
          allowChangeWarehouseLocation ||
          allowChangeWarehouse ||
          allowChangeLot ? (
            <div></div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  };

  if (!productionOrderSelectionStore?.product?.product_BoM) {
    return (
      <>
        <div className={styles.container}>
          <ActionScreenTopBar
            callbackOnClose={props.callbackOnClose}
            title="Bill Of Material - Consumptions"
          />

          <div className={styles.content}>
            <div className={styles.label}>
              {t("no.bom.defined")}..
            </div>
          </div>
        </div>
      </>
    );
  }

  //todo map. productionOrderSelectionStore?.product?.product_BoM
  return (
    <>
      <div className={styles.container}>
        {(currentActionScreenManager?.currentActionConfig?.isFillerMode === 1 && (
          <>
            {addingFillConsumption !== null && addingFillConsumption._step === "STOCK_LOCATION" && (
              <>
                <DefineStockLocationPopup
                  allowLotCreation={false}
                  title={t("stock.location")}
                  currentData={null}
                  onClosePopup={() => {
                    setAddingFillConsumption(null);
                  }}

                  onSuccessConfirm={(result:any) => {
                      if (!result?.Id_Lot && result?.defaultNoLotObj) {
                        result.Id_Lot = result.defaultNoLotObj.Id_Lot;
                        result.temp_lotLabel =
                          "(" +
                          result.defaultNoLotObj.code +
                          ") " +
                          result.defaultNoLotObj.name;
                      }

                      let newFillCons = {...addingFillConsumption,
                        Id_Lot:result.Id_Lot,
                        Id_Warehouse:result.Id_Warehouse,
                        Id_WarehouseLocation:result.Id_WarehouseLocation,
                        _step:"QUANTITY_SET"
                      };
                      
                      if(result?.lotData){
                        newFillCons.lotData = {code:result?.lotData?.code};
                      }

                      if(result?.warehouseData){
                        newFillCons.warehouseData = {code:result?.warehouseData?.code};
                      }

                      setAddingFillConsumption(newFillCons);
                      console.log("success", result);
                  }}
                />
              </>
            )}

            {addingFillConsumption !== null && addingFillConsumption._step === "QUANTITY_SET" && (
                <DefineProductQuantityPopup
             
                  qtyInputPlaceholder={t('quantity')}
                  productData={{name:addingFillConsumption.productName,code:addingFillConsumption.productCode,qtyValue:getMissingQty(addingFillConsumption.productKey)}}
                  title={t("shipping.quantity")}
                  onClosePopup={() => {
                    setAddingFillConsumption(null);
                  }}
                  onSuccessConfirm={(res: any) => {
                      console.log("QT RES: ",res);
                      let resultQuantity = res?.quantityResult ? res.quantityResult : 0;

                      let productKey = addingFillConsumption.productKey;
                      let idProduct = addingFillConsumption?.Id_Product;
                      let arrayProductParameterizedVariables = addingFillConsumption?.arrayProductParameterizedVariables;
                      let idWhLoc = addingFillConsumption?.Id_WarehouseLocation;
                      let idWh = addingFillConsumption?.Id_Warehouse;
                      let idLot = addingFillConsumption?.Id_Lot;

                      let newFillCons:any = {
                        Id_Product:idProduct,
                        arrayProductParameterizedVariables:arrayProductParameterizedVariables,
                        Quantity: resultQuantity,
                        Id_WarehouseLocation:idWhLoc,
                        Id_Warehouse:idWh,
                        Id_Lot:idLot,
                        lotData:addingFillConsumption?.lotData,
                        warehouseData:addingFillConsumption?.warehouseData
                      };
                      
                      finishAddingFillConsumption(productKey, newFillCons); 
                  }}
                />
            )}
          </>
        ))}

        {isPhysScannerEnabled() && (
          <>{getPhysScanner()}</>
        )}
        
        <ActionScreenTopBar
          callbackOnClose={props.callbackOnClose}
          title={t("bom.consumptions")}
        />

        <div className={styles.content}>
          <div className={styles.label}>{t("list.consumptions")}</div>

          <div className={styles.tableArea}>
            <div className={styles.consumptionsTableContainer}>
              <div className={styles.consumptionsTableBody}>
                <div className={styles.consumptionsRow}>
                  <div
                    className={`${styles.consumptionsBodyCellHeader} ${styles.consumptionsLeftHeader}`}
                  >
                    {t("product")}
                  </div>
                  
                  <div className={`${styles.consumptionsBodyCellHeader}`}>
                    {t("quantity.short")}
                  </div>

                  {(currentActionScreenManager?.currentActionConfig?.isFillerMode === 1 || currentActionScreenManager?.currentActionConfig?.allowManualStockLocationSelection === 1 || currentActionScreenManager.currentActionConfig?.allowManualLotSet === 1) && (
                      <div
                      className={`${styles.consumptionsBodyCellHeader} ${styles.consumptionsRightHeader}`}
                    >
                      {t("stock.location")}
                    </div>
                  )}

                </div>

                {getMapArrayFromBom().map((obj: any, index: number) => (
                  <div
                    key={"bom_p_" + index}
                    className={styles.consumptionsRow}
                  >
                    <div
                      className={`${styles.consumptionsBodyCell} ${styles.consumptionsCellProduct} ${styles.consumptionsProductCol}`}
                    >
                      <div className={`${styles.consumptionProductName}`}>
                        <div> ({obj.childProduct_code}) </div>
                        <div> {obj.childProduct_name} </div>
                      </div>
                      {getMapArrayFixedVariables(index).length > 0 && (
                        <div>
                          <div>
                            <div
                              className={
                                styles.consumptionsParameterizedVariablesLabel
                              }
                            >
                              <b>{t("fixedvariables")}:</b>
                            </div>
                            {getMapArrayFixedVariables(index).map(
                              (objPv: any, indexPv: number) => (
                                <div
                                  className={
                                    styles.consumptionsParameterizedVariablesAndValues
                                  }
                                  key={"bom_p_fv_" + indexPv}
                                >
                                  {objPv.temp_labelPv}:{objPv.Value}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}

                      {getArrayParameterizedVariables(index).length > 0 && (
                        <div>
                          <div>
                            <div
                              className={
                                styles.consumptionsParameterizedVariablesLabel
                              }
                            >
                              <b>{t("parameterizedvariables")}:</b>
                            </div>
                            {getArrayParameterizedVariables(index).map(
                              (objPv: any, indexPv: number) => (
                                <div
                                  className={
                                    styles.consumptionsParameterizedVariablesAndValues
                                  }
                                  key={"bom_p_pv_" + indexPv}
                                >
                                  {objPv.name}:{objPv.Value}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}

                      
                    </div>
                    <div className={styles.consumptionsBodyCell}>
                      <div className={styles.consumptionsQtyContainer}>
                        <div>
                        {currentActionScreenManager?.currentActionConfig?.isFillerMode === 1 ? (<>
                          <div className={styles.consumptionsQty}>
                       
                            <TaskProgressBarV2
                                  //fillerColor={isProductCompleted(obj) ? '#a0dca070' : null}
                                  fillerColor={getFillerColor(index)}
                                  width={"125px"}
                                  tasksDone={getFilledQuantity(index)}
                                  totalTasks={getFieldQuantity(index)}
                                  
                            />
                          </div>
                        
                        </>) : (<>
                            <div className={styles.consumptionsQty}>
                            {getFieldQuantity(index)}
                            </div>
                            {getShowInsufficientError(index)}

                        </>)}

                        </div>
  
                      </div>
                    </div>

                    {(currentActionScreenManager?.currentActionConfig?.isFillerMode === 1 || currentActionScreenManager?.currentActionConfig?.allowManualStockLocationSelection === 1 || currentActionScreenManager.currentActionConfig?.allowManualLotSet === 1) && (
                        <div className={styles.consumptionsBodyCell}>
                          {currentActionScreenManager?.currentActionConfig?.isFillerMode === 1 ? (
                            <>{getFillerModeStockLocations(index)}</>
                          ) : (
                            <>{getStockLocationElement(index)}</>
                          )}
                          
                        </div>
                    )}
            
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ActionScreenAskBomConsumptionsConfirmationButtons
        onClickConfirm={() => {
            clickConfirm();
        }}
      />
    </>
  );
}

export default ActionScreenAskBomConsumptions;
