import { IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import CustomArrowBack from "../../CustomElements/CustomArrowBack/CustomArrowBack";
import InAppTemplate from "../../InAppTemplate/InAppTemplate";
import styles from "./GenericScanner.module.scss";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { useState } from "react";
import GenericLabelScannerPopup from "./GenericLabelScannerPopup/GenericLabelScannerPopup";
import HxfOverlayLoading from "../../CustomElements/HxfOverlayLoading/HxfOverlayLoading";
import serializedProductsController from "../../../barrel/controllers/serializedProductsController";
import useLocalStorageHandler from "../../../barrel/hooks/useLocalStorageHandler";
import useFeedbackService from "../../../barrel/hooks/useFeedbackService";
import GLSSFS03Popup from "./GenericLabelScannerPopup/SFS03/GLSSFS03Popup";
function GenericScanner(props:any) {
  const {t} = useHxfTranslation();
  const [showLabelsScanner, setShowLabelsScanner] = useState(false);
  const myLocalStorage = useLocalStorageHandler();
  const [isLoading, setIsLoading] = useState(false);
  const feedbackService = useFeedbackService();
  const [sfs03Popup, setSfs03Popup] = useState<any>(null);

  return (
    <InAppTemplate>
        {isLoading && (
            <HxfOverlayLoading/>
        )}
        {sfs03Popup && (
            <GLSSFS03Popup infoResult={sfs03Popup.infoResult} onClose={() => {
                setSfs03Popup(null);
                setShowLabelsScanner(true);
            }}/>
        )}
      <CustomArrowBack pushUrl="/home" />
      <div className={styles.pageTitle}>{t("genericscanner")}</div>
  
        {showLabelsScanner && (
            <GenericLabelScannerPopup onClose={() => {
                setShowLabelsScanner(false);
            }} onFinishedScan={(result:any) => {
                let scanArgs = result?.scanArgs;
                if(scanArgs){
                    if(scanArgs?.[0].toUpperCase() === "SFS03"){
                        setIsLoading(true);
                        serializedProductsController().getInfo({
                            Id_SerializedProduct:scanArgs?.[1],
                            includeAssociatedProductionConsumptionStockMovements:true,
                            limitConsumptionSMsSingleUnitBom:true,
                            workerCode:myLocalStorage.getWorkerIdentificationCode(),
                            includeProductionConsumptionsProductData:true,
                            includeConsumptionsLocationData:true,
                            includeProductionConsumptionsUomData:true
                        }).then((res:any) => {
                            
                            setSfs03Popup({infoResult: res?.data});
                            setIsLoading(false);
                        }).catch((res:any) => {
                            feedbackService.notifyToast(t('error.unexpected'),'error');
                            setIsLoading(false);
                        });
                    }
                }
                setShowLabelsScanner(false);
            }}/>
        )}
      <div className={styles.optionsContainer}>
        <div className={styles.optionsContent}>
          <IonCardHeader
    
            className={`${styles.elemElement}  ${styles.labelsBtn}`}
            onClick={() => {
                setShowLabelsScanner(true);
            }}
            data-btn={"generic-labels-scanner"}
          >
            <div className={styles.elemBackgroundContainer}>
              <QrCode2Icon />
            </div>

              <div className={'hxfFeatureCornerIconBtn'}>
                <div className={`hxfFeatureCornerIconContainer ${styles.featureLabelsScanIcon}`}>
                  <QrCode2Icon/>
                </div>
            </div>
            <IonCardSubtitle>
              <div className={styles.elemElementTitle}>
                {t("labels.scan.desc")}
              </div>
            </IonCardSubtitle>
            <IonCardTitle className={styles.elemElementDescription}>
              {t("labels.scan")}
            </IonCardTitle>
          </IonCardHeader>

     

        </div>
      </div>
    </InAppTemplate>
  );
}

export default GenericScanner;
