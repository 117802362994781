import { Capacitor, Plugins } from "@capacitor/core";
import { IonContent, IonPage } from "@ionic/react";
import { IonLoading } from "@ionic/react";
import { Button, Tab, TextField } from "@mui/material";
import Tabs from "@mui/material";
import { useEffect, useRef, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useLocation } from "react-router";
import SfsIcon from "../assets/brandlogos/squaredIconWhiteSfs.png";
import GoogleIcon from "../assets/brandlogos/google-icon.svg";
import entityController from "../barrel/controllers/entityController";
import entityUserController from "../barrel/controllers/entityUserController";
import useBarrelOauth from "../barrel/hooks/oauth2/useBarrelOauth";
import useLocalStorageHandler from "../barrel/hooks/useLocalStorageHandler";
import AppSessionService from "../barrel/services/appSessionService";
import entityUsersValidations from "../barrel/validations/entityUsersValidations";
import CustomLoadingAction from "../components/CustomLoadingAction";
import { useGlobalState } from "../GlobalCustomStateManagement/GlobalStateProvider";
import AppOfflineInaccessible from "./ErrorPages/AppOfflineInaccessible/AppOfflineInaccessible";
import styles from "./Login.module.scss";
import HxfAppServersService from "../barrel/services/hxfAppServersService";
import MobileWrapperCommunicationService from "../barrel/services/mobileWrapperCommunicationService";
import useFeedbackService from "../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../barrel/hooks/useHxfTranslation";
import usePrevious from "../barrel/hooks/usePrevious";
import CustomIntegrationService from "./CustomElements/ProductionUI/CustomPopups/CustomIntegrationsPopup/IntegrationServices/CustomIntegrationService";
const Login = (props: any) => {
  let location = useLocation();
  const { handleSuccessLoginResponse } = AppSessionService();
  const { saveRefreshToken, clearWorkerIdentificationCode } =
    useLocalStorageHandler();
  const { sessionState, appState } = useGlobalState();
  const [didMount, setDidMount] = useState(false);
  const state: any = location.state; // Type Casting, then you can get the params passed via router
  //console.log("___",state.from); //Location from if he came from a router while not logged in, after loggin in redirect to this page.
  const feedbackService = useFeedbackService();
  const {t} = useHxfTranslation();
  const redirectTo =
    state !== undefined && state.from !== undefined ? state.from : "";

  const [tabValue, setTabValue] = useState(0);
  const [authFactoSelected, setAuthFactoSelected] = useState(true);

  const [connectButtonEnabled, setConnectButtonEnabled] = useState(true);

  const [entityCodeHasError, setEntityCodeHasError] = useState(false);

  const [showErrorOcurred, setShowErrorOcurred] = useState(false);

  const initialFactoAccessState = {
    entityCode: "",
    Id_Entity: "",
    entityName: "",
    customAccountId: "",
    customAccountPassword: "",
    connectedEntity: false,
    confirmedConnectEntity: false,
    haxify_shopfloorstudio_entity_id: 0,
  };
  const [factoAccessEntityData, setFactoAccessEntityData] = useState(
    initialFactoAccessState
  );

  const [showMainServerUnavailableError, setShowMainServerUnavailableError] = useState(false);
  const [showCustomAccountError, setShowCustomAccountError] = useState(false);
  const [showCustomPasswordError, setShowCustomPasswordError] = useState(false);

  const [isActionLoading, setIsActionLoading] = useState(false);
  const [showUnexpectedError, setShowUnexpectedError] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const barrelOauth = useBarrelOauth();

  const [pressedEnter, setPressedEnter] = useState(0);
  const previousPressedEnter = usePrevious(pressedEnter);
  const [allowEnterConnectEntity, setAllowEnterConnectEntity] = useState(false);
    

  const isShowingConfirmConnect = () => {
    return factoAccessEntityData.connectedEntity && !factoAccessEntityData.confirmedConnectEntity;
  } 
  const isNativeApp = Capacitor.isNativePlatform() ? true : false;
  function a11yProps(index: any) {
    return {
      index: index,
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const resetFactoAccessData = () => {
    setFactoAccessEntityData(initialFactoAccessState);
  };
  const factoConnect = () => {
    if (factoAccessEntityData.entityCode === "") {
      setEntityCodeHasError(true);
      return;
    }

    let entityCodeEntered = factoAccessEntityData.entityCode;
    if (entityCodeEntered.length > 0) {
      let lastChar = entityCodeEntered.charAt(entityCodeEntered.length - 1);
      if (lastChar === " ") {
        entityCodeEntered = entityCodeEntered.slice(0, -1);
      }
    }

    setShowMainServerUnavailableError(false);
    setConnectButtonEnabled(false);
    setShowErrorOcurred(false);
    setEntityCodeHasError(false);
    setIsActionLoading(true);
    entityController()
      .codeConnect(entityCodeEntered)
      .then((reply: any) => {
        setIsActionLoading(false);
        let response = reply?.data;
        let repCode = reply?.data?.code;
        if (!response) {
          //an error occurred
          setShowErrorOcurred(true);
        } else {
          response = response?.response?.data;

          if (repCode === 200 && response?.entityName) {
            //let entityId = response.Id_Entity;
            let entityName = response.entityName;
            let haxify_shopfloorstudio_entity_id =
              response.haxify_shopfloorstudio_entity_id;
            if (!response?.server) {
              throw "Error, no server loaded from response";
            }

            HxfAppServersService().setCurrentServer(response.server);

            setFactoAccessEntityData({
              ...factoAccessEntityData,
              //Id_Entity: entityId,
              entityName: entityName,
              connectedEntity: true,
              confirmedConnectEntity: false,
              haxify_shopfloorstudio_entity_id: haxify_shopfloorstudio_entity_id,
            });
            //connected successfully
            setAllowEnterConnectEntity(true);

            entityUserController().createCsrfToken().then((res) => {
              //created csrftoken successfully
            }).catch((res) => {
              feedbackService.notifyToast(t('generic.critical.error'),"warning",{duration:5000});
            });
            console.log(response);
            //getBackend
          } else {
            //invalid code
            setEntityCodeHasError(true);
            setConnectButtonEnabled(true);
            resetFactoAccessData();
          }
        }
      })
      .catch((error) => {
        setIsActionLoading(false);
        setConnectButtonEnabled(true);
        if (error?.response?.status === 404) {
          //invalid code
          setEntityCodeHasError(true);
          resetFactoAccessData();
        } else {
          //an error occurred
          //setShowErrorOcurred(true);
          setShowMainServerUnavailableError(true);
        
        }
      });
  };

  const handleKeyDown = (event: any) => {
    //if clicked enter or arrow next on mobile
    if (event.keyCode === 13) {
      console.log("Click ");
      factoConnect();
    }
  };

  const handleKeyDownSignIn = (event: any) => {
    if (event.keyCode === 13) {
      console.log("Click ");
      signInButton();
    }
  };

  const showIncorrectLogin = () => {
    setFactoAccessEntityData({
      ...factoAccessEntityData,
      customAccountId: "",
      customAccountPassword: "",
    });
    setShowCustomAccountError(true);
    setShowCustomPasswordError(true);
  };

  const cancelConnect = () => {
    resetFactoAccessData();
    setConnectButtonEnabled(true);
    setAllowEnterConnectEntity(false);
  };

  const continueFactoConnect = () => {
    setAllowEnterConnectEntity(false);
    setFactoAccessEntityData({
      ...factoAccessEntityData,
      confirmedConnectEntity: true,
    });
  };

  const handleNextFocusEnter = (event: any) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      for (let i = index + 1; i < form.elements.length; i++) {
        if (form.elements[i].tagName.toLowerCase() === "input") {
          form.elements[i].focus();
        }
      }

      event.preventDefault();
    }
  };
  const signInButton = () => {
    //basic validations
    let hasErrors = false;
    if (factoAccessEntityData.customAccountId === "") {
      setShowCustomAccountError(true);
      hasErrors = true;
    }

    if (factoAccessEntityData.customAccountPassword === "") {
      setShowCustomPasswordError(true);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }
    setShowCustomAccountError(false);
    setShowCustomPasswordError(false);
    //
    console.log("setting actiuon loading on");
    setIsActionLoading(true);
    setLoadingLogin(true);
    entityUserController()
      .customAccountSignIn(
        factoAccessEntityData.haxify_shopfloorstudio_entity_id,
        factoAccessEntityData.customAccountId,
        factoAccessEntityData.customAccountPassword
      )
      .then((responseObject) => {

     
        if(!responseObject?.data?.response?.Id_Entity){
          throw "unexpected error";
        }
        MobileWrapperCommunicationService().notifyChangedAccessTokenFrontoffice();
        handleSuccessLoginResponse(responseObject);
        console.log("Commented redirectto.");
        
        CustomIntegrationService().startEnabledIntegrations();
        //props.history.push(redirectTo);
      })
      .catch((error) => {

        let errorsArray = error?.response?.data?.response?.issues?.errors;
        if(errorsArray && errorsArray.includes("error.disabled")){
          
          setLoadingLogin(false);
          feedbackService.notifyToast(t('workstation.service.not.enabled.yet'),"warning",{duration:5000});
        }else if (
          entityUsersValidations().responseSignInCheckIncorrectDetails(
            error
          ) !== false
        ) {
          console.log("Incorrect details login error");
          showIncorrectLogin();
          setLoadingLogin(false);
        } else {
          console.log("unexpected error");
          setLoadingLogin(false);
          feedbackService.notifyToast(t('generic.critical.error'),"error");
        }
        setIsActionLoading(false);
      });
  };

  console.log("isshowing to: ", isShowingConfirmConnect());

  useEffect(() => {
    const onKeyDown = (e:any) => {

      if(e.key === "Enter"){
          setPressedEnter(pressedEnter + 1);
      }
    }
  
    document.addEventListener("keydown", onKeyDown, true);

    return function cleanupListener() {
      window.removeEventListener('keydown', onKeyDown);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressedEnter]);

  useEffect(() => {

    if(previousPressedEnter !== undefined && previousPressedEnter !== pressedEnter){
    
      if(isShowingConfirmConnect()){
        continueFactoConnect();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressedEnter,previousPressedEnter]);

  useEffect(() => {
    if (!didMount) {
      console.log("clearing worker id code if exists");
      clearWorkerIdentificationCode();
      setDidMount(true);
    }
  }, [didMount, clearWorkerIdentificationCode]);

  if (!appState.isConnectionAvailable()) {
    return <AppOfflineInaccessible />;
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        {isActionLoading && (
          <CustomLoadingAction
            onTimeout={() => {
              setIsActionLoading(false);
            }}
            timeOutMilliseconds={10000}
          />
        )}


        <div className={styles.contentDiv}>
          <div className={styles.containerDiv}>
            <div style={{ position: "relative" }}>
              <div
                className={styles.exitSo}
                onClick={() => {
                  if(MobileWrapperCommunicationService().isDeviceMobileWrapper()){
                    MobileWrapperCommunicationService().notifyExitShopfloor();
                  }else{
                    window.location.href = "https://shopfloor.studio/";
                  }
                
                }}
              >
                <KeyboardBackspaceIcon /> {t('exit.workstation')}
              </div>
            </div>

            <div className={styles.divAuth}>
              <div className={styles.logoDiv}>
                <div style={{ marginRight: "10px", width: "50px" }}>
                  <img src={SfsIcon} />
                </div>

                <div>Shopfloor</div>
              </div>

              <div>
                {!factoAccessEntityData.connectedEntity && (
                  <div
                    className={`${styles.connectWithSelection} ${styles.divConnectForm}`}
                  >
                    <div className={styles.formContainer}>
                      {showErrorOcurred && (
                        <div className={styles.errorOccurred}>
                          {t("service.temporarily.unav")}
                        </div>
                      )}
                      {showMainServerUnavailableError && (
                        <div className={styles.errorOccurred}>
                          {t("error.main.server.unav")}
                        </div>
                      )}

                      <div className={styles.fieldContainer}>
                        <TextField
                          error={entityCodeHasError}
                          className={styles.connectField}
                          id="entityCodeField"
                          onChange={(evt) => {
                            let newValue = evt.target.value;

                            setFactoAccessEntityData({
                              ...factoAccessEntityData,
                              entityCode: newValue,
                            });
                          }}
                          value={factoAccessEntityData.entityCode}
                          label={t('entity.code')}
                          variant="outlined"
                          onKeyDown={handleKeyDown}
                        />
                      </div>

                      <Button
                        disabled={!connectButtonEnabled}
                        onClick={factoConnect}
                        className={styles.connectButton}
                        id="entityConnectButton"
                      >
                        {t('connect')}
                      </Button>
                      <div>
                        {!MobileWrapperCommunicationService().isDeviceMobileWrapper() ? (
                          <>
                            <div style={{position:'relative', top:'45px',minWidth:190}}>
                                <div style={{display:'flex',justifyContent:'center',flexWrap:'wrap'}}>
                                  <div style={{cursor:'pointer'}} onClick={() => {
                                    window.location.href = "https://play.google.com/store/apps/details?id=com.shopfloorstudio";
                                  }}>
                                    <img alt="playstore" style={{height:60}} src="assets/images/stores/playstore.png"></img>
                                  </div>
                                  <div style={{cursor:'pointer'}} onClick={() => {
                                    window.location.href = "https://apps.apple.com/pt/app/shopfloor-studio/id6447078620";
                                  }}>
                                    <img alt="appstore" style={{position:'relative',top:'10px'}} src="assets/images/stores/appstore.svg"></img>
                                  </div>
                                </div>
                            </div>
                          </>
                        ) : (<></>)}
                      </div>
                      {/*
                      <div className={styles.orContinueWith}>
                        or continue with
                      </div>
                      <div className={styles.socialContinueDiv}>
                        <Button
                          disabled={!connectButtonEnabled}
                          onClick={() => {
                            console.log("Clicked continue with haxify");
                            barrelOauth.openConsentOauthUrl(
                              isNativeApp ? "localapp" : "webapp",
                              process.env.REACT_APP_HAXIFY_OAUTH_CLIENT_APP_ID,
                              isNativeApp
                                ? process.env
                                    .REACT_APP_HAXIFY_OAUTH_SIGNIN_LOCALAPP_REDIRECT_URI
                                : process.env
                                    .REACT_APP_HAXIFY_OAUTH_SIGNIN_WEBAPP_REDIRECT_URI
                            );
                          }}
                          className={styles.socialContinue}
                        >
                          Continue with Haxifyxraee
                        </Button>
                      </div>
                      <div className={styles.socialContinueDiv}>
                        <Button
                          startIcon={<img src={GoogleIcon} />}
                          disabled={!connectButtonEnabled}
                          onClick={() => {
                            console.log("Clicked continue with google");
                          }}
                          className={styles.socialContinue}
                        >
                          Continue with Google
                        </Button>
                      </div>
                        */}
                    </div>
                  </div>
                )}

                {isShowingConfirmConnect() && (
                    <div>
                      <div className={styles.connectWithLabel}>
                        {t("wish.to.connect.to")}{" "}
                        {factoAccessEntityData.entityName}?
                      </div>
                      <div>
                        <div className={styles.connectingConfirmationButton}>
                          <Button
                            id="btnContinue"
                            onClick={() => {
                              
                              continueFactoConnect();
                              
                            }}
                            className={styles.continueButton}
                          >
                            {t("continue")}
                          </Button>
                        </div>
                        <div className={styles.connectingConfirmationButton}>
                          <Button
                            onClick={() => {
                              
                              cancelConnect();
                            }}
                            className={styles.cancelButton}
                          >
                            {t('cancel')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}

                {factoAccessEntityData.connectedEntity &&
                  factoAccessEntityData.confirmedConnectEntity && (
                    <div>
                      <div className={styles.connectWithLabel}>
                        {t('entity')}: {factoAccessEntityData.entityName}
                      </div>
                      <div className={styles.formLogin}>
                        {showUnexpectedError && (
                          <div className={styles.timeoutError}>
                            <p>
                              {t('generic.critical.error')}
                            </p>
                          </div>
                        )}
                        <form>
                          <div className={styles.loginFieldContainer}>
                            <TextField
                              autoFocus={true}
                              error={showCustomAccountError}
                              className={styles.loginField}
                              onKeyDown={handleNextFocusEnter}
                              onChange={(evt) => {
                                setShowCustomAccountError(false);
                                let newValue = evt.target.value;
                                setFactoAccessEntityData({
                                  ...factoAccessEntityData,
                                  customAccountId: newValue,
                                });
                              }}
                              value={factoAccessEntityData.customAccountId}
                              label="User ID"
                              variant="outlined"
                              id="entityUserAccountInput"
                            />
                          </div>

                          <div className={styles.loginFieldContainer}>
                            <TextField
                              id="entityUserPasswordInput"
                              error={showCustomPasswordError}
                              className={styles.loginField}
                              onChange={(evt) => {
                                setShowCustomPasswordError(false);
                                let newValue = evt.target.value;
                                setFactoAccessEntityData({
                                  ...factoAccessEntityData,
                                  customAccountPassword: newValue,
                                });
                              }}
                              value={
                                factoAccessEntityData.customAccountPassword
                              }
                              label={t('password')}
                              variant="outlined"
                              type="password"
                              onKeyDown={handleKeyDownSignIn}
                            />
                          </div>

                          <div className={styles.connectingConfirmationButton}>
                            <Button
                              disabled={loadingLogin}
                              className={styles.continueButton}
                              id="btnSignIn"
                              onClick={() => {
                                signInButton();
                              }}
                            >
                              {t("sign.in")}
                            </Button>
                          </div>
                          <div className={styles.connectingConfirmationButton}>
                            <Button
                              onClick={() => {
                                cancelConnect();
                              }}
                              className={styles.cancelButton}
                            >
                              {t('cancel')}
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            <div className={styles.divApp}>
              <div className={styles.appTitleDesc}>
                <p className={styles.appTitle}>Shopfloor Studio</p>
                <p className={styles.appDesc}>
                  
                </p>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
  /*return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Blank</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Blank</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ExploreContainer />
      </IonContent>
    </IonPage>
  );*/
};

export default Login;
