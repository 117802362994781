import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

function operationProductActionController() {

  const fetchData = (objData:any = {}) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + 'operation-product-action/fetch',
      objData
    );
  }

  return { fetchData };
}

export default operationProductActionController;
