import axios from "axios";
import useLocalStorageHandler from "../hooks/useLocalStorageHandler";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

function receivingOrderProductsController() {
  const localStorage = useLocalStorageHandler();

  const receiveReceivingOrderProducts = (receivingOrderProductsArray: any) => {
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();
    let requestUrl = "receiving-order-products/receive";
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {
        workerCode: currentWorkerCode,
        receivingOrderProducts: receivingOrderProductsArray,
      }
    );
  };

  const addProductReceivingOrderProducts = (opts: any) => {
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();
    let requestUrl = "receiving-order-products/add-product";
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {
        workerCode: currentWorkerCode,
        ...opts,
      }
    );
  };


  const returnShippingOrderProducts = (subcontractedReturnData:any,shippingOrderProductsArray: any,addedProductsArray:any) => {
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();
    let requestUrl = "receiving-order-products/return-shipped-material";
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {
        workerCode: currentWorkerCode,
        shippingOrderProducts: shippingOrderProductsArray,
        addedProducts:addedProductsArray,
        subcontractedReturnData:subcontractedReturnData
      }
    );
  };


  const getReturnedMaterialSubcontractFromShippingOrder = (
    options: any = { page: 0, size: 10, openOnly: true }
  ) => {
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();
    let requestUrl =
      "receiving-orders/listJsonReturnedMaterial/?page=" +
      options["page"] +
      "&size=" +
      options["size"] +
      "&openOnly=" +
      options["openOnly"];

    if (options?.includeBuyOrderData && options.includeBuyOrderData) {
      requestUrl =
        requestUrl + "&includeBuyOrderData=" + options.includeBuyOrderData;
    }
    if (options?.searchByCode) {
      requestUrl = requestUrl + "&searchByCode=" + options.searchByCode;
    }
    if (options?.Id_ShippingOrder && options?.Id_ShippingOrder !== 0) {
      requestUrl =
        requestUrl + "&Id_ShippingOrder=" + options.Id_ShippingOrder;
    }

    if(options?.returnFrom_Id_ShippingOrder && options?.returnFrom_Id_ShippingOrder !== 0){
      requestUrl =
      requestUrl + "&returnFrom_Id_ShippingOrder=" + options.returnFrom_Id_ShippingOrder;
    }

    if (
      options?.getDefaultReceivingLocation &&
      options?.getDefaultReceivingLocation !== "true"
    ) {
      requestUrl =
        requestUrl +
        "&getDefaultReceivingLocation=" +
        options.getDefaultReceivingLocation;
    }

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {}
    );
  };

  const getReceivingOrderProducts = (
    options: any = { page: 0, size: 10, openOnly: true, Id_BuyOrder: 0 }
  ) => {
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();
    let requestUrl =
      "receiving-order-products/listJson/?page=" +
      options["page"] +
      "&size=" +
      options["size"] +
      "&openOnly=" +
      options["openOnly"];

    if (options?.includeBuyOrderData && options.includeBuyOrderData) {
      requestUrl =
        requestUrl + "&includeBuyOrderData=" + options.includeBuyOrderData;
    }
    if (options?.getAutoFillSettings) {
      requestUrl =
        requestUrl + "&getAutoFillSettings=" + options.getAutoFillSettings;
    }

    if (options?.searchByCode) {
      requestUrl = requestUrl + "&searchByCode=" + options.searchByCode;
    }
    if (options?.Id_ReceivingOrder && options?.Id_ReceivingOrder !== 0) {
      requestUrl =
        requestUrl + "&Id_ReceivingOrder=" + options.Id_ReceivingOrder;
    }

    if (
      options?.getDefaultReceivingLocation &&
      options?.getDefaultReceivingLocation !== "true"
    ) {
      requestUrl =
        requestUrl +
        "&getDefaultReceivingLocation=" +
        options.getDefaultReceivingLocation;
    }

    if(options?.includeProductReceivingActions){
      requestUrl =
      requestUrl +
      "&includeProductReceivingActions=" +
      options.includeProductReceivingActions;
    }

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {}
    );
  };

  return {addProductReceivingOrderProducts,returnShippingOrderProducts, getReturnedMaterialSubcontractFromShippingOrder,getReceivingOrderProducts, receiveReceivingOrderProducts };
}

export default receivingOrderProductsController;
