import CustomErrorNotice from "../CustomErrorNotice/CustomErrorNotice";
import { IonButton } from "@ionic/react";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";

interface IProps{
  title?:any;
  description?:any;
  hideSecondDescription?:boolean;
  extraContent?:any;
}
function CustomUnavailableErrorNotice(props:IProps) {

  const {t} = useHxfTranslation();

  return (
    <CustomErrorNotice
      title={props?.title ? props.title : t("an.error.occurred")}
      description={props?.description ? props.description : t("failed.load")}
      secondDescription={!props?.hideSecondDescription ? t("reload.app.contact") : ""}
      extraContent={ props?.extraContent ? props.extraContent : (
        <>
          <IonButton
            onClick={() => {
              location.reload();
            }}
          >
            {t("reload")}
          </IonButton>
        </>
  )}
    />
  );
}

export default CustomUnavailableErrorNotice;
