import { setupIonicReact } from "@ionic/react";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { Switch } from "react-router";
import { Redirect, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { GlobalStyles } from "./components/Themes/globalStyles";
import { darkTheme, lightTheme } from "./components/Themes/Themes";
import AppOfflineProvider from "./contexts/AppOfflineProvider";
import { AxiosIntercepterProvider } from "./contexts/AxiosIntercepterContext";
import ConfigurableRoute from "./contexts/ConfigurableRouteContext";
import GuestOnlyRouteContext from "./contexts/GuestOnlyRouteContext";
import { GlobalStateProvider } from "./GlobalCustomStateManagement/GlobalStateProvider";
import EntitySwitch from "./pages/EntitySwitch";
import HaxifyOauthReceivePage from "./pages/HaxifyOauthReceivePage";
import Home from "./pages/Home/Home";
import Login from "./pages/Login";
import "./theme/haxify.scss";
import ProductionOrdersStandard from "./pages/FeaturePages/ProductionOrders/Standard/ProductionOrdersStandard";
/* Theme variables */
import "./theme/variables.css";
import ProductionProductSelectionStandard from "./pages/FeaturePages/Productions/Standard/ProductSelectionStandard/ProductionProductSelectionStandard";
import NotFoundPage from "./pages/ErrorPages/NotFoundPage/NotFoundPage";
import ProductionInitiation from "./pages/FeaturePages/Productions/Standard/ProductionInitiation/ProductionInitiation";
import SomeComponentDemoStoreStateManagement from "./pages/DemoCodePages/appCustomStateManagementCreateStoreDemo/someComponent";
import WorkerIdentifyPage from "./pages/WorkerIdentifyPage/WorkerIdentifyPage";
import ProductionProcess from "./pages/FeaturePages/Productions/Standard/ProductionProcess/ProductionProcess";
import PageRedirector from "./pages/PageRedirector/PageRedirector";
import GenericTestPage from "./pages/DemoCodePages/genericTestPage/genericTestPage";
import ReceivingsStandard from "./pages/FeaturePages/ShippingsAndReceivings/Receivings/Standard/ReceivingsStandard";
import ReceivingsBuyOrdersSelection from "./pages/FeaturePages/ShippingsAndReceivings/Receivings/SubViews/ReceivingsBuyOrdersSelection/ReceivingsBuyOrdersSelection";
import ReceivingsShow from "./pages/FeaturePages/ShippingsAndReceivings/Receivings/SubViews/ReceivingsOrders/ReceivingOrdersShow";
import ReceivingOrdersShow from "./pages/FeaturePages/ShippingsAndReceivings/Receivings/SubViews/ReceivingsOrders/ReceivingOrdersShow";
import ReceivingOrderProductsShow from "./pages/FeaturePages/ShippingsAndReceivings/Receivings/SubViews/ReceivingOrderProducts/ReceivingOrderProductsShow";
import ShippingsStandard from "./pages/FeaturePages/ShippingsAndReceivings/Shippings/Standard/ShippingsStandard";
import ShippingOrdersShow from "./pages/FeaturePages/ShippingsAndReceivings/Shippings/SubViews/ShippingsOrders/ShippingOrdersShow";
import ShippingOrderProductsShow from "./pages/FeaturePages/ShippingsAndReceivings/Shippings/SubViews/ShippingOrderProducts/ShippingOrderProductsShow";
import ShippingsSalesOrdersSelection from "./pages/FeaturePages/ShippingsAndReceivings/Shippings/SubViews/ShippingsSalesOrdersSelection/ShippingsSalesOrdersSelection";
import MoveStockStandard from "./pages/FeaturePages/MoveStock/Standard/MoveStockStandard";
import MoveStockPick from "./pages/FeaturePages/MoveStock/SubViews/MoveStockPick/MoveStockPick";
import MoveStockDrop from "./pages/FeaturePages/MoveStock/SubViews/MoveStockDrop/MoveStockDrop";
import StockInOutStandard from "./pages/FeaturePages/StockInOut/Standard/StockInOutStandard";
import AddRemoveStock from "./pages/FeaturePages/StockInOut/SubViews/AddRemoveStock/AddRemoveStock";
import Punchclock from "./pages/FeaturePages/Punchclock/Punchclock";
import { SnackbarProvider } from "notistack";
import ProductionHistory from "./pages/FeaturePages/ProductionHistory/ProductionHistory";
import MachinesManagement from "./pages/FeaturePages/Machines/MachinesManagement";
import MaterialStaging from "./pages/FeaturePages/MaterialStaging/MaterialStaging";
import MaterialStagingProductionOrderMaterials from "./pages/FeaturePages/MaterialStaging/SubViews/ProductionOrderMaterials/MaterialStagingProductionOrderMaterials";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import translationEN from './translations/en.json'; 
import translationPT from './translations/pt.json';
import SelectedMachineManagement from "./pages/FeaturePages/Machines/SelectedMachineManagement/SelectedMachineManagement";
import StartDowntime from "./pages/FeaturePages/Machines/SelectedMachineManagement/StartDowntime/StartDowntime";
import OnGoingUnavailabilityOccurrence from "./pages/FeaturePages/OnGoingUnavailabilityOccurrence/OnGoingUnavailabilityOccurrence";
import ReceivingsReturnedMaterial from "./pages/FeaturePages/ShippingsAndReceivings/Receivings/SubViews/ReceivingsReturnedMaterial/ReceivingsReturnedMaterial";
import ShippingReturnedReceivingOrderProducts from "./pages/FeaturePages/ShippingsAndReceivings/Receivings/SubViews/ReceivingsReturnedMaterial/SubViews/ShippingReturnedReceivingOrderProducts";
import StartWorkerDowntime from "./pages/FeaturePages/StartWorkerDowntime/StartWorkerDowntime";
import LocateStock from "./pages/FeaturePages/LocateStock/LocateStock";
import DirectWorkstationConnect from "./pages/DirectWorkstationConnect/DirectWorkstationConnect";
import { styled } from "@mui/material";
import { MaterialDesignContent } from 'notistack'
import GenericScanner from "./pages/FeaturePages/GenericScanner/GenericScanner";
import StockCounting from "./pages/FeaturePages/InventoryEvaluation/StockCounting/StockCounting";



// Get the value of a cookie
function getCookie(name:any) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    let cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
}

const nav:any = navigator;
// This code snippet detects the browser language
const currentLanguage = nav.language || nav?.userLanguage;

let myAssignedLanguage = getCookie("lang") ? getCookie("lang") : null;

if(!myAssignedLanguage){
	if (currentLanguage.startsWith("pt")) {
		// Render English language content
		myAssignedLanguage = "pt";
	  } else {
		myAssignedLanguage = "en";
	  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: translationEN
      },
      pt:{
        translation: translationPT
      }
    },
    lng: myAssignedLanguage, // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const App: React.FC = () => {
  console.log("vLog3d2 v14");

  const theme = "light";

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-warning': {
      backgroundColor: '#BB8D3C',
    },
  }));
  
  return (
    <SnackbarProvider
    Components={{
      warning:StyledMaterialDesignContent
    }}
    maxSnack={3} anchorOrigin={{
      vertical: "top",
      horizontal: "center"
    }}>
      <IonApp>
        <IonReactRouter>
          <AppOfflineProvider>
            <GlobalStateProvider>
              <AxiosIntercepterProvider>
                <ThemeProvider
                  theme={theme === "light" ? lightTheme : darkTheme}
                >
                  <>
                    <GlobalStyles />

                    <IonRouterOutlet>
                      <Switch>
                        <Route
                          exact
                          path="/generic-test-page"
                          component={GenericTestPage}
                        />
                        <Route
                          exact
                          path="/code-demo-custom-create-store"
                          component={SomeComponentDemoStoreStateManagement}
                        />
                        <Route
                        exact
                        path="/direct-workstation-connect"
                        component={DirectWorkstationConnect}
                        />
                        <GuestOnlyRouteContext
                          exact
                          path="/login"
                          component={Login}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          exact
                          path="/home"
                          component={Home}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={false}
                          exact
                          path="/worker-identify"
                          component={WorkerIdentifyPage}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/production-history"
                          component={ProductionHistory}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/material-staging"
                          component={MaterialStaging}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/material-staging/production-order/:poId"
                          component={MaterialStagingProductionOrderMaterials}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          requiresNetworkAccess={true}
                          exact
                          path="/entity-switch"
                          component={EntitySwitch}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/receivings"
                          component={ReceivingsStandard}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/receivings/returned-material"
                          component={ReceivingsReturnedMaterial}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/receivings/returned-material-shipped-receiving-products/:soId"
                          component={ShippingReturnedReceivingOrderProducts}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/punch-clock"
                          component={Punchclock}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/receivings/receiving-orders"
                          component={ReceivingOrdersShow}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/receivings/receiving-orders/:roId"
                          component={ReceivingOrderProductsShow}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/receivings/buy-orders"
                          component={ReceivingsBuyOrdersSelection}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/stock-in-out"
                          component={StockInOutStandard}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/stock-in-out/:action"
                          component={AddRemoveStock}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/move-stock"
                          component={MoveStockStandard}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/locate-stock"
                          component={LocateStock}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/generic-scanner"
                          component={GenericScanner}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/inventory-evaluation/stock-counting"
                          component={StockCounting}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/move-stock/pick"
                          component={MoveStockPick}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/move-stock/drop"
                          component={MoveStockDrop}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/shippings"
                          component={ShippingsStandard}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/machines"
                          component={MachinesManagement}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/machine-management/:machineId"
                          component={SelectedMachineManagement}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/worker-downtime"
                          component={StartWorkerDowntime}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/machine-downtime/start"
                          component={StartDowntime}
                        />
                         <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/downtime/occurrence/:unavOccurrenceId"
                          component={OnGoingUnavailabilityOccurrence}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/shippings/shipping-orders"
                          component={ShippingOrdersShow}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/shippings/shipping-orders/:soId"
                          component={ShippingOrderProductsShow}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/shippings/sales-orders"
                          component={ShippingsSalesOrdersSelection}
                        />

                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/production-orders"
                          component={ProductionOrdersStandard}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          exact
                          path="/feature/production-order/:poId"
                          component={ProductionProductSelectionStandard}
                        />
                        <ConfigurableRoute
                          requiresLogin={false}
                          requiresWorkerIdentification={false}
                          exact
                          path="/page-redirector"
                          component={PageRedirector}
                        />
                        <ConfigurableRoute
                          requiresLogin={false}
                          exact
                          path="/not-found"
                          component={NotFoundPage}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          onLoadClearLocationState={true}
                          exact
                          path="/feature/production-order/:poId/po-product/:popId"
                          component={ProductionInitiation}
                        />
                        <ConfigurableRoute
                          requiresLogin={true}
                          requiresWorkerIdentification={true}
                          onLoadClearLocationState={true}
                          onArriveRemount={true}
                          exact
                          path="/feature/production-order/:poId/po-product/:popId/production/:productionId"
                          component={ProductionProcess}
                        />


                        <Route
                          exact
                          path="/haxify-oauth-receive"
                          component={HaxifyOauthReceivePage}
                        />
                        <ConfigurableRoute
                          onLoadClearLocationState={true}
                          exact
                          path="/"
                        >
                          <Redirect to="/home" />
                        </ConfigurableRoute>
                        <ConfigurableRoute exact path="*">
                          <Redirect to="/not-found" />
                        </ConfigurableRoute>
                      </Switch>
                    </IonRouterOutlet>
                  </>
                </ThemeProvider>
              </AxiosIntercepterProvider>
            </GlobalStateProvider>
          </AppOfflineProvider>
        </IonReactRouter>
      </IonApp>
    </SnackbarProvider>
  );
};

export default App;

setupIonicReact({
  mode: "md",
});
