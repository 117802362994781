import { IonButton, IonIcon } from "@ionic/react";
import i18next from "i18next";
import { arrowUndoCircleOutline, closeOutline } from "ionicons/icons";
import { useState } from "react";
import useFeedbackService from "../../../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../../../barrel/hooks/useHxfTranslation";
import RandomizationService from "../../../../../../../../barrel/services/randomizationService";
import HxfOverlayLoading from "../../../../../../../CustomElements/HxfOverlayLoading/HxfOverlayLoading";
import SimpleContentPopup from "../../../../../../../CustomElements/ProductionUI/SimpleContentPopup/SimpleContentPopup";
import { cleanGenericTransfAction } from "../../ProductGenericTransfActionsPopup";
import { useWrapperProductTransfActionsStore } from "../../ProductGenericTransfActionsPopupStore";

import styles from "./PGTActionAskIrregularityPopup.module.scss";
import PGTAskOccurrencesView from "./Subviews/AskOccurrencesView/PGTAskOccurrencesView";
import PGTFillChecklistsView from "./Subviews/FillChecklistsView/PGTFillChecklistsView";

interface IIrregularities{
    Id:any;
    code:any;
}

interface IActionIrregularity{
    Id_Irregularity:any;
    code:any;
    name:any;
    recOneOccurenceAtATime?:any;
    irregularityChecklists?:any;
}
interface ITransfActionData{
    actionIrregularities:IActionIrregularity[];
}

interface ITransfAction {
    Id:any;
    _actionData:ITransfActionData;
}

interface AddIrrPopupProps {

    onClose?:any;

    transfAction:ITransfAction;
    Id_Object:any;
    onConfirm:any;
}

const getIrrLabel = (objIrr:any) => {

    return (<>{objIrr.name}</>)
}
const PGTActionAskIrregularityPopup = (props:AddIrrPopupProps) => {
    
    console.log("PTGF ", props);
    const {t} = useHxfTranslation();
    const feedbackService = useFeedbackService();
    const [isLoading, setIsLoading] = useState(false);

    const [autoConfirmMode, setAutoConfirmMode] = useState<any>(null);
    const [stage,setStage] = useState<"SELECT_IRR"|"ASK_OCCURRENCES_NUMBER" | "FILL_CHECKLISTS">("SELECT_IRR");

    const [selectionData, setSelectionData] = useState<any>({});
    const [selectablePossibleIrregularities, setSelectablePossibleIrregularities] = useState([]);
    const [showAddingIrrProcessData,setShowAddingIrrProcessData] = useState<any>(null);
    const [checklistsToFill, setChecklistsToFill] = useState<any>(null);

    const productTransfActionsManager = useWrapperProductTransfActionsStore();
    const getIrregularities = () => {

        let actionIrregularities = props?.transfAction?._actionData?.actionIrregularities;
       
        if(!actionIrregularities){
            return [];
        }


        return actionIrregularities;
    }

    const getCustomSmallDesc = (obj:any, index:any) => {
        
        let irrInternalId = obj?._tempInternalId;
        if(!irrInternalId){
            return (<></>);
        }

        let showIrrChecklistSmallDesc = true; //later turn into a flag ??, shows the first checklist, first parameters filled
        if(showIrrChecklistSmallDesc){
        
        
            let currentArrayFilledChecklists = productTransfActionsManager.getActionResult(props.Id_Object, props.transfAction.Id).arrayFilledChecklists;
            
            if(currentArrayFilledChecklists){
                let firstchecklistFound = null;
                for(let i = 0; i<currentArrayFilledChecklists.length; i++){
                    if(currentArrayFilledChecklists[i].irr_tempInternalId === irrInternalId){
                        firstchecklistFound = currentArrayFilledChecklists[i];
                        break;
                    }
                }

                let pvLabel = firstchecklistFound?.checklistFilledData?.[0]?.name;
                if(firstchecklistFound){
                    return (<>{pvLabel}: {firstchecklistFound?.checklistFilledData?.[0]?.Value}</>)
                }
            }
        }
        return (<></>)
        //return (<div className={styles.smallDescription}>some other desc</div>)
    }
    const getIrregularityOccurrences = () => {
      
        let currentRes = productTransfActionsManager.getActionResult(props.Id_Object, props.transfAction.Id);
  
        let irregularities:any = getIrregularities();
        let irregularitiesFilled = currentRes?.irregularitiesFilled ? currentRes?.irregularitiesFilled : [];
        for(let i = 0; i<irregularitiesFilled.length; i++){
            for(let j = 0; j<irregularities.length; j++){
                if(irregularities[j].Id_Irregularity === irregularitiesFilled[i].Id_Irregularity){
                    irregularitiesFilled[i].code = irregularities[j].code;
                    irregularitiesFilled[i].name = irregularities[j].name;
                }
            }
        }
        return irregularitiesFilled;
    }
    return (<>
      <SimpleContentPopup>
        <div>

          <div className={styles.container}>
            <div className={styles.barContainer}>
              <div></div>

              <div className={styles.title}>{t("add.irregularity")}</div>

              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                 props.onClose();
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
            <div className={styles.content}  style={{height:stage === "SELECT_IRR" ? "60vh" : "78vh"}}>
            {isLoading && (
                <HxfOverlayLoading/>
            )}
                {stage === "SELECT_IRR" && (
                <div className={styles.selectOperActContainer}>
                    {t('select.irregularity')}
                </div>
                )}
                {/*stage === "SELECT_IRR" && (
                <div className={styles.selectIrrActContainer}>
                   <div>
                   <IonButton
                        color="primary"
                     
                        onClick={() => {
                           // setStage("SELECT_ACT");
                        }}
                    >
                        <IonIcon slot="icon-only" size="large" icon={arrowUndoCircleOutline} />
                    </IonButton>
                    </div> <div className={styles.selIrrDiv}>{t('select.irregularity')}</div>
                </div>
                )*/}

                {stage === "ASK_OCCURRENCES_NUMBER" && (<>
                {/*<div className={styles.selectIrrActContainer}>
                  <div>
                   <IonButton
                        color="primary"
                        onClick={() => {
                            setShowAddingIrrProcessData(null);
                            setStage("SELECT_IRR");
                        }}
                    >
                        <IonIcon slot="icon-only" size="large" icon={arrowUndoCircleOutline} />
                    </IonButton>
                    </div> <div className={styles.selIrrDiv}>{t('process.irregularity')}</div>
                </div>*/}
                </>
                )}

               

                {stage === "SELECT_IRR" && (
                    <div className={styles.irrActContainer}>
                        {getIrregularities().map((objIrr:IActionIrregularity,idx:number) => (
                            <div key={"idx_irr_" + idx} className={styles.irrActSelect} onClick={() => {
                                
                               let addingIrrProcessData:any = {
                               
                                irregularityData:{
                                    Id:objIrr.Id_Irregularity,
                                    name:objIrr.name,
                                    code:objIrr.code,
                                    recOneOccurenceAtATime:objIrr.recOneOccurenceAtATime
                                }
                               };
                             
                               setShowAddingIrrProcessData(addingIrrProcessData);
                               if(objIrr.recOneOccurenceAtATime === 1){
                                setAutoConfirmMode("OCCURS_ONE_AT_A_TIME");
                               }
                               setStage("ASK_OCCURRENCES_NUMBER");
                               
                               
                               
                            }}>
                                <div>
                                    {objIrr.code}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {(stage === "FILL_CHECKLISTS" && checklistsToFill?.checklists) && (
                    <PGTFillChecklistsView includePvNameInResult={true} /*filledChecklistsData={productTransfActionsManager.getActionResult(props.Id_Object, props.transfAction.Id)?.filledChecklists}*/ checklistsToFill={checklistsToFill.checklists} onConfirm={(currentChecklistsFilling:any) => {
                        
                        let reworkedFilledChecklistsArray = [];
                        let checklistsFilledIds = Object.keys(currentChecklistsFilling);
                        for(let i = 0; i<checklistsFilledIds.length; i++){

                            reworkedFilledChecklistsArray.push({
                                "irr_tempInternalId":checklistsToFill.tempInternalId,
                                "Id_Checklist":checklistsFilledIds[i],
                                "checklistFilledData":currentChecklistsFilling[checklistsFilledIds[i]]
                            });
                        }


                        let curActionRes = productTransfActionsManager.getActionResult(props.Id_Object, props.transfAction.Id);
                        
                       
                        let arrayFilledChecklists = curActionRes?.arrayFilledChecklists ? curActionRes?.arrayFilledChecklists : [];

                      
                        arrayFilledChecklists = [...arrayFilledChecklists, ...reworkedFilledChecklistsArray];

          
                     
                        curActionRes.arrayFilledChecklists = arrayFilledChecklists;

                        productTransfActionsManager.updateActionResult(props.Id_Object, props.transfAction.Id, curActionRes);
                        setStage("SELECT_IRR");
                    }}/>
                )}
                
                {(stage === "ASK_OCCURRENCES_NUMBER" && showAddingIrrProcessData !== null) && (
                    <PGTAskOccurrencesView onClose={() => {
                        props.onClose();
                    }}
                     autoConfirmMode={autoConfirmMode}

                     irregularityData={showAddingIrrProcessData?.irregularityData}
                     onConfirm={(irrObj:any) => {
                        let currentActionRes = productTransfActionsManager.getActionResult(props.Id_Object,props.transfAction.Id);
                     
                        let irregularitiesFilled = currentActionRes?.irregularitiesFilled ? currentActionRes?.irregularitiesFilled : [];
                        if(!irrObj?.irregularity?._tempInternalId){ //temporary id to associate filled data
                            irrObj.irregularity._tempInternalId = RandomizationService().generateRandomString(7);
                        }
                        irregularitiesFilled.push(irrObj.irregularity);

                        currentActionRes.transfAction = cleanGenericTransfAction(props.transfAction);
                        currentActionRes.irregularitiesFilled = irregularitiesFilled;
                       
                        let idIrregularityCurrent = showAddingIrrProcessData?.irregularityData?.Id;

                        let irregularityChecklists = [];
                        //check if irregularity requires filling checklists
                        let actIrregularities = props.transfAction?._actionData?.actionIrregularities;
                        if(actIrregularities){
                            for(let i = 0; i<actIrregularities.length; i++){
                                let actIrr = actIrregularities[i];
                                if(actIrr.Id_Irregularity === idIrregularityCurrent){
                                    irregularityChecklists = actIrr.irregularityChecklists;
                                    break;
                                }
                            }
                        }
                        productTransfActionsManager.updateActionResult(props.Id_Object,props.transfAction.Id, currentActionRes);
                        if(irregularityChecklists.length > 0){
                            setChecklistsToFill({tempInternalId:irrObj.irregularity._tempInternalId,checklists:JSON.parse(JSON.stringify(irregularityChecklists))});
                            setStage("FILL_CHECKLISTS");
                        }else{
                           
                            setStage("SELECT_IRR");
                        }
                        setAutoConfirmMode(null);
                    }}
                    />
                )}


              <div className={styles.activeProductionsContainer}>
                <div className={styles.activeProductionsSection}></div>
              </div>
            </div>

            {stage === "SELECT_IRR" && (
            <div style={{position:'relative',top:'-16px'}}>



                <div className={styles.containerBtnConfirm}>
                {getIrregularityOccurrences()?.length > 0 && (
                    <div className={styles.lineBreaker}></div>
                )}

                <div className={styles.addedIrregularitys}>
                    {getIrregularityOccurrences().map((obj: any, index: number) => (
                        <div
                        className={styles.addedIrregularity}
                        key={"addedIrregularity_" + index}
                        >
                        <div style={{margin:8}}>
                            <div className={styles.IrregularityName}>{obj.code}</div>
                            {getCustomSmallDesc(obj, index)}
                            
                        </div>
   
                        <div className={styles.IrregularityRemoveBtnContainer}>
                            <IonButton
                            color="danger"
                            className={styles.IrregularityRemoveBtn}
                            onClick={() => {
                                let currentActionRes = productTransfActionsManager.getActionResult(props.Id_Object, props.transfAction.Id);
                
                                let irregularitiesFilled = currentActionRes?.irregularitiesFilled ? currentActionRes?.irregularitiesFilled : [];
                
                                irregularitiesFilled.splice(index, 1);
                                currentActionRes.irregularitiesFilled = irregularitiesFilled;
                                productTransfActionsManager.updateActionResult(props.Id_Object, props.transfAction.Id, currentActionRes);
                            }}
                            >
                            <IonIcon slot="icon-only" size="large" icon={closeOutline} />
                            </IonButton>
                        </div>
                        </div>
                    ))}
                </div>
                    <div className={styles.lineBreaker}></div>
                    <div className={styles.buttonsContainer}>
                        <div>
                        <IonButton
                            className={styles.customConfirmationButton}
                            onClick={() => {
                                props.onConfirm();
                            }}
                        >
                            {getIrregularityOccurrences().length > 0 ? t('confirm.caps') : t('no.irregularities.caps')}
                        </IonButton>
                        </div>
                    </div>
                </div>
                
            </div>
            )}

          </div>
        </div>
      </SimpleContentPopup>
    </>)
}

export default PGTActionAskIrregularityPopup;