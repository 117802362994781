import { IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import LoadingSpinnerImg from "../../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import SimpleContentPopup from "../../../../../CustomElements/ProductionUI/SimpleContentPopup/SimpleContentPopup";

import styles from "./ProductGenericTransfActionsPopup.module.scss";
import { useWrapperProductTransfActionsStore } from "./ProductGenericTransfActionsPopupStore";
import PGTActionAskIrregularityPopup from "./ReceivingActions/PRActionAskIrregularityPopup/PGTActionAskIrregularityPopup";

interface IProps{
    startFromActionIndex?:any;
    indexObj:any;
    objTransfProduct:any;
    onClose?:any;
    onFinishProcess:any;
    type:"RECEIVING";

    onRequestReceiveProductAction?:any;
}

export const cleanGenericTransfAction = (transfAction:any) => {

  let cleanAct = {
    Id:transfAction.Id,
    code:transfAction.code,
    orderIndex:transfAction.orderIndex
  };

  return cleanAct;
}

function ProductGenericTransfActionsPopup(props:IProps){
   console.log("THE PROP :",props);
    const [isLoading,setIsLoading] = useState(false);
    const [didMount, setDidMount] = useState(false);

    const [currentIndexAction, setCurrentIndexAction] = useState(props?.startFromActionIndex ? props.startFromActionIndex : 0);

    

    const getObjRopName = () => {
        let productData = JSON.parse(props.objTransfProduct.productData);
        return productData.name;
    }

    const getObjRopCode = () => {
        let productData = JSON.parse(props.objTransfProduct.productData);
        return productData.name;
    }

    const getProductReceivingActionsList = () => {
        return props.objTransfProduct?.productReceivingActions ? props.objTransfProduct.productReceivingActions : [];
    }

    const finishCurrentAction = () => {

      let nextIndex = currentIndexAction + 1;
      let actionsArr = getProperActionsArr();
      let nextAction = actionsArr[nextIndex];
      if(!nextAction){
        //finished all actions
        props.onFinishProcess();
        return;
      }

      
      if(props.type === "RECEIVING" && nextAction.code === "RECEIVE_QTY"){
  
          props.onRequestReceiveProductAction(currentIndexAction);
          return;
      }

      setCurrentIndexAction(nextIndex);

    }

    const getProperActionsArr = () => {
      if(props.type === "RECEIVING"){
        return props.objTransfProduct?.productReceivingActions;
      }

      return [];
    }
    const getProperIndexAction = () => {
      let transfAction = getProperActionsArr()?.[currentIndexAction];
      
      if(!transfAction){
        return (<></>)
      }


      
      if(props.type === "RECEIVING"){ //actions supported by "receiving" mode


        if(transfAction.code === "ASK_IRREGULARITY"){
          return (
            <PGTActionAskIrregularityPopup Id_Object={props.objTransfProduct.Id}  transfAction={transfAction} onClose={() => {
              props.onClose();
            }} onConfirm={() => {
              finishCurrentAction();
            }}/>
          )
        }
      }


      return (<>
             <div className={styles.intPopup}>

<SimpleContentPopup>
  <div>
    <div className={styles.container}>
      <div
        className={`popup-barcontainer-border-styles ${styles.barContainer}`}
      >
        <div></div>
        <div className={styles.title}>CUR_ACT_TITLE</div>
        <IonButton
          color="danger"
          className={styles.closeWindowButton}
          onClick={() => {
            props.onClose();
          }}
        >
          <IonIcon slot="icon-only" size="large" icon={closeOutline} />
        </IonButton>
      </div>
      
      <div className={styles.selectedProductInfo} style={{backgroundColor:"#98c553"}}>
        <div className={styles.selcetedProductInfoLabels}>
          ({getObjRopCode()}) {getObjRopName()}
        </div>
      </div>
      
      <div
        className={`popup-content-border-sides-only-styles ${styles.content}`}
      >
        <div className={styles.label}>
          action not implemented yet
        </div>


        {isLoading ? (
           <div style={{display:'flex',justifyContent:'center',marginTop:30}}> <LoadingSpinnerImg/></div>
          ) : (<>
              <div className={styles.productVariablesSection}>
              hello world
              </div>
          </>)}

      </div>
    </div>
    
    { /***** some confirmation btns example */}
    <div
    className={`popup-bottom-confirmation-border-styles ${styles.bottomAreaContainer}`}
    >
        <div>
            <div className={styles.container}>
            <div className={styles.buttonsContainer}>
                <div className={`${styles.customConfirmationContainer} `}>
                <IonButton
                data-btn={"confirm-define-pv-value"}
                disabled={true}
                    className={styles.confirmButton}
                    onClick={() => {
                
                    }}
                >
                    some btn
                </IonButton>
                </div>
            </div>
            </div>
        </div>
    </div>
    { /**** ** */}
  </div>
</SimpleContentPopup>
</div>
      </>)
    }
    useEffect(() => {
        if(!didMount){

            //check current index
           
            if(props.startFromActionIndex === currentIndexAction){
              let transfAction = getProperActionsArr()?.[currentIndexAction];
              let nextAction = getProperActionsArr()?.[currentIndexAction+1];
              if(props.type === "RECEIVING"){
                if(transfAction.code === "RECEIVE_QTY"){
                  if(currentIndexAction === 0){
                    props.onRequestReceiveProductAction(currentIndexAction);
                  }else if(!nextAction){
                    console.log("finished process");
                    //finished
                    props.onFinishProcess();
                  }else{
                    setCurrentIndexAction(currentIndexAction+1);
                  }
                }
              }
            }
            
            //
            setDidMount(true);
        }
    },[didMount]);

    if(!didMount){
        return (<></>)
    }

    return (
      <>{getProperIndexAction()}</>
    )


  
}

export default ProductGenericTransfActionsPopup;