import {
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
    IonSpinner
} from "@ionic/react";
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import TokenIcon from '@mui/icons-material/Token';
import { barcodeOutline, searchCircleOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import inventoryEvaluationOrders from "../../../../barrel/controllers/inventoryEvaluationOrders";
import lotsController from "../../../../barrel/controllers/lotsController";
import productsController from "../../../../barrel/controllers/productsController";
import stockManagementController from "../../../../barrel/controllers/stockManagementController";
import useFeedbackService from "../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import useLocalStorageHandler from "../../../../barrel/hooks/useLocalStorageHandler";
import usePrevious from "../../../../barrel/hooks/usePrevious";
import useSyncSettings from "../../../../barrel/hooks/useSyncSettings";
import MobileWrapperCommunicationService from "../../../../barrel/services/mobileWrapperCommunicationService";
import ProductService from "../../../../barrel/services/productService";
import ScannablesService from "../../../../barrel/services/ScannablesService";
import { IHxfBarcodeSettings } from "../../../../components/HxfBarcodeScanner/HxfBarcodeScanner";
import { useGlobalState } from "../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomAppliedFilterChip from "../../../CustomElements/CustomAppliedFilterChip/CustomAppliedFilterChip";
import CustomArrowBack from "../../../CustomElements/CustomArrowBack/CustomArrowBack";
import CustomSearchBar from "../../../CustomElements/CustomSearchBar/CustomSearchBar";
import CustomUnavailableErrorNotice from "../../../CustomElements/CustomUnavailableErrorNotice/CustomUnavailableErrorNotice";
import ErrorNoticeRequiresNetworkLoadObject from "../../../CustomElements/ErrorNoticeRequiresNetworkLoadObject/ErrorNoticeRequiresNetworkLoadObject";
import HxfInfiniteScroll from "../../../CustomElements/HxfInfiniteScroll/HxfInfiniteScroll";
import HxfOverlayLoading from "../../../CustomElements/HxfOverlayLoading/HxfOverlayLoading";
import DefineProductParameterizedVariableValuesPopup from "../../../CustomElements/statePopups/defineProductParameterizedVariableValues/DefineProductParameterizedVariableValuesPopup";
import DefineProductQuantityPopup from "../../../CustomElements/statePopups/defineProductQuantityPopup/DefineProductQuantityPopup";
import DefineStockLocationPopup from "../../../CustomElements/statePopups/defineStockLocationPopup/DefineStockLocationPopup";
import DefineUomPopup from "../../../CustomElements/statePopups/defineUomPopup/DefineUomPopup";
import InAppTemplate from "../../../InAppTemplate/InAppTemplate";
import ScanBarcodePopup from "../../ProductionOrders/Standard/ScanBarcodePopup/ScanBarcodePopup";
import IEStockCountingProductPopup from "./IEStockCountingProductPopup/IEStockCountingProductPopup";
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import styles from "./StockCounting.module.scss";
  
  interface IProps{
    mode?:null | undefined | "LOCATE_STOCK";
    //----
    [key: string]: any; // Allow any additional props
  }
  function StockCounting(props:IProps) {
    const ALLOW_BARCODESCANNER_TO_ALSO_SCAN_QRCODES = true; //later turn into a setting, locate ALLOW_BARCODESCANNER_TO_ALSO_SCAN_QRCODES accross whole project

    const [inventoryEvaluationOrderId, setInventoryEvaluationOrderId] = useState<any>("LATEST_ONGOING"); //in the future get it from param etc..
    
    const syncSettings = useSyncSettings();
    const { sessionState } = useGlobalState();
    const [didMount, setDidMount] = useState(false);
    const previousDidMount = usePrevious(didMount);
    const sizePage = 25;
    const posContainerRef = useRef(null);
    const loadedObjsRef = useRef<any>(null);
    const [loadedObjs,setLoadedObjs] = useState<any>(null);
    const {t} = useHxfTranslation();
    const feedbackService = useFeedbackService();

    const [noActiveEval, setNoActiveEval] = useState(false);
    const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
    const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
      useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const [isLoadingOverlay, setIsLoadingOverlay] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const myLocalStorage = useLocalStorageHandler();
    const [isProcessingScan, setIsProcessingScan] = useState(false);
    const infiniteScrollEnabledRef = useRef(false);
    const allowedInfiniteScrollLoadPageRef = useRef(0);
    const [indexReRender, setIndexReRender] = useState(0);

    const [productIdsExceptionOrderLast, setProductIdsExceptionOrderLast] = useState([]);
  
    const [searchbarInput, setSearchbarInput] = useState("");
    const timerStartSearching = useRef<any>(0);
  
    const objSearchFilters = useRef<any>({});
  
    const [filtersApplied, setFiltersApplied] = useState<any>([]);
    const previousFiltersApplied = usePrevious(filtersApplied);
  
    const [evaluatingProduct, setEvaluatingProduct] = useState<any>({});
    const [qrProductSelectorScannerEnabled, setQrProductSelectorScannerEnabled] = useState(false);
    const [barcodeProductSelectorScannerEnabled, setBarcodeProductSelectorScannerEnabled] = useState(false);
  
    const [showBarcodeScannerPopup, setShowBarcodeScannerPopup] = useState(false);
    const [triggerOnFinishScan, setTriggerOnFinishScan] = useState(null);
    const triggerFinishScanBarcodeSettings = useRef<any>(null);
  
  
    const prevTriggerOnFinishScan = usePrevious(triggerOnFinishScan);
    const [showQrScannerPopup, setShowQrScannerPopup] = useState(false);
  
    const qrAutoProductSelScannerSettings = useRef<IHxfBarcodeSettings>({
      initCameraOnStartUp:true,
      useCamera: true,
      useExternalDevice: false,
      allowedScanFormats: [{"type":"qrcode","pattern":"SFS02,[lot.id],[product.id]"},{"type":"barcode39","pattern":"SFS02-[lot.id]-[product.id]"}],
    });
   
    const [qrScannerEnabled, setQrScannerEnabled] = useState(false);
    const [barcodeScannerEnabled, setBarcodeScannerEnabled] = useState(false);

    const [showProductsFamilies, setShowProductsFamilies] = useState(false);
  
    const [defaultLocationSettings, setDefaultLocationSettings] = useState<any>({
      defaultId_Warehouse_Location:null,
      defaultWarehouseLocationData:null,
      defaultId_Warehouse:null,
      defaultWarehouseData:null,
      defaultId_Lot:null,
      defaultLotData:null
    });
  
    const processScanSelectionByProductAndLot = (lotId:any,productId:any) => {
  
  
      if(isProcessingScan){
        console.log("ignored, already processing a scan");
        return;
      }
      setIsProcessingScan(true);
      setIsLoadingOverlay(true);
      productsController().getProduct(productId).then((res:any) => {
        setIsProcessingScan(false);
  
        let productObj = res?.data?.response?.data;
        if(!productObj?.Id){
          
          throw "unexpected product obj";
        }
  
        if(!lotId){
          //only selecting product
          setIsLoadingOverlay(false);
  
          setCurrentSelectedProductData(productObj);
            
          let pickingObj = { 
            Id_Product: productObj.Id,
            name:productObj.name,
            code:productObj.code,
            isUnique:productObj?.isUnique,
            productLabelsData:productObj?.productLabelsData,
            _checkingLocations:true
          };
          
          setEvaluatingProduct(pickingObj);
        }else{
          //also selecting lot location
          //get lot data
     
          lotsController().getLot({includeDefaultNoLot:true,includeWhLocation:true},lotId,null).then((res) => {
            setIsLoadingOverlay(false);
            setIsProcessingScan(false);
            let lotObj = res?.data;
            if(!lotObj?.Id){
              throw "unexpected product obj";
            }
           
  
            let whId = lotObj?.warehouse?.Id_Warehouse;
  
            let whLocId = lotObj?.warehouse?.warehouseLocation?.Id;
            setCurrentSelectedProductData(productObj);
            
            let pickingObj = { 
              Id_Product: productObj.Id,
              name:productObj.name,
              code:productObj.code,
              isUnique:productObj?.isUnique,
              productLabelsData:productObj?.productLabelsData,
              warehouseLocation:whLocId ? {
                ...lotObj.warehouse.warehouseLocation
              } : null,
              warehouse: whId ? {
                name:lotObj.warehouse.name,
                code:lotObj.warehouse.code
              } : null,
              lot: lotObj?.Id ? {
                name:lotObj.name,
                code:lotObj.code
              } : null,
              Id_WarehouseLocation:whLocId,
              Id_Warehouse:whId,
              Id_Lot:lotId,
              _checkingLocations:true
            };
            
            setEvaluatingProduct(pickingObj);
          }).catch((res) => {
            setIsLoadingOverlay(false);
            setIsProcessingScan(false);
            if(res?.response?.status === 404){
              feedbackService.notifyToast(t("lot.not.found"),"error");
              return;
            }
         
            feedbackService.notifyToast(t("generic.critical.error") + "!","error");
          });
  
        }
      
  
  
      }).catch((res:any) => {
        setIsLoadingOverlay(false);
        setIsProcessingScan(false);
        if(res?.response?.status === 404){
          feedbackService.notifyToast(t("product.not.found"),"error");
          return;
        }
        feedbackService.notifyToast(t("generic.critical.error") + "!","error");
      });
    }
  
    const isGhostScannerEnabled = () => {
  
      let isPickingProduct = evaluatingProduct && Object.keys(evaluatingProduct).length > 0;
      return barcodeProductSelectorScannerEnabled && !showBarcodeScannerPopup && !isDefiningPPVVS() && !isPickingProduct;
    }
    const getPopupScannerObj = (options:any = {}) => {
  
      let allowedScanFormats:any = [{"type":"barcode39","pattern":"SFS02-[lot.id]-[product.id]"}];
      if(ALLOW_BARCODESCANNER_TO_ALSO_SCAN_QRCODES){
        allowedScanFormats.push({"type":"qrcode","pattern":"SFS02,[lot.id],[product.id]"});
  
      }
  
      let barcodeSettings:IHxfBarcodeSettings = {
        initCameraOnStartUp:false,
        useCamera: qrProductSelectorScannerEnabled, //only show camera btn opt if qrcode is enabled
        useExternalDevice: true,
        allowedScanFormats: allowedScanFormats,
      };
  
      triggerFinishScanBarcodeSettings.current = barcodeSettings;
  
      return (
        <ScanBarcodePopup
        freeScannerMode={true}
        title="Scanner"
        ghostMode={options?.ghostMode}
        visible={true}
        barcodeScannerSettings={barcodeSettings}
        onClosedCamera={() => {
          if (
            MobileWrapperCommunicationService().isDeviceMobileWrapper() &&
            barcodeSettings.useCamera &&
            !barcodeSettings.useExternalDevice
          ) {
            setShowBarcodeScannerPopup(false);
          }
        }}
        onFinishedScan={(result: any) => {
          setTriggerOnFinishScan(result);//Locate scanbarcodepopup, as scanbarcodepopup works on timer, eventually make this functionality inside hxfbcarcodescanner, create hxfbarcodescanner v2 or something
          //the onFinishedScan finished scan must not be called from a timer, to fix later, or it would be accessing unsynced states
          //this temporarily fixes it 
  
        }}
        onClosePopup={() => {
          setShowBarcodeScannerPopup(false);
        }}
      />
      )
    }
  
    const [currentSelectedProductData, setCurrentSelectedProductData] = useState<any>(
      {}
    );
      const getLocateStockLabels = () => {
  
        let productLabelsData = evaluatingProduct?.productLabelsData ? evaluatingProduct.productLabelsData : [];
        
        let locateStockLabels:any = [];
        for(let i = 0; i<productLabelsData.length; i++){
          if(parseInt(productLabelsData[i]?.isLocationLabel) === 1){
            locateStockLabels.push(productLabelsData[i]);
          }
          
        }
  
        return locateStockLabels;
      }
    const onCancelPicking = () => {
      setEvaluatingProduct({});
      setCurrentSelectedProductData({});
    };
  
    const isDefiningPPVVS = () => {
      let condi1 = currentSelectedProductData?.arrayProductParameterizedVariables && currentSelectedProductData?.arrayProductParameterizedVariables.length > 0;//(!currentSelectedProductData?.Id || !evaluatingProduct?.Id_Product);
      let condi2 = !evaluatingProduct?.productParameterizedVariables;
      let isDefining = condi1 && condi2; 
  
  
      return isDefining;
    }

    const preparedIESEvaluatedProduct = (evProduct:any) => {
        let newProduct = {...evProduct};
        newProduct.arrayProductParameterizedVariables =  newProduct?.productParameterizedVariables ? newProduct?.productParameterizedVariables : [];
        delete newProduct.productParameterizedVariables;

        return newProduct;
    }
    const getPopupElement = () => {
    
      if (!currentSelectedProductData?.Id || !evaluatingProduct?.Id_Product) {
        return <></>;
      }
  
      //product has product parameterized variables  but have not been defined yet
      if (
        isDefiningPPVVS()
      ) {
        return (
          <>
            <DefineProductParameterizedVariableValuesPopup
                  allowIncompleteFilledList={props.mode === "LOCATE_STOCK"}
                  currentData={currentSelectedProductData}
                  title={t('product.parameters')}
                  onClosePopup={() => {
                    onCancelPicking();
                  }}
                  onSuccessConfirm={(res:any, extraInfo:any) => {
                    let evaluatingProductData = { ...evaluatingProduct };
                    evaluatingProductData.productParameterizedVariables = res;
                   
                    //info if filled all the pvs
                    /* if(extraInfo?.filledAll === false){
                      evaluatingProductData.filledAll = false;
                    }else{
                      evaluatingProductData.filledAll = true;
                    }*/
                    setEvaluatingProduct(evaluatingProductData);
                  }}
                  includeFullParameterizedVariablesData={true}
            />
  
  
          </>
  
        );
      }
  
      if(evaluatingProduct?._checkingLocations){
        return (
            <IEStockCountingProductPopup
                Id_InventoryEvaluationOrder={inventoryEvaluationOrderId}
                evaluatingProduct={preparedIESEvaluatedProduct(evaluatingProduct)}
                onSelectedLocation={(objData:any) => {

                    let newEvalProduct = {...evaluatingProduct};
                    delete newEvalProduct._checkingLocations;
                    //no need to do anything with objData as the selected location was already there
                    setEvaluatingProduct(newEvalProduct);
                }} onNewLocation={() => {
                    let newEvalProduct = {...evaluatingProduct};
                    delete newEvalProduct._checkingLocations;
                    //remove current locations so we can select a new locations
                    delete newEvalProduct?.Id_WarehouseLocation;
                    delete newEvalProduct?.Id_Warehouse;
                    delete newEvalProduct?.Id_Lot;
                    delete newEvalProduct?.warehouseLocation;
                    delete newEvalProduct?.warehouse;
                    delete newEvalProduct?.lot;
                    setEvaluatingProduct(newEvalProduct);
                }} onClose={() => {
                    

                    let idProduct = evaluatingProduct.Id_Product;
                    //refetch this one specifically;
                    
                    productsController()
                    .getProductsListJson({
                      Id_Product:idProduct,
                      includeParameterizedVariables: true,
                      include_Id_InventoryEvaluationOrder:'LATEST_ONGOING',
                      includeMoveStockSettings:true,
                      
                      },{checkIfProductsFinishedCounting:true}).then((res) => {
                      
                        let resultProduct = res?.data?.response?.data?.return?.[0];
                        if(!resultProduct){
                          throw "unexpected";
                        }
                        
                        let currentObjs = [...loadedObjs];
                      
                        for(let i = 0; i<currentObjs.length; i++){
                          if(currentObjs[i].Id === idProduct){
                            currentObjs[i]._productFinishedCounting = resultProduct?._productFinishedCounting;
                            if(currentObjs[i]._productFinishedCounting){
                              let currentProductExceptionOrderLast:any = productIdsExceptionOrderLast;
                              let alreadyFound = false;
                              for(let j = 0; j<currentProductExceptionOrderLast.length;j++){
                                if(currentProductExceptionOrderLast[j] === idProduct){
                                  alreadyFound = true;
                                  break;
                                }
                              }
                              if(!alreadyFound){
                                currentProductExceptionOrderLast.push(idProduct);
                              }
                              
                              setProductIdsExceptionOrderLast(currentProductExceptionOrderLast);
                            }

                            break;
                          }
                        }
                        
                        setLoadedObjs(currentObjs);
                        
                      }).catch((res) => {
                        feedbackService.notifyToast(t('error.unexpected'), "error");
                        return;
                      });


                    setEvaluatingProduct({});
                }} 
            />
        )
      }
  
      //user has not defined yet the stock location to pick stock from
      if (evaluatingProduct?.Id_WarehouseLocation === undefined) {
        return (
          <DefineStockLocationPopup
            title={t('stock.location')}
            enableLocateStockLabels={false} 
            disableSetLocation={false}
            qrScannerEnabled={qrScannerEnabled}
            barcodeScannerEnabled={barcodeScannerEnabled}
            traceStockEnabled={false} 
            allowLotCreation={true}
            notDefinedAllParameterizedVariableValues={evaluatingProduct?.filledAll === false || isModeStockLocation()}
           /* defaultId_Lot={defaultLocationSettings?.defaultId_Lot}
            defaultId_Warehouse={defaultLocationSettings?.defaultId_Warehouse}
            defaultId_Warehouse_Location={defaultLocationSettings?.defaultId_Warehouse_Location}
            defaultWarehouseLocationData={defaultLocationSettings?.defaultWarehouseLocationData}
            defaultWarehouseData={defaultLocationSettings?.defaultWarehouseData}
            defaultLotData={defaultLocationSettings?.defaultLotData}*/
            infoData={{ productData: currentSelectedProductData }}
            disableShopfloorLocation={true}
            /*lotNoLot_Id_Lot={defaultLocationSettings.lotNoLot_Id_Lot}
            lotNoLot_name={defaultLocationSettings.lotNoLot_name}
            lotNoLot_code={defaultLocationSettings.lotNoLot_code}*/
            disableNoLotUseDefault={true}
            onClosePopup={() => {
              onCancelPicking();
            }}
            onSuccessConfirm={(res:any) => {
              let idWarehouseLocation = null;
              let idWarehouse = null;
              let idLot = null;
  
              let invalidLocation = true;
              if (
                !res?.Id_WarehouseLocation &&
                !res?.Id_Warehouse &&
                !res?.Id_Lot
              ) {
                invalidLocation = false;
              } else {
                idWarehouseLocation = res.Id_WarehouseLocation;
                idWarehouse = res.Id_Warehouse;
                if (res?.Id_Lot) {
                  idLot = res.Id_Lot;
                  invalidLocation = false;
                } else if (!res?.Id_Lot && res?.defaultNoLotObj?.Id_Lot) {
                  idLot = res?.defaultNoLotObj?.Id_Lot;
                  invalidLocation = false;
                }
              }
  
              if (invalidLocation) {
                throw "error invalid location passed on stock move";
              }
              if(!isModeStockLocation()){

                let newObjEval = {
                  ...evaluatingProduct,
                  Id_WarehouseLocation: idWarehouseLocation,
                  Id_Warehouse: idWarehouse,
                  Id_Lot: idLot,

                };
          
               
                if(idLot === "new"){
                  newObjEval.lotCode = res?.lotData?.code;
                }
                setEvaluatingProduct(newObjEval);
              }else if(isModeStockLocation() && isUniqueProduct(evaluatingProduct)){
                throw "not implemented --";
              }else{
                throw "not implemented --";
              }
  
            }}
          />
        );
      }
  
      //the user has not yet defined the quantity he is moving
      if (!evaluatingProduct?.quantity) {
        if (
          !evaluatingProduct?.Id_Selected_UoM &&
          currentSelectedProductData?.flag_uomsEnabled_countingStock === 1 &&
          currentSelectedProductData?.uomConversionsData?.length > 0
        ) {
          //todo define uom selector
          return (
            <DefineUomPopup
              title={t('uom')}
              productData={currentSelectedProductData}
              onClosePopup={() => {
                onCancelPicking();
              }}
              onSuccessConfirm={(idSelectedUom:any) => {
                let selectedUomData = {};
  
                if (idSelectedUom !== -1) {
                  let uomConversionsData =
                    currentSelectedProductData.uomConversionsData;
                  for (let i = 0; i < uomConversionsData.length; i++) {
                    if (uomConversionsData[i].Id_UoM_Target === idSelectedUom) {
                      selectedUomData = { ...uomConversionsData[i] };
                    }
                  }
                }
  
                setEvaluatingProduct({
                  ...evaluatingProduct,
                  Id_Selected_UoM: idSelectedUom,
                  temp_selectedUom_Data: selectedUomData,
                });
              }}
            />
          );
        }
  
        return (
          <>
            <DefineProductQuantityPopup
              requirePositiveQuantity={true}
              title={t("product.stock.counting")}
              qtyInputPlaceholder={t('insert.quantity')}
              productData={{
                code: currentSelectedProductData?.code,
                name: currentSelectedProductData?.name,
                uomLabel: evaluatingProduct?.temp_selectedUom_Data
                  ? evaluatingProduct.temp_selectedUom_Data.UoM_Target_Code
                  : "",
                qtyValue: evaluatingProduct?.quantity ? evaluatingProduct.quantity : 0,
                Id_Selected_UoM: evaluatingProduct?.Id_Selected_UoM
                  ? evaluatingProduct?.Id_Selected_UoM
                  : -1,
                uomConversionsData: currentSelectedProductData?.uomConversionsData,
              }}
              onClosePopup={() => {
                onCancelPicking();
              }}
              onSuccessConfirm={(res:any) => {
                let submittingEvalProduct = {
                  ...evaluatingProduct,
                  Quantity: res.quantityResult,
                };

                submittingEvalProduct.arrayProductParameterizedVariables = submittingEvalProduct?.productParameterizedVariables ? submittingEvalProduct?.productParameterizedVariables : [];
                delete submittingEvalProduct.productParameterizedVariables;

                
                //todo - register record
                //redirect back to _checkLoc
                setIsLoadingOverlay(true);
                inventoryEvaluationOrders()
                  .evaluateProduct({Id_InventoryEvaluationOrder:inventoryEvaluationOrderId,product:submittingEvalProduct, workerCode: myLocalStorage.getWorkerIdentificationCode()})
                  .then((res) => {
                    setIsLoadingOverlay(false);
                    if(!res?.data?.done){
                      throw "unexpected drop";
                    }
                  
                    if(res?.status !== 200){
                      throw "unexpected";
                    }
                    feedbackService.notifyToast(t('evaluation.registered.successfully') + "!","success");
                    

                    let newEvalProduct = {...evaluatingProduct, _checkingLocations: true};
                    delete newEvalProduct.Id_WarehouseLocation;
                    delete newEvalProduct.Id_Warehouse;
                    delete newEvalProduct.Id_Lot;
                    setEvaluatingProduct({...newEvalProduct}); //finished, closes all popups
                  })
                  .catch((res) => {
                    setIsLoadingOverlay(false);
                    feedbackService.notifyToast(t("generic.critical.error") + "!","error");
                  });
              }}
            />
          </>
        );
      }
  
      return <></>;
    };
  
    const initiateFilterCode = (val:any) => {
      setSearchbarInput(val);
      clearTimeout(timerStartSearching.current); //stops spam requests
      timerStartSearching.current = setTimeout(
        () => {
          objSearchFilters.current = {
            ...objSearchFilters.current,
            searchBarFilter: val,
          };
          restartSearch();
        },
        500,
        val
      );
    };
  
    const onFilterChipRemove = (index:any) => {
      let newFiltersApplied = [];
      for (let i = 0; i < filtersApplied.length; i++) {
        if (i !== index) {
          newFiltersApplied.push(filtersApplied[i]);
        }
      }
      setFiltersApplied(newFiltersApplied); //will trigger useeffect
    };
  
    const getProperFilterChip = (obj:any, index:any) => {
      //custom styling of chip
      if (obj.filterKey === "Id_Product") {
        return (
          <CustomAppliedFilterChip
            filterTextElement={
              <>
                <b>{t('product')}:</b> {obj.productCode}
              </>
            }
            onRemoveFilter={() => {
              onFilterChipRemove(index);
            }}
          />
        );
      }
  
      let labelDefault = obj?.filterLabel ? obj.filterLabel : obj.filterKey;
      let chipVal = obj[obj.filterKey];
      //default
      return (
        <CustomAppliedFilterChip
          filterTextElement={
            <>
              <b>{labelDefault}:</b> {chipVal}
            </>
          }
          onRemoveFilter={() => {
            onFilterChipRemove(index);
          }}
        />
      );
    };
  
  
    const getProperProductFamilies = (objProduct:any) => {
  
  
      if(!objProduct?.families || objProduct?.families.length === 0){
        return (<></>)
      }
      
      
      return (
        <>
        <div className={styles.familiesLabel}>{t('families')}</div>
        <div className={styles.limitedFamiliesContainer}>
          {objProduct.families.map((obj:any, index:any) => (
              <span key={"fam_" + index} className={styles.family}>
                {index > 0 ? (<>, </>) : (<></>)}
                <i>{obj.name}</i>
              </span>
          ))}
        </div>
  
        </>
      )
    }
  
    const getFormattedFiltersForRequest = (filtersArray:any) => {
      let filtersArr = [...filtersArray];
      let formattedFilters:any = {};
      for (let i = 0; i < filtersArr.length; i++) {
        let filterKey = filtersArr[i].filterKey;
        let filterValue = filtersArr[i][filterKey];
        formattedFilters[filterKey] = filterValue;
      }
  
      return formattedFilters;
    };

    const getShowCountedLastExceptionProductIds = () => {
      return productIdsExceptionOrderLast;
    }
  
    const loadMore = useCallback(
      (options = null,passedFilters = null) => {
        if(isLoadingMore){
          return;
        }
        let currentPage = allowedInfiniteScrollLoadPageRef.current;
        
        let extraFiltersApply = {};
        if (passedFilters) {
          extraFiltersApply = getFormattedFiltersForRequest(passedFilters);
        } else {
          extraFiltersApply = getFormattedFiltersForRequest(filtersApplied);
        }
        setIsLoadingMore(true);
        productsController()
          .getProductsListJson({
            page: currentPage,
            size: sizePage,
            includeParameterizedVariables: true,
            include_Id_InventoryEvaluationOrder:'LATEST_ONGOING',
            showCountedLastOnGoingIEO:true,
            includeMoveStockSettings:true,
            ...objSearchFilters.current,
            ...extraFiltersApply,
          },{checkIfProductsFinishedCounting:true,showCountedLast_exceptIdProducts:getShowCountedLastExceptionProductIds(),})
          .then((resp) => {
            setIsLoadingMore(false);
            syncSettings.check(resp);
            let posData = resp.data.response.data.return;
            let posTotalSize = resp.data.response.data.totalSize;
            let posExtraData = resp.data.response.data.extra;
  
            let selectedInventoryEvaluationOrder = resp.data.response.selectedInventoryEvaluationOrder;
            if(!selectedInventoryEvaluationOrder){
                setNoActiveEval(true);
                return;
            }else{
                setInventoryEvaluationOrderId(selectedInventoryEvaluationOrder.Id);
            }

            if (currentPage === 0) {
    
  
              //first load only
              //loads the extraData that loads in the backend only when the page = 0
              //this is done to avoid double requests just to gather extra unrelated data
              let moveStockSettings = resp?.data?.response?.moveStockSettings;
              if(moveStockSettings){
                if(moveStockSettings?.movePickShowProductsFamilies === 1){
                  if(!showProductsFamilies){
                    setShowProductsFamilies(true);
                  }
                }
                let pickDefault = moveStockSettings?.pickDefault;
                if(pickDefault){
               
                  if(moveStockSettings?.pickDefault?.moveStockQrcodeLocationFiller === 1){
                    setQrScannerEnabled(true);
                  }
                  if(moveStockSettings?.pickDefault?.moveStockBarcode39LocationFiller === 1){
                    setBarcodeScannerEnabled(true);
                  }
  
                  if(moveStockSettings?.pickDefault?.moveStockQrcodeProductSelector === 1){
                    setQrProductSelectorScannerEnabled(true);
                  }
                  if(moveStockSettings?.pickDefault?.moveStockBarcode39ProductSelector === 1){
                    setBarcodeProductSelectorScannerEnabled(true);
                  }

                  
                  let idWhLoc = pickDefault.Id_WarehouseLocation;
                  let whLocCode = pickDefault?._moveStockPick_warehouseLocationData?.code;
                  let whLocName = pickDefault?._moveStockPick_warehouseLocationData?.name;
                  let idWh = pickDefault.Id_Warehouse;
  
                  let whCode = pickDefault?._moveStockPick_warehouseData?.code;
                  let whName = pickDefault?._moveStockPick_warehouseData?.name;
  
                  let idLot = pickDefault.Id_Lot;
                  let lotCode = pickDefault?._moveStockPick_lotData?.code;
                  let lotName = pickDefault?._moveStockPick_lotData?.name;
  
                  let defaultNoLot = pickDefault?.defaultNoLot;
                  let lotNoLot_Id_Lot = null;
                  let lotNoLot_code = null;
                  let lotNoLot_name = null;
  
                  if(defaultNoLot){
                    lotNoLot_Id_Lot = defaultNoLot.Id_Lot;
                    lotNoLot_code = defaultNoLot.code;
                    lotNoLot_name = defaultNoLot.name;
                  }
  
                  setDefaultLocationSettings({
                    defaultId_Lot:idLot,
                    defaultId_Warehouse:idWh,
                    defaultId_Warehouse_Location:idWhLoc,
                    defaultWarehouseLocationData:{
                      name:whLocName,
                      code:whLocCode
                    },
                    defaultWarehouseData:{
                      name:whName,
                      code:whCode
                    },
                    defaultLotData:{
                      code:lotCode,
                      name:lotName
                    },
                    lotNoLot_Id_Lot:lotNoLot_Id_Lot,
                    lotNoLot_name:lotNoLot_name,
                    lotNoLot_code:lotNoLot_code
                  });
             
                }
              }
           
            }
  
            let newArrayLoadedObjs = [];
  
            
            if (loadedObjs !== null && !options?.resettingSearch) {
              newArrayLoadedObjs = [...loadedObjs, ...posData];
            } else {
              newArrayLoadedObjs = posData;
            }
  
         
            setLoadedObjs(newArrayLoadedObjs);
            let hasMoreElements =
              newArrayLoadedObjs.length < posTotalSize && posData.length !== 0;
            if (hasMoreElements) {
              allowedInfiniteScrollLoadPageRef.current =
                allowedInfiniteScrollLoadPageRef.current + 1;
              infiniteScrollEnabledRef.current = true;
            } else {
              infiniteScrollEnabledRef.current = false;
            }
  
            //setLoadedObjs(loadedObjsRef.current);
            setLoadingPage(false);
           
  
            if (currentPage === 0) {
            setIndexReRender(indexReRender + 1);
            }
          })
          .catch((resp) => {
            setIsLoadingMore(false);
            console.log("THE ERROR: ", resp);

            
            if(resp?.response?.data?.issues?.errors && resp?.response?.data?.issues?.errors.includes("no.ongoing.inventory.eval.order")){
              setNoActiveEval(true);
              return;
            }

            if (resp["error"] && resp["error"]["error_type"] == "offline_fail") {
              if (failedToLoadPosError) {
                setFailedToLoadPosError(false);
              }
              setRequiresNetworkLoadError(true);
            } else {
              if (requiresNetworkLoadError) {
                setRequiresNetworkLoadError(false);
              }
              setFailedToLoadPosError(true);
            }
            console.log("FAILED::", resp);
  
            
          });
      },
      [
        syncSettings,
        failedToLoadPosError,
        indexReRender,
        requiresNetworkLoadError,
        filtersApplied,
      ]
    );
  
    const isUniqueProduct = (product:any) => {
      return product?.isUnique === 1;
    }
    const isModeStockLocation = () => {
  
      return props?.mode === "LOCATE_STOCK";
    }
  
    const getProperPageTitle = () => {
      if(isModeStockLocation()){
        return (<>{t('locate.stock')}</>)
      }
  
      return (<>{t('inventory.evaluation')} - {t('stockcounting')}</>)
    }
    const restartSearch = useCallback(() => {
      allowedInfiniteScrollLoadPageRef.current = 0;
      
      loadMore({resettingSearch:true});
    }, [loadMore, loadedObjs, allowedInfiniteScrollLoadPageRef]);
  
    useEffect(() => {
      if(prevTriggerOnFinishScan !== triggerOnFinishScan && triggerOnFinishScan){
        let result:any = triggerOnFinishScan;
        setTriggerOnFinishScan(null);
        console.log("RESULT: ", result);
       
        let format = result.resultFormat;
        let resultScan = result.resultScan;
        let barcodeSettings = triggerFinishScanBarcodeSettings.current;
  
        let pattern = format.pattern;
  
        let scannedWithQrcode = false;
        let scannedWithBarcode = false;
        let delimiter = null;
  
        let currentAllowedScanFormats = barcodeSettings.allowedScanFormats;
  
        if(ALLOW_BARCODESCANNER_TO_ALSO_SCAN_QRCODES){
          if(format.type === "barcode39" || format.type === "barcode128"){
          
              for(let i = 0; i<currentAllowedScanFormats.length; i++){
                if(!currentAllowedScanFormats[i]?._skipMatchFindDelimiterProcessing && currentAllowedScanFormats[i].pattern === pattern){
                    //match found
                    let realType = currentAllowedScanFormats[i]?.type;
                  
                    //SWAP type and process as if it was scanned with qrcode (in case it was qrcode pattern the one scanned)
                    format.type = realType;
                  
                }
              }
            
          }
        }
        if (format.type === "barcode39") {
          delimiter = ScannablesService().hxfBarcode39Delimiter;
          scannedWithBarcode= true;
        }
  
        if (format.type === "barcode128") {
          delimiter = ScannablesService().hxfBarcode128Delimiter;
          scannedWithBarcode= true;
        }
  
        if (format.type === "qrcode") {
          delimiter = ScannablesService().hxfQrcodeDelimiter;
          scannedWithQrcode= true;
        }
  
      
      
        let splittedPattern = pattern.split(delimiter);
       
        let indexThatIdentifiesTheProductId:any = -1;
        let indexThatIdentifiesTheLotId = -1;
  
        let indexThatIdentifiesTheProductionRecord = -1;
        for (let i = 0; i < splittedPattern.length; i++) {
       
          if (splittedPattern[i] === "[product.id]") {
          
            indexThatIdentifiesTheProductId = i;
          
          }
  
  
          if (splittedPattern[i] === "[lot.id]") {
            indexThatIdentifiesTheLotId = i;
           
          }
        }
        
        let isIdentifyingByProductAndLot = indexThatIdentifiesTheLotId !== -1 && indexThatIdentifiesTheProductId !== -1;
  
        if(isIdentifyingByProductAndLot){
          
          //identified with product id and lot id
          let splittedResult = resultScan.split(delimiter);
          let idLot = splittedResult[indexThatIdentifiesTheLotId];
          let idProduct = splittedResult[indexThatIdentifiesTheProductId];
  
          let arrayProductParameterizedVariables:any = []; //this type of scan does not support PPVVS so set as empty
          
          let productKey = ProductService().buildProductKey(idProduct, arrayProductParameterizedVariables);
          setShowBarcodeScannerPopup(false);
          processScanSelectionByProductAndLot(idLot, idProduct);
          
  
        }
  
  
      }
    },[triggerOnFinishScan]);
    useEffect(() => {
      if (!didMount) {
        let loadingFilters = props?.location?.state?.applyFilters;
  
        if (loadingFilters) {
          setFiltersApplied(loadingFilters);
        } else {
          loadingFilters = null;
        }
  
        window.history.replaceState({}, document.title); //clear history state

        loadMore({},loadingFilters);
        setDidMount(true);
      }
    }, [
      props?.location?.state?.applyFilters,
      didMount,
      failedToLoadPosError,
      requiresNetworkLoadError,
      sessionState,
      loadMore,
    ]);
  
    useEffect(() => {
      if (previousDidMount === didMount && didMount) {
        if (previousFiltersApplied !== filtersApplied) {
          console.log("filters were changed");
          restartSearch();
        }
      }
    }, [
      filtersApplied,
      previousDidMount,
      didMount,
      previousFiltersApplied,
      restartSearch,
    ]);
  
    if (!didMount) {
      return <></>;
    }
  
  
  
    return (
      <InAppTemplate>
        <CustomArrowBack dataBtnIdentifier={"back-home"} pushUrl={"/"} />
        {isGhostScannerEnabled() && (
             <>{getPopupScannerObj({ghostMode:true})}</>
        )}
        {showBarcodeScannerPopup && (
          <>{getPopupScannerObj()}</>
        )}
        {showQrScannerPopup && (
              <ScanBarcodePopup
              freeScannerMode={true}
                  title="Scanner"
                  visible={true}
                  barcodeScannerSettings={qrAutoProductSelScannerSettings.current}
                  onClosedCamera={() => {
                    if (
                      MobileWrapperCommunicationService().isDeviceMobileWrapper() &&
                      qrAutoProductSelScannerSettings.current.useCamera &&
                      !qrAutoProductSelScannerSettings.current.useExternalDevice
                    ) {
                      setShowQrScannerPopup(false);
                    }
                  }}
                  onInvalidScan={() => {
                    feedbackService.notifyToast("Invalid code scanned","warning");
                  }}
                  onFinishedScan={(result: any) => {
                    console.log("RESULT: ", result);
                
                    let format = result.resultFormat;
                    let resultScan = result.resultScan;
                    let delimiter = null;
  
                    if (format.type === "barcode39") {
                      delimiter = ScannablesService().hxfBarcode39Delimiter;
                    }
                    if (format.type === "barcode128") {
                      delimiter = ScannablesService().hxfBarcode128Delimiter;
                    }
  
                    if (format.type === "qrcode") {
                      delimiter = ScannablesService().hxfQrcodeDelimiter;
                    }
  
                    let pattern = format.pattern;
                    let splittedPattern = pattern.split(delimiter);
  
                    let indexThatIdentifiesTheProduct = -1;
                    
            
                    for (let i = 0; i < splittedPattern.length; i++) {
                      if (splittedPattern[i] === "[product.id]") {
                        indexThatIdentifiesTheProduct = i;
                        break;
                      }
                    }
  
                    let indexThatIdentifiesTheLot = -1; //optional
                    for (let i = 0; i < splittedPattern.length; i++) {
                      if (splittedPattern[i] === "[lot.id]") {
                        indexThatIdentifiesTheLot = i;
                        break;
                      }
                    }
  
                    if (indexThatIdentifiesTheProduct !== -1) {
                    
                      let splittedResult = resultScan.split(delimiter);
                      if (splittedResult.length !== splittedPattern.length) {
                    
                        console.log("Scan does not match the pattern");
                        feedbackService.notifyToast("Invalid code scanned","warning");
                      } else {
                        let lotId:any = null;
                        let productId = splittedResult[indexThatIdentifiesTheProduct];
                        if(indexThatIdentifiesTheLot !== -1){
                          lotId = splittedResult[indexThatIdentifiesTheLot];
                        }
                        
                        
                        //get target product data
                        //todo set loading 
                        processScanSelectionByProductAndLot(lotId,productId);
              
  
                      }
                    }else{
                      feedbackService.notifyToast("Invalid code scanned","warning");
                    }
                  }}
                  onClosePopup={() => {
                    setShowQrScannerPopup(false);
                  }}
                />
            )}
        <div className={styles.pageTitle}>{getProperPageTitle()}</div>
        {isLoadingOverlay && (
          <HxfOverlayLoading/>
        )}
        {!loadingPage && (
          <>
            <div className={styles.settingsContainer}>
              <div className={styles.customSearchBarContainer}>
                <CustomSearchBar
                  dataInputFieldIdentifier={"move-stock-search"}
                  value={searchbarInput}
                  onResetTextClick={() => {
                    initiateFilterCode("");
                  }}
                  onChange={(evt:any) => {
                    initiateFilterCode(evt.target.value);
                  }}
                  iconElement={
                    <div className={styles.searchIconStyles}>
                      <IonIcon icon={searchCircleOutline} />
                    </div>
                  }
                  placeholder={t('search.product')}
                />
              </div>
            </div>
            {filtersApplied.length > 0 && (
              <div className={styles.appliedFiltersContainer}>
                {filtersApplied.map((obj:any, index:any) => (
                  <div key={"cafc_" + index}>
                    {getProperFilterChip(obj, index)}
                  </div>
                ))}
              </div>
            )}
          </>
        )}
  
        {getPopupElement()}
  
        <div className={styles.receivingOrdersContainer} ref={posContainerRef}>
          {/*loadingPage && <LoadingSpinnerImg />*/}
          <div
            id="objSelectionDiv"
            key={"rerender_ref_selection_" + indexReRender}
            className={styles.objsSelection}
          >
            {!loadingPage &&
            !failedToLoadPosError &&
            loadedObjs !== null ? (
              <>
              {qrProductSelectorScannerEnabled && props.mode !== "LOCATE_STOCK" && MobileWrapperCommunicationService().isDeviceMobileWrapper() && (
              <div className={styles.objElement}>
                  <IonCardHeader
                    className={`${styles.elemElement} ${styles.scannerContentBtn}`}
                    onClick={() => {
                      setShowQrScannerPopup(true);
                    }}
                  >
                    <div className={styles.elemBackgroundContainer}>
                    <QrCodeScannerIcon />
                    </div>
                    
                      <div className={styles.scannerDeviceElemTitle}><div><b>QR</b></div></div>
                  
                    <IonCardTitle
                      className={styles.elemElementDescription}
                    ></IonCardTitle>
                  </IonCardHeader>
              </div>
              )}
  
              {barcodeProductSelectorScannerEnabled && props.mode !== "LOCATE_STOCK" && (
                <div className={styles.objElement}>
                  <IonCardHeader
                    className={`${styles.elemElement} ${styles.scannerElem}`}
                    onClick={() => {
                      setShowBarcodeScannerPopup(true);
                    }}
                  >
                      <div className={styles.scannerContainer}>
                        <div>
                          <IonIcon icon={barcodeOutline} style={{fontSize:60}} />
                        </div>
                        <div className={styles.scannerLabel}>
                          <div
                            className={``}
                          >
                            {t('scanner')}
                          </div>
                        </div>
                      </div>
                  </IonCardHeader>
                </div>
              )}
  
                {loadedObjs.map((obj:any, index:any) => (
                  <div key={"pick_prd_" + index} className={styles.objElement}>
                    <IonCardHeader
                      data-btn-pick-product-code={obj.code}
                      className={`${styles.elemElement} ${ obj?._productFinishedApproved ? styles.productCountApproved :(obj?._productFinishedCounting ? styles.productCountFinished : "") }`}
                      onClick={() => {

                        if(obj?._productFinishedApproved){
                          feedbackService.notifyToast(t('product.counting.already.approved'), "error");
                          return;
                        }
                        setCurrentSelectedProductData(obj);
  
                        setEvaluatingProduct({ _checkingLocations:true,Id_Product: obj.Id, name:obj.name, code:obj.code,isUnique:obj?.isUnique,productLabelsData:obj?.productLabelsData });
                      }}
                    >
                      <div className={styles.elemBackgroundContainer}>

                      {obj?._productFinishedApproved ? (
                          <OfflinePinIcon/>
                        ) : (
                          <>
                            {obj?._productFinishedCounting ? (
                              <WhereToVoteIcon/>
                            ) : (
                              <TokenIcon />
                            )}
                          </>
                        )}
                        
  
                        
                      </div>
                      <div className={styles.orderNameCodeContainer}>
  
                        <div className={styles.orderNameCode}>
                          <div
                            className={`${styles.productCodeNameLabel}`}
                          >
                            ({obj.code}) {obj.name}
                          </div>
  
                          {showProductsFamilies && (
                              <>{getProperProductFamilies(obj)}</>
                          )}
                        </div>
                      </div>
                    </IonCardHeader>
                  </div>
                ))}{" "}
              </>
            ) : (
              <></>
            )}
  
            {isLoadingMore && (
              <IonCardHeader className={styles.elemElement} onClick={() => {}}>
                <div className={styles.elemBackgroundContainer}>
                  <LocalShippingIcon />
                </div>
                <IonCardSubtitle>
                  <div className={styles.elemElementTitle}>Loading...</div>
                </IonCardSubtitle>
                <IonCardTitle className={styles.elemElementDescription}>
                  <IonSpinner />
                </IonCardTitle>
              </IonCardHeader>
            )}
  
            <HxfInfiniteScroll
              containerElementRef={posContainerRef}
              requiresContainerHavingScrollbar={true}
              onLoadMore={() => {
                /*let allowingVal = allowedInfiniteScrollLoadPageRef.current + 1;
              
                if(allowingVal > 7){
                  return;
                }
                console.log("allowing more :", allowingVal);
                 allowedInfiniteScrollLoadPageRef.current = allowedInfiniteScrollLoadPageRef.current + 1;
                */
  
                if (infiniteScrollEnabledRef.current) {
                  
                  loadMore();
                }
              }}
              allowedLoadPageRef={allowedInfiniteScrollLoadPageRef}
            />
          </div>
        </div>
        {failedToLoadPosError ? (
          <div className={styles.errorArea}>
            <CustomUnavailableErrorNotice />
          </div>
        ) : (
          <></>
        )}

        {noActiveEval && (
                    <div className={styles.errorArea}>
                     <CustomUnavailableErrorNotice title={t('inventory.evaluation.order')} description={t('no.active.ieo')} extraContent={(<></>)} hideSecondDescription={true}/>
                   </div> 
        )}
  
        {requiresNetworkLoadError ? (
          <div className={styles.errorArea}>
            <ErrorNoticeRequiresNetworkLoadObject />
          </div>
        ) : (
          <></>
        )}
      </InAppTemplate>
    );
  }
  
  export default StockCounting;
  