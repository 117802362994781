import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
import { isEmptyObject } from "../utils/ObjectUtils";

function productionController() {
  
  const getOnGoingProductionRecords = (options:any = {}) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "production-record/get-ongoing-prs",
      {
       ...options
      }
    );
  }

  const undoCancel = (options:any = {}) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "production-record/undo-cancel",
      {
       ...options
      }
    );
  }

  

  const startProduction = (
    options:any = {},
    workerCode: any,
    Id_ProductionOrder_Product: any,
    Id_Operation: any,
    Id_Previous_ProductionRecord: any,
    preActionsData:any,
    cancelToken: any
  ) => {
    let extraOptions: any = {
      workerCode: workerCode,
      Id_ProductionOrder_Product: Id_ProductionOrder_Product,
      Id_Operation: Id_Operation,
    };

    if (Id_Previous_ProductionRecord !== null) {
      extraOptions["Id_Previous_ProductionRecord"] =
        Id_Previous_ProductionRecord;
    }

    if(preActionsData){
      extraOptions["preActionsData"] = preActionsData;
    }
   
    extraOptions = {...extraOptions, ...options};

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "production/start",
      extraOptions,
      cancelToken
    );
  };

  const cancelAllMinimizedProductionRecords = (workerCode: any) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "production/cancel-all-minimized",
      {
        workerCode: workerCode,
      }
    );
  };
  const cancelProduction = (options:any = {},workerCode: any, Id_ProductionRecord: any) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "production/cancel",
      {
        workerCode: workerCode,
        Id_ProductionRecord: Id_ProductionRecord,
        ...options
      }
    );
  };
  

  const createProduction = (
    options:any = {},
    workerCode: any,
    Id_ProductionRecord: any,
    Id_OperationProduct: any,
    Id_Previous_ProductionRecord: any = null,
    Id_ProductionOrder_Product: any = null,
    productProductionData: any = null,
    preActionsData:any,
    cancelToken: any
  ) => {
    let postingObj: any = {
      workerCode: workerCode,
      Id_ProductionRecord: Id_ProductionRecord,
      Id_OperationProduct: Id_OperationProduct,
    };

    if (Id_Previous_ProductionRecord) {
      postingObj["Id_Previous_ProductionRecord"] = Id_Previous_ProductionRecord;
    }

    if (Id_ProductionOrder_Product) {
      postingObj["Id_ProductionOrder_Product"] = Id_ProductionOrder_Product;
    }

    if(preActionsData){
      postingObj["preActionsData"] = preActionsData;
    }

    if (
      productProductionData !== null &&
      !isEmptyObject(productProductionData)
    ) {
      postingObj = {
        ...postingObj,
        productProductionData: productProductionData,
      };
    }

    let extraOptions = {...options};

    postingObj = {...postingObj, ...extraOptions};
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "production/create",
      postingObj,
      cancelToken
    );
  };

  const multiProductionExecution = (
    workerCode: any,
    operationCode: any,
    arrayWithPopsIds: any,
    askPreviousRelations = null,
    preActionsData = null,
    extraData:any = null,
    cancelToken: any
  ) => {
    let askPrevRelations =
      askPreviousRelations !== null ? askPreviousRelations : [];

    let postingObj:any = {
      workerCode: workerCode,
      operationCode: operationCode,
      arrayWithPopsIds: [...arrayWithPopsIds],
      askPreviousRelations: askPrevRelations,

    };

    if(preActionsData){
      postingObj["preActionsData"] = preActionsData;
    }

    if(extraData){
      postingObj = {...postingObj, ...extraData};
    }

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() +
        "production/multi-production-execution",
      postingObj,
      cancelToken
    );
  };

  const closeProduction = (
    workerCode: any,
    Id_ProductionRecord: any,
    Id_OperationProduct: any,
    productProductionData: any = null,
    cancelToken = null
  ) => {
    let postingObj: any = {
      workerCode: workerCode,
      Id_ProductionRecord: Id_ProductionRecord,
      Id_OperationProduct: Id_OperationProduct,
    };

    if (
      productProductionData !== null &&
      !isEmptyObject(productProductionData)
    ) {
      postingObj = {
        ...postingObj,
        productProductionData: productProductionData,
      };
    }

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "production/close",
      postingObj,
      cancelToken
    );
  };

  const pauseProduction = (workerCode: any, Id_ProductionRecord: any) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "production/pause",
      {
        workerCode: workerCode,
        Id_ProductionRecord: Id_ProductionRecord,
      }
    );
  };

  const resumeProduction = (
    workerCode: any,
    Id_ProductionRecord: any,
    cancelToken: any
  ) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "production/resume",
      {
        workerCode: workerCode,
        Id_ProductionRecord: Id_ProductionRecord,
      },
      cancelToken
    );
  };



  //

  const workstationEdit = (idProductionRecord:any, options:any, cancelToken:any) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() +
        "production-record/ws-edit",
      {...options, Id:idProductionRecord },cancelToken
    );
  }
  const getById = (idProductionRecord:any, options:any,cancelToken?:any) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() +
        "production-record/get/" + idProductionRecord,
      {...options }, cancelToken
    );
  }

  const getWorkerProduction = (
    workerCode: any,
    idProduction: any,
    includeProductProductionFlow: any,
    cancelToken: any
  ) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() +
        "production/get-worker-production/" +
        idProduction +
        "?includeProductProductionFlow=" +
        includeProductProductionFlow,
      { workerCode: workerCode },
      cancelToken
    );
  };

  const getWorkerMultiProduction = (idMultiProduction: any) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() +
        "production/get-worker-multi-production/" +
        idMultiProduction,
      {}
    );
  };

  return {
    getOnGoingProductionRecords,
    getWorkerMultiProduction,
    multiProductionExecution,
    cancelAllMinimizedProductionRecords,
    closeProduction,
    resumeProduction,
    pauseProduction,
    startProduction,
    createProduction,
    cancelProduction,
    getWorkerProduction,
    getById,
    workstationEdit,
    undoCancel
  };
}

export default productionController;
