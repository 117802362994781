import createStore from "../../../../../../AppCustomStateManagement/createStore";
// Library interfaces

interface IProductGenericTransfActions{
  resultingDataMap:any;
}
interface ProductGenericTransfActionsPopupStore {
    
    mapObjResult: { [key: number]: IProductGenericTransfActions };
}


const initialState = {
    mapObjResult:{}
};
interface IAction {
  type: any;
  payload?: any;
}

const reducer = (
  store: ProductGenericTransfActionsPopupStore,
  action: IAction
) => {
  switch (action.type) {

    case "SET_STORE":
      store = {...action.payload};
      return store;
    case "RESET_STORE":
        store = {...initialState};
        return store;
    default:
      return store;
  }
};

export const [
  useProductTransfActionsPopupStore,
  dispatchProductTransfActionsPopupStore,
] = createStore(initialState, reducer);


export const useWrapperProductTransfActionsStore = () => {

    const practionsPopupStore = useProductTransfActionsPopupStore();

    const reset = () => {
      dispatchProductTransfActionsPopupStore({type:'RESET_STORE'});
    }

    const updateActionResult = (idObject:any, idAction:any, resultData:any) => {
      
      let objData = practionsPopupStore?.[idObject];
      if(!objData){
        objData = {};
      }
      let currentTotalResult = objData?.resultingDataMap;
      if(!currentTotalResult){
        currentTotalResult = {};
      }
      currentTotalResult[idAction] = resultData;

      let newMapObj = {...practionsPopupStore.mapObjResult};
      newMapObj[idObject] = {...objData, resultingDataMap:currentTotalResult};
      dispatchProductTransfActionsPopupStore({type:'SET_STORE', payload:{...practionsPopupStore, mapObjResult: JSON.parse(JSON.stringify(newMapObj))}});
    }

    const getActionResult = (idObject:any,idAction:any) => {
      return practionsPopupStore.mapObjResult?.[idObject]?.resultingDataMap?.[idAction] ? practionsPopupStore.mapObjResult?.[idObject]?.resultingDataMap?.[idAction] : {};
    }

    const getSubmitResult = () => {
        return practionsPopupStore.mapObjResult;
    }

    return {reset,updateActionResult, getSubmitResult, getActionResult};
};

