import { IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useCallback, useEffect, useRef, useState } from "react";
import ProductionOrdersDao from "../../../../../barrel/dataAccessObjects/ModelObjects/Objects/productionOrdersDao";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import useStateCallback from "../../../../../barrel/hooks/useStateCallback";
import LoadingSpinnerImg from "../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import InAppTemplate from "../../../../InAppTemplate/InAppTemplate";
import styles from "./ReceivingsStandard.module.scss";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
function ReceivingsStandard(props) {
  const { sessionState } = useGlobalState();

  const [didMount, setDidMount] = useState(false);

  const sizePage = 25;
  const [loadedPOS, setLoadedPOS] = useStateCallback(null);
  const loadedPOSRef = useRef(null);
  const productionOrdersDao = ProductionOrdersDao();
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
    useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [activeProductionsButtonData, setActiveProductionsButtonData] =
    useState(null);
  const [simultaneousOperationPopsData, setSimultaneousOperationPopsData] =
    useState(null);
  const [
    activeMultiProductionsButtonData,
    setActiveMultiProductionsButtonData,
  ] = useState(null);
  const {t} = useHxfTranslation();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const infiniteScrollEnabledRef = useRef(false);
  const allowedInfiniteScrollLoadPageRef = useRef(0);
  const [indexReRender, setIndexReRender] = useState(0);
  function isHidden(el) {
    return el.offsetParent === null;
  }

  const loadPageChecks = useCallback(() => {
    setLoadingPage(false);
  }, [setLoadingPage]);

  useEffect(() => {
    if (!didMount) {
      loadPageChecks();
      setDidMount(true);
    }
  }, [
    didMount,
    failedToLoadPosError,

    productionOrdersDao,
    requiresNetworkLoadError,
    sessionState,
    loadPageChecks,
  ]);

  return (
    <InAppTemplate>
      <CustomArrowBack pushUrl="/home" />
      <div className={styles.pageTitle}>{t("receivings")}</div>
      {loadingPage && <LoadingSpinnerImg />}

      <div className={styles.optionsContainer}>
        <div className={styles.optionsContent}>




          <IonCardHeader
            className={`${styles.elemElement}  ${styles.receivingsBtn}`}
            onClick={() => {
              props.history.push({
                pathname: "/feature/receivings/receiving-orders",
              });
            }}
            data-btn="all-receivings"
          >
            <div className={styles.elemBackgroundContainer}>
            <AddHomeWorkIcon />
            </div>
            <div className={'hxfFeatureCornerIconBtn'}>
                <div className={`hxfFeatureCornerIconContainer ${styles.featureReceiveIcon}`}>
                  <AddHomeWorkIcon/>
                </div>
            </div>
            <IonCardSubtitle>
              <div className={styles.elemElementTitle}>
                {t("all.expected.receivings")}
              </div>
            </IonCardSubtitle>
            <IonCardTitle className={styles.elemElementDescription}>
              {t("receivings")}
            </IonCardTitle>
          </IonCardHeader>

          <IonCardHeader
            className={`${styles.elemElement}  ${styles.purchasesBtn}`}
            onClick={() => {
              props.history.push({
                pathname: "/feature/receivings/buy-orders",
              });
            }}
          >
            <div className={styles.elemBackgroundContainer}>
              <ShoppingBasketIcon />
            </div>
            <div className={'hxfFeatureCornerIconBtn'}>
                <div className={`hxfFeatureCornerIconContainer ${styles.featureBuyIcon}`}>
                  <ShoppingBasketIcon/>
                </div>
            </div>
            <IonCardSubtitle>
              <div className={styles.elemElementTitle}>
                {t("buyorders.receivings")}
              </div>
            </IonCardSubtitle>
            <IonCardTitle className={styles.elemElementDescription}>
              {t("buyorders")}
            </IonCardTitle>
          </IonCardHeader>

          {sessionState?.userSessionData?.globalSettings?.featureExpander?.operationProductsSubcontracting === 1 && (
            <IonCardHeader
              className={`${styles.elemElement}`}
              onClick={() => {
                props.history.push({
                  pathname: "/feature/receivings/returned-material",
                });
              }}
            >
              <div className={styles.elemBackgroundContainer}>
              <AssignmentReturnIcon />
              </div>
              <div className={'hxfFeatureCornerIconBtn'}>
                  <div className={`hxfFeatureCornerIconContainer ${styles.featureReturnIcon}`}>
                    <AssignmentReturnIcon/>
                  </div>
              </div>
              <IonCardSubtitle>
                <div className={styles.elemElementTitle}>
                  {t("returned.material.subcontracted")}
                </div>
              </IonCardSubtitle>
              <IonCardTitle className={styles.elemElementDescription}>
                {t("returned.material")}
              </IonCardTitle>
            </IonCardHeader>
          )}
        </div>
      </div>
    </InAppTemplate>
  );
}

export default ReceivingsStandard;
