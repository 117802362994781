import axios from "axios";
import useLocalStorageHandler from "../hooks/useLocalStorageHandler";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

function receivingOrdersController() {
  const localStorage = useLocalStorageHandler();
  const getReceivingOrders = (
    options: any = { page: 0, size: 10, openOnly: true, Id_BuyOrder: 0 }
  ) => {
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();

    let requestUrl =
      "receiving-orders/listJson/?page=" +
      options["page"] +
      "&size=" +
      options["size"] +
      "&openOnly=" +
      options["openOnly"];

    if (options?.includeBuyOrderData && options.includeBuyOrderData) {
      requestUrl =
        requestUrl + "&includeBuyOrderData=" + options.includeBuyOrderData;
    }

    if (options?.includeSupplierData && options.includeSupplierData) {
      requestUrl =
        requestUrl + "&includeSupplierData=" + options.includeSupplierData;
    }
    

    if (options?.searchByCode) {
      requestUrl = requestUrl + "&searchByCode=" + options.searchByCode;
    }
    if (options?.Id_BuyOrder && options?.Id_BuyOrder !== 0) {
      requestUrl = requestUrl + "&Id_BuyOrder=" + options.Id_BuyOrder;
    }
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {}
    );
  };

  return { getReceivingOrders };
}

export default receivingOrdersController;
