import axios from "axios";
import useLocalStorageHandler from "../hooks/useLocalStorageHandler";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

function shippingOrdersController() {
  const localStorage = useLocalStorageHandler();
  const getShippingOrders = (
    options: any = { page: 0, size: 10, openOnly: true, Id_SalesOrder: 0 }
  ) => {
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();
    let requestUrl =
      "shipping-orders/listJson/?page=" +
      options["page"] +
      "&size=" +
      options["size"] +
      "&availableOnly=" +
      options["availableOnly"];

      requestUrl = requestUrl + "&workerIdentificationCode=" + currentWorkerCode;

    if(options?.onlySubcontractedShippingOrders){
      requestUrl = requestUrl + "&onlySubcontractedShippingOrders=" + options.onlySubcontractedShippingOrders;
    }

    if (options?.includeSalesOrderData && options.includeSalesOrderData) {
      requestUrl =
        requestUrl + "&includeSalesOrderData=" + options.includeSalesOrderData;
    }
    if (options?.includeClientData && options.includeClientData) {
      requestUrl =
        requestUrl + "&includeClientData=" + options.includeClientData;
    }
    if (options?.searchBarFilter) {
      requestUrl = requestUrl + "&searchBarFilter=" + options.searchBarFilter;
    }
    if (options?.searchByCode) {
      requestUrl = requestUrl + "&searchByCode=" + options.searchByCode;
    }

    if (options?.Id_SalesOrder && options?.Id_SalesOrder !== 0) {
      requestUrl = requestUrl + "&Id_SalesOrder=" + options.Id_SalesOrder;
    }

    if (options?.orderBy && options?.orderBy !== 0) {
      requestUrl = requestUrl + "&orderBy=" + options.orderBy;
    }

    if (options?.orderBy && options?.orderDir !== 0) {
      requestUrl = requestUrl + "&orderDir=" + options.orderDir;
    }

    if(options?.specialOrderByDueDateAsc){
      requestUrl = requestUrl + "&specialOrderByDueDateAsc=true";
    }
    
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {}
    );
  };

  const scanByCode = (shippingOrderCode:any) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + 'shipping-order/scan-reference',
      {code:shippingOrderCode}
    );
  }
  const close = (idShippingOrder:any, workerCode:any) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + 'shipping-order/close',
      {Id_ShippingOrder:idShippingOrder, workerCode:workerCode}
    );
  }
  return { getShippingOrders,scanByCode,close };
}

export default shippingOrdersController;
