
import { IonButton, IonSpinner, useIonAlert } from "@ionic/react";
import { styled } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useEffect, useState } from "react";

import { Checkbox, TextField } from "@mui/material";
import useHxfTranslation from "../../../../../../../../../../barrel/hooks/useHxfTranslation";
import usePrevious from "../../../../../../../../../../barrel/hooks/usePrevious";
import HxfSelectorField from "../../../../../../../../../CustomElements/HxfSelectorField/HxfSelectorField";
import styles from "./PGTFillChecklistsView.module.scss";

interface IProps{
    onConfirm?:any;
    onCancel?:any;
    checklistsToFill?:any;
    //filledChecklistsData?:any;
    includePvNameInResult?:boolean;
}

export const getUnavailabilityActionFillChecklist = () => {
    return "UNAVACT_FILL_CHECKLIST";
}

const StyledRadio = styled(Radio)(({ theme }) => ({

    "& .MuiSvgIcon-root": {
      width:45,
      height:45
    },
  
  }));

function PGTFillChecklistsView(props:IProps){
    const [indexCurrentChecklist, setIndexCurrentChecklist] = useState(0);

    const [present] = useIonAlert();

    const [didMount, setDidMount] = useState(false);
    const prevDidMount = usePrevious(didMount);
    const {t} = useHxfTranslation();

    const [returningObj, setReturningObj] = useState<any>({comment:null});
    
    const [isLoading, setIsLoading] = useState(true);

    const [checklistFields, setChecklistFields] = useState<any>([]);
    const [mapErrors, setMapErrors] = useState<any>({});
    
    const [currentChecklistsFilling, setCurrentChecklistsFilling] = useState({});

    const getChecklistNaming = () => {
    /*

        let occurrence = occurrenceState.getCurrentUnavailabilityAction();
        let name = occurrence?._actionData?.checklistName;*/

   
            return (<div className={styles.nameCl}>{props.checklistsToFill[indexCurrentChecklist]?.name}</div>)
       
    }
    const getChecklistPvValue = (
        indexPv: number,
        varType: string
      ) => {
     
    
  
    
        let idParameterizedVariable = checklistFields[indexPv]["Id_ParameterizedVariable"];
    
        let val = checklistFields[indexPv]?.Value;

        if (val === null || val === undefined) {
          if (varType === "text") {
            return "";
          }
          if (varType === "numeric") {
            return "";
          }
          if (varType === "checkbox") {
            return false;
          }
          if (varType === "optionselect") {
            return null;
          }
          return null;
        } else {
          if (varType === "optionselect") {
            return { label: val, value: val };
          }
        }
    
        return val;
      };
    
      const getFieldVarType = (
        objPv: any,
        indexPv: number
      ) => {
        let varName = objPv.name;
        let isRequiredCheck = true;//objPv?._config?.isRequiredCheck; //TODO load configs from the checklist pv locate isRequiredCheck
        if (objPv.varType === "text") {
          return (
            <div>
              <TextField
                disabled={false}
                error={mapErrors?.[indexPv]}
                onChange={(evt) => {
                    let newChecklists:any = [...checklistFields];
                    newChecklists[indexPv].Value = evt?.target?.value;

                    setChecklistFields(newChecklists);
                }}
                type={"text"}
                value={getChecklistPvValue(indexPv, objPv.varType)}
                fullWidth={true}
                label={varName}
                variant="outlined"
              />
            </div>
          );
        }
    
        if (objPv.varType === "numeric") {
          return (
            <div>
              <TextField
                disabled={false}
                error={mapErrors?.[indexPv]}
                onChange={(evt) => {


                  let newChecklists:any = [...checklistFields];
                  newChecklists[indexPv].Value = evt?.target?.value;

                  setChecklistFields(newChecklists);
                }}
                type={"number"}
                value={getChecklistPvValue(indexPv, objPv.varType)}
                fullWidth={true}
                label={varName}
                variant="outlined"
              />
            </div>
          );
        }
    
        if (objPv.varType === "checkbox") {
          return (
            <div className={styles.checkboxCustom}>
              <div className={styles.checkboxCustomInner}>
              <FormControlLabel control={ <Checkbox
                
                required={isRequiredCheck}
                  color={"primary"}
                  style={{
                    color: mapErrors[indexPv] ? "#e82301" : "#bdbdbd", //doesFieldHaveError(objPv, idChecklist) ? "#cd1b1b26" : "#ffffff00",
                    transform: "scale(2)",
               
                  }}
                  onChange={(evt) => {
                    let newVal = evt.target.checked;
                    //clear error
                    let newCurMap = { ...mapErrors };
                    if (
                      newCurMap[indexPv]
                    ) {
                      newCurMap[indexPv] =
                        false;
                      setMapErrors(newCurMap);
                    }
    
                    console.log("ONCHG: ", evt.target.checked);
        
                    let newChecklists:any = [...checklistFields];
                    newChecklists[indexPv].Value = evt.target.checked;

                    setChecklistFields(newChecklists);
                  }}
                  checked={getChecklistPvValue(
                    indexPv,
                    objPv.varType
                  )}
                />} label={(<div className={styles.pvCheckLabel}>{objPv.name}</div>)} />


              </div>
            </div>
          );
        }
    
        if (objPv.varType === "optionselect") {
          let optionsSelectableArr = objPv.varTypeOptionSelect_Opts.split(",");
    
          let defaultOptionsObj = [];
          for (let i = 0; i < optionsSelectableArr.length; i++) {
            defaultOptionsObj.push({
              label: optionsSelectableArr[i],
              value: optionsSelectableArr[i],
            });
          }
    
          return (
            <div className={styles.customChecklistComboSelectOptions}>
              <HxfSelectorField
                error={mapErrors?.[indexPv]}
                uniqueId={"hxfsel_" + indexPv}
                disabled={false}
                labelPlaceholder={varName}
                onChange={(evt:any, val:any) => {
                  console.log("ONCHG: ", val);
                  if (!val) {
                    val = null;
                  }
                  //setChecklistPvValue(indexChecklist, indexPv, val, objPv.varType);
    
                  let newChecklists:any = [...checklistFields];
                  newChecklists[indexPv].Value = val?.value;

                  setChecklistFields(newChecklists);
                  //clear error
                  let newCurMap = { ...mapErrors };
                  if (
                    newCurMap[indexPv]
                  ) {
                    newCurMap[indexPv] =
                      false;
                    setMapErrors(newCurMap);
                  }
                }}
                value={getChecklistPvValue(indexPv, objPv.varType)}
                defaultItems={defaultOptionsObj}
              />
            </div>
          );
        }
    
        return <>not implemented</>;
      };
   

      const loadChecklistByIndex = (index:any) => {
        let checklistFieldsData = props.checklistsToFill[index]?.checklistParameterizedVariables;
        if(!checklistFieldsData){
            checklistFieldsData = [];
        }

        //preloaded already filled state
        //edit currently not supported
        /*
        let filledChecklistsData = props.filledChecklistsData;
        if(filledChecklistsData){

        }*/
        //--
      
        setChecklistFields(checklistFieldsData);
      }

    useEffect(() => {
        if(!didMount){
          console.log("JUST LOADED");
            loadChecklistByIndex(indexCurrentChecklist);

              
            setDidMount(true);
        }
    },[didMount]);

    useEffect(() => {
        //after mounting
        if(!prevDidMount && didMount){

 

            setIsLoading(false);
        }
    }, [didMount]);


    if(isLoading){
        return (
            <>
             
               <div className={styles.content}>
                <div style={{marginBottom:50, marginTop:50}}>
                   
                    <IonSpinner/>
                    
                </div>
            </div>
            </>
        )
    }


    return (
        <>
            <div className={styles.content}>
                <div style={{margin:40}}>
                    <div>
                        {getChecklistNaming()}
                    </div>

                    {checklistFields.map((objField:any, index:any) => (
                        <div key={"chk_" + index} className={styles.inputContainer}>
                            {getFieldVarType(objField,index)}
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <div className={styles.confirmBtnContainer}>
                    <div className={styles.lineBreaker}></div>
                    <div className={styles.buttonsContainer}>
                    <div className={styles.confirmationContainer}>
                        <div>
                        <div>
                            <IonButton
                            disabled={false}
                            className={styles.confirmButton}
                            onClick={() => {
                                   //validate 
                                    let hasErrors = false;
                                    let newMapErrors:any = {};
                                
                                    for(let i = 0; i<checklistFields.length; i++){
                                        let isRequired = true;//objPv?._config?.isRequiredCheck; //TODO load configs from the checklist pv locate isRequiredCheck checklistFields[i]?._config?.isRequiredCheck;
                                        if(isRequired && !checklistFields[i]?.Value){

                                            hasErrors = true;
                                            newMapErrors[i] = true;
                                        }
                                    }

                                    if(hasErrors){
                                        setMapErrors(newMapErrors);
                                        return;
                                    }
                                    //confirming
                                    let clearedChecklistData = [];
                                    for(let i = 0; i<checklistFields.length; i++){
                                        let clValue = checklistFields[i]?.Value;

                                        if(checklistFields[i]?.varType === "checkbox"){
                                        if(clValue === true){
                                            clValue = "true";
                                        }else if(!clValue){
                                            clValue = "false";
                                        }
                                        }else if(!checklistFields[i]?.Value){
                                        clValue = null;
                                        }


                                        let clearedObj:any = {
                                          Value:clValue,
                                          Id_ParameterizedVariable: checklistFields[i].Id_ParameterizedVariable
                                        };
                                        if(props?.includePvNameInResult){
                                          clearedObj.name = checklistFields[i].name;
                                        }
                                        clearedChecklistData.push(clearedObj);
                                    }

                            
                                    //todo validate required
                                    
                                    let idCurrentChecklist = props.checklistsToFill[indexCurrentChecklist].Id_Checklist;
                                    let checklistsResult:any = {...currentChecklistsFilling};
                                    checklistsResult[idCurrentChecklist] = clearedChecklistData;
                                    setCurrentChecklistsFilling(checklistsResult);

                                  
                                    if(indexCurrentChecklist === props.checklistsToFill.length-1){
                                        //finished all checklists
                                        
                                        props.onConfirm(checklistsResult);
                                    }else{
                                        //store 
                                        loadChecklistByIndex(indexCurrentChecklist+1);
                                        setIndexCurrentChecklist(indexCurrentChecklist+1);
                                    }
                
    
                            
                            }}
                            >
                            {t("done")}
                            </IonButton>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PGTFillChecklistsView;