import {
    IonButton, IonIcon, IonLoading
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import useHxfTranslation from "../../../../../../../../barrel/hooks/useHxfTranslation";

  
  import SimpleContentPopup from "../../../../../../../CustomElements/ProductionUI/SimpleContentPopup/SimpleContentPopup";
import { usePreActionsStore } from "../../../preActionsStore";
import styles from "./PreActionSelectPopSubcontract.module.scss";
import PreActionSelectPopSubcontractConfirmButtons from "./PreActionSelectPopSubcontractConfirmButtons";

  interface IPopup {
    onFinish:any;
    onClose:any;
    subcontractsSuppliersData:any;
    operationProduct?:any;
  }
  function PreActionSelectPopSubcontract(props: IPopup) {

    let preAction = usePreActionsStore();

    const [didMount, setDidMount] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useHxfTranslation();
    const [disableConfirmationButtons, setDisableConfirmationButtons] = useState(false);
    const [selectedSourceIndex, setSelectedSourceIndex] = useState<any>(null);
    const subcontracts = props?.operationProduct?._subcontracts;

    useEffect(() => {
      if(!didMount){


      }
      setDidMount(true);
    },[didMount]);


    const finishSelection = () => {
      
      setDisableConfirmationButtons(true);
      props.onFinish({

      });
      
    }

    const getSupplierName = (obj:any) => {
        let idSupplier = obj?.Id_Supplier;

        let subcontractsSuppliersData = props.subcontractsSuppliersData;
        if(subcontractsSuppliersData){
            for(let i = 0; i<subcontractsSuppliersData.length; i++){
                if(subcontractsSuppliersData[i].Id === idSupplier){
                    return (<>{subcontractsSuppliersData[i].name}</>)
                }
            }
        }
       
        return (<>aaa</>)
    }
    if(isLoading){
      return (
        <IonLoading message={"Loading..."} isOpen={true}/>
      )
    }


  
    return (
      <div className={styles.intPopup}>
        
        <SimpleContentPopup mode="INNER">
          <div>
            <div className={styles.container}>
              <div
                className={`popup-barcontainer-border-styles ${styles.barContainer}`}
              >
                <div></div>
  
                <div className={styles.title}>{t('external.source')}</div>
  
                <IonButton
                  color="danger"
                  className={styles.closeWindowButton}
                  onClick={() => {
                    if (props?.onClose) {
                      props.onClose();
                    }
                  }}
                >
                  <IonIcon slot="icon-only" size="large" icon={closeOutline} />
                </IonButton>
              </div>
              <div
                data-content="true"
                className={`popup-content-border-sides-only-styles ${styles.content}`}
              >
                 <div className={styles.externalSourceTitle}>{t('select.the.external.source')}</div>
                 {subcontracts && subcontracts?.length > 0 ? (
                    <div className={styles.subcontractsContainer}>
                        {subcontracts.map((obj:any,index:any) => (
                            <div key={"subcontract_" + index} className={`${styles.subcontractContainer} ${selectedSourceIndex === index ? styles.selectedSubcontract : ""}`} onClick={() =>{
                                setSelectedSourceIndex(index);
                            }}>
                                <div>{getSupplierName(obj)}</div>
                            </div>
                        ))}
                        
                    </div>
                 ) : (
                    <>
                        <div className={styles.scantip}>
                            {t('no.source.available')} 
                        </div>
                    </>
                 )}

               

              </div>

   
            </div>

            <PreActionSelectPopSubcontractConfirmButtons
                  enabled={!disableConfirmationButtons && selectedSourceIndex !== null}
                  typeShow={"CONFIRM"}
                  onClickConfirm={() => {
                    props.onFinish({selectedSource:subcontracts[selectedSourceIndex]});
                  }}
                />
          </div>
        </SimpleContentPopup>
      </div>
    );
  }
  
  export default PreActionSelectPopSubcontract;
  