import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
import { isEmptyObject } from "../utils/ObjectUtils";
import GenericListJsonService from "../services/genericListJsonService";
function productsController() {

  const getProduct = (idProduct:any) => {
    let requestUrl = "product/get/" + idProduct;
    requestUrl = GenericListJsonService().getUrlFromOptions(requestUrl, {});

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {}
    );
  }
  
  const getProductsListJson = (
    options: any = { page: 0, size: 10, openOnly: true }, postData?:any
  ) => {
    let requestUrl = "products/listJson/?";//page=" + options["page"] + "&size=" + options["size"]


    /*if (options?.searchingCodeName) {
      requestUrl =
        requestUrl + "&searchingCodeName=" + options.searchingCodeName;
    }

    if (options?.includeParameterizedVariables) {
      requestUrl =
        requestUrl +
        "&includeParameterizedVariables=" +
        options.includeParameterizedVariables;
    }*/

    requestUrl = GenericListJsonService().getUrlFromOptions(requestUrl, {...options});

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      postData ? postData : {}
    );
  };

  return { getProductsListJson,getProduct };
}

export default productsController;
