import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonPopover,
} from "@ionic/react";
import { create, ellipsisHorizontal, ellipsisVertical, helpCircle, search, personCircle, star, printOutline } from 'ionicons/icons';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SignalCellularConnectedNoInternet1BarIcon from "@mui/icons-material/SignalCellularConnectedNoInternet1Bar";
import { exitOutline } from "ionicons/icons";
import { useState } from "react";
import { useHistory, withRouter } from "react-router";
import entityUserController from "../../barrel/controllers/entityUserController";
import useLocalStorageHandler from "../../barrel/hooks/useLocalStorageHandler";
import AppOutdatedCacheButton from "../../components/AppOutdatedCacheButton/AppOutdatedCacheButton";
import HxfContentRenderer from "../../components/HxfContentRenderer";
import { useAppOfflineContext } from "../../contexts/AppOfflineProvider";
import { useGlobalState } from "../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomSystemPopups from "../CustomElements/ProductionUI/CustomPopups/CustomSystemPopups";
import ProductionOperationConfirmation from "../CustomElements/ProductionUI/ProductionOperationConfirmation/ProductionOperationConfirmation";
import ToolbarAccountPopoverList from "../Popovers/ToolbarAccountPopover/ToolbarAccountPopover";
import styles from "./InAppTemplate.module.scss";
import "../../theme/haxify.scss";
import ToolbarLangSwitcher from "../Popovers/ToolbarLangSwitcher/ToolbarLangSwitcher";
import { useTranslation } from "react-i18next";
import useHxfTranslation from "../../barrel/hooks/useHxfTranslation";
import ToolbarWorkersMode from "./ToolbarWorkersMode/ToolbarWorkersMode";
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import MobileWrapperCommunicationService from "../../barrel/services/mobileWrapperCommunicationService";
import { dispatchCustomPopupsStore } from "../CustomElements/ProductionUI/CustomPopups/CustomPopupsStore";
import DowntimesFooterUI from "../CustomElements/DowntimesFooterUI/DowntimesFooterUI";
import ToolbarAuthenticatedWorkerPopover from "../Popovers/ToolbarAuthenticatedWorkerPopover/ToolbarAuthenticatedWorkerPopover";
function InAppTemplate(props) {
  const history = useHistory();
  const {t} = useHxfTranslation();
  const { appOffline } = useAppOfflineContext();
  const { appState } = useGlobalState();
  const { state, setState, stuff, setStuff, dothis, sessionState } =
    useGlobalState();
  const myLocalStorage = useLocalStorageHandler();
  const { testApi } = entityUserController();
  const [didMount, setDidMount] = useState(false);
  const [presentAlert] = useIonAlert();
  const {i18n} = useTranslation();
  
  const [present, dismiss] = useIonPopover(ToolbarAccountPopoverList, {
    onHide: () => dismiss(),
    onLogOff: () => {
      sessionState.logout();
    },
    onSwitchEntity: () => {
      props.history.push("/entity-switch");
    },
    getSessionState: () => {
      return sessionState;
    },
    translate:t
  });

  const [presentAuthenticatedWorkerMenu, dismissAuthenticatedWorkerMEnu] = useIonPopover(ToolbarAuthenticatedWorkerPopover, {
    onHide: () => dismissLangSwitch(),
    getSessionState: () => {
      return sessionState;
    },
  });

  const [presentLangSwitch, dismissLangSwitch] = useIonPopover(ToolbarLangSwitcher, {
    onHide: () => dismissLangSwitch(),
    getSessionState: () => {
      return sessionState;
    },
  });

  const getErrorMessageIfExists = () => {
    return <></>;
    if (!appState.isConnectionAvailable()) {
      return (
        <>
          <div className={styles.offlineModeBarContainer}>
            <div className={styles.offlineModeBar}>
              <div className={styles.offlineLabel1Red}>
                <div className={styles.offlineIcon}>
                  <SignalCellularConnectedNoInternet1BarIcon />
                </div>{" "}
                No Internet Connection
              </div>
              <div className={styles.offlineLabel2}>
                Please turn your connection online to continue operating...
              </div>
            </div>
          </div>
        </>
      );
    }

    if (appState.appStateData.maintenance) {
      return (
        <div className={styles.offlineModeBarContainer}>
          <div className={styles.offlineModeBar}>
            <div>
              <div className={styles.offlineLabel1}>
                <div className={styles.offlineIcon}>
                  <SignalCellularConnectedNoInternet1BarIcon />
                </div>{" "}
                Under Maintenance
              </div>

              <div className={styles.offlineLabel2}>
                The app is currently under maintenance.
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <></>;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {/*<IonTitle>Blankxuu3 Blankx {stuff.firstname} men {sessionState.isUserLoggedIn() ? "OK" : "NOK"} <IonButton onClick={() => {
              testApi();
            }}>clickme</IonButton> <AppOutdatedCacheButton/> <OfflineTooLongComponent/></IonTitle>*/}
          <div className={styles.toolbar}>

            {sessionState.hasAppAdminLevel(1) && (
              <div className={styles.adminMinibar}>
                  {t('hxfstaff.session')} - <b>{localStorage.getItem('srv')}</b>
              </div>
            )}


            <div className={`${styles.toolbarElement} ${styles.toolbarLogo}`}>
              <img src="/assets/images/wslogo.png" alt="Logo" />
            </div>

            <div
              className={`${styles.toolbarElement} ${styles.toolbarCurrentStatus}`}
            ></div>

            <div className={styles.rightCornerElements}>

              {sessionState.userSessionData.workerName &&
              sessionState.userSessionData?.Id_Worker &&
              sessionState.userSessionData.Id_Worker !== 0 ? (
                <div className={styles.toolbarElement}>
                  <div
                    onClick={(e) => {
                      if (props?.disableExitWorkerClickable) {
                        return;
                      }

                      presentAuthenticatedWorkerMenu({
                        event: e.nativeEvent,
                      });

                    }}
                    className={styles.toolbarWorkerAuthenticated}
                  >
                   
                   <ToolbarWorkersMode/>
                    <div className={styles.toolbarProfileAccountNameSection}>
                      <div className={styles.toolbarPersonName}>
                        {sessionState.userSessionData.workerName}
                      </div>
                    </div>
                    <KeyboardArrowDownIcon />
                   
                  </div>
                </div>
              ) : (
                <div className={styles.toolbarElement} id="toolbarAccountBtn">
                  <div
                    onClick={(e) => {
                      present({
                        event: e.nativeEvent,
                      });
                    }}
                    className={styles.toolbarSessionManagerSection}
                  >
                    <div className={`${styles.toolbarProfileAccountNameSection}`}>
                      <div className={styles.toolbarProfileAccount}>
                        <div>
                          <b>{t('account')}:</b>{" "}
                          {sessionState.userSessionData.customAccountId}
                        </div>
                      </div>
                    </div>

                    <KeyboardArrowDownIcon />
                  </div>
                </div>
              )}
              <div className={styles.toolbarElement}>
                  <div
                    onClick={(e) => {
        
                      presentAlert({
                        cssClass: "my-css",
                        header: t('home.screen'),
                        message: t("confirm.go.back.to.home"),
                        buttons: [
                          t("cancel"),
                          {
                            text: t("yes"),
                            handler: (d) => {
                  
                              history.push({pathname:"/"});
                            },
                          },
                        ],
                        onDidDismiss: (e) => {},
                      });
                    }}
                    className={styles.toolbarHome}
                  >
                    <HomeIcon />
                  </div>
                </div>
            </div>
          </div>
        </IonToolbar>
        {getErrorMessageIfExists()}

        <AppOutdatedCacheButton />
      </IonHeader>
      <IonContent fullscreen scrollX={true} id="app-content-container">
        <IonHeader collapse="condense">
          <IonToolbar>
            fffffffff
            <IonTitle size="large">blan k</IonTitle>
          </IonToolbar>
        </IonHeader>
        <HxfContentRenderer>{props.children}</HxfContentRenderer>
      </IonContent>

      <IonFooter style={{ zIndex: "20" }}>
        <DowntimesFooterUI/>
        <ProductionOperationConfirmation />
        <CustomSystemPopups />
      </IonFooter>

      <IonFooter>
        <IonToolbar>
        <IonButtons slot="secondary">
          {sessionState?.userSessionData?.sfDevices_enableCustomIntegrations === 1 && (
          <IonButton onClick={() => {
              dispatchCustomPopupsStore({type:"SHOW_CUSTOM_INTEGRATIONS_POPUP"});
           
            }}>
              <DeveloperModeIcon/>
            </IonButton>)
          }
          {sessionState?.userSessionData?.sfDevices_enableZebraConfigure === 1 && (
            <IonButton onClick={() => {
              
              MobileWrapperCommunicationService().notifyOpenPrinterZpl();
            }}>
              <IonIcon slot="icon-only" ios={printOutline} md={printOutline}></IonIcon>
            </IonButton>
          )}

            {/*
            <IonButton>
              <IonIcon slot="icon-only" icon={personCircle}></IonIcon>
            </IonButton>
            <IonButton>
              <IonIcon slot="icon-only" icon={search}></IonIcon>
            </IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton>
              <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical}></IonIcon>
            </IonButton>*/}
          </IonButtons>

          <IonTitle className={styles.footerLabel}>© Haxify {new Date().getFullYear()} - Shopfloor Studio.</IonTitle>
                    
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
}

export default withRouter(InAppTemplate);
