import { useState } from "react";
import MobileWrapperCommunicationService from "../../../../barrel/services/mobileWrapperCommunicationService";
import HxfIonPopup from "../../../CustomElements/ProductionUI/HxfIonPopup/HxfIonPopup";
import ScanBarcodePopup from "../../ProductionOrders/Standard/ScanBarcodePopup/ScanBarcodePopup";
import GLSSFS03Popup from "./SFS03/GLSSFS03Popup";
interface IProps{
    onClose:any;
    onFinishedScan?:any;
}


function GenericLabelScannerPopup(props:IProps){
    //TODO allow camera scanner 



    return (
        <>  

            <ScanBarcodePopup
            freeScannerMode={true}
            title="Scanner"
            visible={true}
            barcodeScannerSettings={{
                useCamera:true,
                useExternalDevice:true,
                allowedScanFormats:[],
                allowAllFormats:true,
                initCameraOnStartUp:false
            }}
            onClosedCamera={() => {
                props.onClose();
            }}
            
            onFinishedScan={(result: any) => {
                console.log("RESULT: ", result);
    
                let scanStartIdentifier = result;

                //check if starts with SFS03 + any alpha numeric character
               
                let scannedLabelValue = result.resultScan;
                const regexSFS03 = /^SFS03[^a-zA-Z0-9]/;
                const SFS03Match  = scannedLabelValue.match(regexSFS03);
                if(SFS03Match){
                    let matched = SFS03Match?.[0];
                    scanStartIdentifier = matched.charAt(matched.length - 1);
                }

                if(scanStartIdentifier){
                    let scannedArguments = scannedLabelValue.split(scanStartIdentifier);
                    console.log("SCA ARGS: ",scannedArguments);

                    props.onFinishedScan({scanArgs:scannedArguments});
                }
        
            }}
            onClosePopup={() => {
                props.onClose();
            }}
        />
    </>
    )
}

export default GenericLabelScannerPopup;