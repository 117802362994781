import {
  IonButton,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonSpinner,
  useIonAlert,
  useIonToast
} from "@ionic/react";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import SettingsCellIcon from '@mui/icons-material/SettingsCell';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Paper, Table, TableBody, TableContainer, TableRow, TableCell, TableHead, styled } from "@mui/material";
import { addOutline, albumsOutline, closeCircle, listOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import receivingOrderProductsController from "../../../../../../barrel/controllers/receivingOrderProductsController";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import usePrevious from "../../../../../../barrel/hooks/usePrevious";
import useSyncSettings from "../../../../../../barrel/hooks/useSyncSettings";
import DatesConversionService from "../../../../../../barrel/services/datesConversionService";
import UomService from "../../../../../../barrel/services/uomService";
import LoadingSpinnerImg from "../../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomAppliedFilterChip from "../../../../../CustomElements/CustomAppliedFilterChip/CustomAppliedFilterChip";
import CustomArrowBack from "../../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import CustomUnavailableErrorNotice from "../../../../../CustomElements/CustomUnavailableErrorNotice/CustomUnavailableErrorNotice";
import ErrorNoticeRequiresNetworkLoadObject from "../../../../../CustomElements/ErrorNoticeRequiresNetworkLoadObject/ErrorNoticeRequiresNetworkLoadObject";
import HxfInfiniteScroll from "../../../../../CustomElements/HxfInfiniteScroll/HxfInfiniteScroll";
import HxfOverlayLoading from "../../../../../CustomElements/HxfOverlayLoading/HxfOverlayLoading";
import DefineInputsDataPopup from "../../../../../CustomElements/statePopups/defineInputsDataPopup/DefineInputsDataPopup";
import DefineProductParameterizedVariableValuesPopup from "../../../../../CustomElements/statePopups/defineProductParameterizedVariableValues/DefineProductParameterizedVariableValuesPopup";
import DefineProductPopup from "../../../../../CustomElements/statePopups/defineProductPopup/DefineProductPopup";
import DefineProductQuantityPopup from "../../../../../CustomElements/statePopups/defineProductQuantityPopup/DefineProductQuantityPopup";
import DefineStockLocationPopup from "../../../../../CustomElements/statePopups/defineStockLocationPopup/DefineStockLocationPopup";
import TaskProgressBar from "../../../../../CustomElements/TaskProgressBar/TaskProgressBar";
import TaskProgressBarV2 from "../../../../../CustomElements/TaskProgressBarV2/TaskProgressBarV2";
import InAppTemplate from "../../../../../InAppTemplate/InAppTemplate";
import CornerButton from "../../../../Productions/Standard/ProductionProcess/CornerButton/CornerButton";
import ProductGenericTransfActionsPopup from "../TransferenceActions/ProductGenericTransfActionsPopup";
import { useWrapperProductTransfActionsStore } from "../TransferenceActions/ProductGenericTransfActionsPopupStore";
import styles from "./ReceivingOrderProductsShow.module.scss";


const CustomTableHead = styled(
	TableHead
 )((
	 {
		 theme
	 }
 ) => ({
 
    "& th":{
        backgroundColor: '#4b47a5',
        color:'white'
    }
 }));
 const CustomTableCell = styled(
	TableCell
 )((
	 {
		 theme
	 }
 ) => ({
  padding:5
 }));

function ReceivingOrderProductsShow(props) {
  const showProgressOnTheSelectedUom = true; //in the future we might want to make this a setting instead of a constant
  const setDefaultNoLotAsLotOnLoad = false; //in the future we might want to make this dynamic, its on false by default to force the worker to confirm it wants to use default
  const [present, dismiss] = useIonAlert();
  const receivingOrderId = props?.match?.params?.roId;
  const { sessionState } = useGlobalState();
  const [enableSendAll,setEnableSendAll] = useState(false);
  const [enableSendCustomProduct,setEnableSendCustomProduct] = useState(false);
  const [didMount, setDidMount] = useState(false);
  const previousDidMount = usePrevious(didMount);
  const sizePage = 1000;
  const {t} = useHxfTranslation();
  const loadedObjsRef = useRef(null);
  const syncSettings = useSyncSettings();
  const posContainerRef = useRef(null);
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const showProductStockLocations = useRef(null);//to use later if a feature is required to store items on specific locations
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
    useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [latestScannedIndex,setLatestScannedIndex] = useState(null);
  const [infoOrderData, setInfoOrderData] = useState({});

  const [
    receivingOrderProductsReceivedData,
    setReceivingOrderProductsReceivedData,
  ] = useState(null);

  const DISABLE_LOAD_MORE_RECEIVING_PRODUCTS = true;
  const ALLOW_SWAP_VIEWS = true;
  const [defaultViewMode, setDefaultViewMode] = useState("MOBILE"); //<"TABLE"|"MOBILE">
  const [allowAddProduct,setAllowAddProduct] = useState(false);
  const [viewMode, setViewMode] = useState("MOBILE"); //<"TABLE"|"MOBILE">
  const [addProductPopup, setAddProductPopup] = useState(null);
  const [addingProduct, setAddingProduct] = useState(null);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const infiniteScrollEnabledRef = useRef(false);
  const allowedInfiniteScrollLoadPageRef = useRef(0);
  const [indexReRender, setIndexReRender] = useState(0);
  const feedbackService = useFeedbackService();
  const [searchbarInput, setSearchbarInput] = useState("");
  const timerStartSearching = useRef(0);

  const receivingOrdersSearchFilters = useRef({});

  const [filtersApplied, setFiltersApplied] = useState([]);
  const previousFiltersApplied = usePrevious(filtersApplied);

  const [receivingLocation, setReceivingLocation] = useState(null);

  const [receivingActionsPopup, setReceivingActionsPopup] = useState(null);
  const productTransfActionsManager = useWrapperProductTransfActionsStore();
  const [receivingProducts, setReceivingProducts] = useState([]); //array of {Id_ReceivingOrder_Product, quantity};
  const previousReceivingProducts = usePrevious(receivingProducts);
  const [isPopupReceivingLocationOpen, setIsPopupReceivingLocationOpen] =
    useState(false);
  const [presentToast] = useIonToast();
  const [
    isPopupDefineProductQuantityOpen,
    setIsPopupDefineProductQuantityOpen,
  ] = useState(false);

  const [popupDefineParameterizedVariableValues, setPopupDefineParameterizedVariableValues] = useState(false);
  const [popupQuantityProductData, setPopupQuantityProductData] =
    useState(null);
  function isHidden(el) {
    return el.offsetParent === null;
  }
  const resetStateReceiving = () => {
    //after submitting
    loadedObjsRef.current = [];
    setReceivingProducts([]);
    setReceivingLocation(null);
  }
  const removeQtyReceivingProduct = (idReceivingProduct) => {
    let newReceivingProducts = [];
    for (let i = 0; i < receivingProducts.length; i++) {
      if (
        receivingProducts[i].Id_ReceivingOrder_Product !== idReceivingProduct
      ) {
        newReceivingProducts.push(receivingProducts[i]);
      }
    }

    setReceivingProducts(newReceivingProducts);
  };
  const updateQtyReceivingProduct = (idReceivingProduct, newQty) => {
    let newReceivingProducts = [...receivingProducts];
    for (let i = 0; i < newReceivingProducts.length; i++) {
      if (
        newReceivingProducts[i].Id_ReceivingOrder_Product === idReceivingProduct
      ) {
        newReceivingProducts[i].quantity = newQty;
        setReceivingProducts(newReceivingProducts);
        return true;
      }
    }

    newReceivingProducts.push({
      Id_ReceivingOrder_Product: idReceivingProduct,
      quantity: newQty,
    });
    setReceivingProducts(newReceivingProducts);
    return true;
  };

  const updateOverridingPPVV = (idReceivingProduct, partialArrayProductParameterizedVariables) => {
    let newReceivingProducts = [...receivingProducts];
    for (let i = 0; i < newReceivingProducts.length; i++) {
      if (
        newReceivingProducts[i].Id_ReceivingOrder_Product === idReceivingProduct
      ) {
        newReceivingProducts[i].overridingArrayProductParameterizedVariableValues = partialArrayProductParameterizedVariables;
        setReceivingProducts(newReceivingProducts);
        return true;
      }
    }

    newReceivingProducts.push({
      Id_ReceivingOrder_Product: idReceivingProduct,
      overridingArrayProductParameterizedVariableValues: partialArrayProductParameterizedVariables,
    });
    setReceivingProducts(newReceivingProducts);
  }

  const getQtyReceivingProductByIdReceivingProduct = (idReceivingProduct) => {
    for (let i = 0; i < receivingProducts.length; i++) {
      if (
        receivingProducts[i].Id_ReceivingOrder_Product === idReceivingProduct
      ) {
        return receivingProducts[i].quantity;
      }
    }

    return 0;
  };

  const getPreparedProductDataObj = (obj) => {
    return JSON.parse(obj?.productData);
  }

  const getObjPPVVS = (obj) =>  {
    obj = JSON.parse(JSON.stringify(obj)); //Avoid inmemory direct mod issues
    let productParams = [];
 
    if (obj.receivingOrderProductParameterizedVariablesValueData) {
      obj.receivingOrderProductParameterizedVariablesValueData = JSON.parse(
        obj.receivingOrderProductParameterizedVariablesValueData
      );
      obj.parameterizedVariablesData = JSON.parse(
        obj.parameterizedVariablesData
      );
      obj.productParameterizedVariablesData = JSON.parse(
        obj.productParameterizedVariablesData
      );
      for (
        let i = 0;
        i < obj.receivingOrderProductParameterizedVariablesValueData.length;
        i++
      ) {
        let varValue =
          obj.receivingOrderProductParameterizedVariablesValueData[i].Value;
        if (!varValue) {
          varValue = "";
        }
        let idProductParameterizedVariable =
          obj.receivingOrderProductParameterizedVariablesValueData[i]
            .Id_Product_ParameterizedVariable;

        for (let j = 0; j < obj.productParameterizedVariablesData.length; j++) {
          if (
            obj.productParameterizedVariablesData[j]
              .Id_Product_ParameterizedVariable ===
            idProductParameterizedVariable
          ) {
            let idParameterizedVariable =
              obj.productParameterizedVariablesData[j].Id_ParameterizedVariable;

            for (let k = 0; k < obj.parameterizedVariablesData.length; k++) {
              if (
                obj.parameterizedVariablesData[k].Id_ParameterizedVariable ==
                idParameterizedVariable
              ) {
                productParams.push({
                  paramCode: obj.parameterizedVariablesData[k].code,
                  paramName: obj.parameterizedVariablesData[k].name,
                  paramVal: varValue,
                });
                break;
              }
            }
            break;
          }
        }
      }
    }

    return productParams;
  }
  const getReceivingOrderProductTotalQuantity = (quantity, obj) => {
    if (showProgressOnTheSelectedUom && obj?.Id_UoM_Selected && quantity > 0) {
      quantity = UomService().convertQuantity(
        quantity,
        -1,
        obj.Id_UoM_Selected,
        obj.uomConversionsData
      );
    }

    return quantity;
  };
  const getViewModes = () => {
    let defViewMode = defaultViewMode;
    let viewModes = ["MOBILE","TABLE"];
      viewModes.sort((a, b) => {
        if (a === defViewMode) return -1;
        if (b === defViewMode) return 1;
        return 0;
    });
    return viewModes;
  }
  const getFinishedReceivingOrderProductQuantity = (
    idReceivingProduct,
    obj
  ) => {
    if (!receivingOrderProductsReceivedData) {
      return 0;
    }

    for (let i = 0; i < receivingOrderProductsReceivedData.length; i++) {
      if (
        receivingOrderProductsReceivedData[i].Id_ReceivingOrder_Product ===
        idReceivingProduct
      ) {
        let receivedQuantity =
          receivingOrderProductsReceivedData[i].receivedQuantity;
        if (
          showProgressOnTheSelectedUom &&
          obj?.Id_UoM_Selected &&
          receivedQuantity > 0
        ) {
          receivedQuantity = UomService().convertQuantity(
            receivedQuantity,
            -1,
            obj.Id_UoM_Selected,
            obj.uomConversionsData
          );
        }

        return receivedQuantity;
      }
    }
    return 0;
  };
  const isReceivingLocationSet = () => {
    return receivingLocation?.Id_WarehouseLocation ? true : false;
  };

  const hasAddedReceivingProducts = () => {
    return receivingProducts.length > 0;
  };

  const initiateFilterCode = (val) => {
    setSearchbarInput(val);
    clearTimeout(timerStartSearching.current); //stops spam requests
    timerStartSearching.current = setTimeout(
      () => {
        receivingOrdersSearchFilters.current = {
          ...receivingOrdersSearchFilters.current,
          searchByCode: val,
        };
        restartSearch();
      },
      500,
      val
    );
  };

  const onFilterChipRemove = (index) => {
    let newFiltersApplied = [];
    for (let i = 0; i < filtersApplied.length; i++) {
      if (i !== index) {
        newFiltersApplied.push(filtersApplied[i]);
      }
    }
    setFiltersApplied(newFiltersApplied); //will trigger useeffect
  };

  const getProperFilterChip = (obj, index) => {
    //custom styling of chip
    if (obj.filterKey === "Id_BuyOrder") {
      return (
        <CustomAppliedFilterChip
          filterTextElement={
            <>
              <b>{t("buy.order")}:</b> {obj.orderCode}
            </>
          }
          onRemoveFilter={() => {
            onFilterChipRemove(index);
          }}
        />
      );
    }

    let labelDefault = obj?.filterLabel ? obj.filterLabel : obj.filterKey;
    let chipVal = obj[obj.filterKey];
    //default
    return (
      <CustomAppliedFilterChip
        filterTextElement={
          <>
            <b>{labelDefault}:</b> {chipVal}
          </>
        }
        onRemoveFilter={() => {
          onFilterChipRemove(index);
        }}
      />
    );
  };
  const openAddCustomProduct = () => {
    setAddProductPopup({});
  }
  const getTaskUomLabel = (obj) => {
    if (obj?.temp_UoM_SelectedLabel) {
      return obj.temp_UoM_SelectedLabel;
    } else if (obj?.temp_UoM_BaseLabel) {
      return obj.temp_UoM_BaseLabel;
    }
    return "";
  };
  const getFormattedFiltersForRequest = (filtersArray) => {
    let filtersArr = [...filtersArray];
    let formattedFilters = {};
    for (let i = 0; i < filtersArr.length; i++) {
      let filterKey = filtersArr[i].filterKey;
      let filterValue = filtersArr[i][filterKey];
      formattedFilters[filterKey] = filterValue;
    }

    return formattedFilters;
  };

  const isProductCompleted = (obj) => {
    let tasksDone = getFinishedReceivingOrderProductQuantity(obj.Id, obj);
    let totalTasks = getReceivingOrderProductTotalQuantity(obj.Quantity, obj);
    if(parseFloat(tasksDone) >= parseFloat(totalTasks)){
      return true;
    }

    return false;
  }

  const selectProduct = (obj, index) => {

    let defineProductParameterizedVariables = []; //if any pvv is null it means it has to be defined here in the receiving order before asking the quantity
    if (obj?.arrayProductParameterizedVariables) {
      let arrayProductParameterizedVariables = obj.arrayProductParameterizedVariables;
      for(let i = 0; i<arrayProductParameterizedVariables.length; i++){
        if(arrayProductParameterizedVariables[i].Value === "" || arrayProductParameterizedVariables[i].Value === null){
          defineProductParameterizedVariables.push(arrayProductParameterizedVariables[i]);
        }
      }
    }


    let practions = obj?.productReceivingActions ? obj?.productReceivingActions : [];
    if(practions.length === 0){
      callActionReceiveQty(index, obj,defineProductParameterizedVariables);
    }else{
      callReceivingActions(index, obj, defineProductParameterizedVariables);
    }
    
  }

  const attemptOpenProduct = (obj, index) => {
    /*if(!ALLOW_DROP_SHOPFLOOR_LOCATION && !shippingLocation?.Id_WarehouseLocation){
      feedbackService.notifyToast(t('define.stock.taking.location'), "warning");
      return;
    }*/

    selectProduct(obj, index);
  }
  const getAddingQtyElem = (idReceivingProduct, obj) => {
    let qty = getQtyReceivingProductByIdReceivingProduct(idReceivingProduct);

    if (qty <= 0 || !qty) {
      return <></>;
    }

    let uomLabel = "";

    if (showProgressOnTheSelectedUom && obj?.Id_UoM_Selected && qty > 0) {
      qty = UomService().convertQuantity(
        qty,
        -1,
        obj.Id_UoM_Selected,
        obj.uomConversionsData
      );
      uomLabel = obj?.temp_UoM_SelectedLabel;
    }

    return (
      <div  className={`${viewMode === "TABLE" ? styles.addingQtyTable : ""} ${viewMode === "MOBILE" ? styles.addingQtyMobile : ""}`}>
        <div>
          +{qty} {uomLabel}
        </div>
      </div>
    );
  };


  const callReceivingActions = (index, obj, definePPVVS) => {
  
    setReceivingActionsPopup({index:index, obj:obj, defineProductParameterizedVariables:definePPVVS, startFromActionIndex:0});

  }

  const callActionReceiveQty = (index, obj,defineProductParameterizedVariables) => {
    let productData = JSON.parse(obj.productData);
    let passData = {
      Id_ReceivingOrder_Product: obj.Id,
      Id_Product: productData.Id_Product,
      name: productData.name,
      code: productData.code,
      qtyValue: 0,
      uomLabel: null,
      Id_UoM_Selected: null,
      uomConversionsData: obj?.uomConversionsData,
    };

    let qtyValue = getQtyReceivingProductByIdReceivingProduct(obj.Id);
    passData.qtyValue = qtyValue;

    if (obj?.Id_UoM_Selected) {
      //has default uom selected
      passData.uomLabel = obj?.temp_UoM_SelectedLabel;
      passData.Id_UoM_Selected = obj?.Id_UoM_Selected;
      //convert back to base
      passData.qtyValue = UomService().convertQuantity(
        qtyValue,
        -1,
        obj.Id_UoM_Selected,
        obj.uomConversionsData
      );
    } else {
      //no uom was selected on the shipping, so we use the label of the base uom
      passData.uomLabel = obj.temp_UoM_BaseLabel;
    }
    setPopupQuantityProductData(passData);

    //if to define ppvs, open define ppv popup
    if(defineProductParameterizedVariables.length > 0){

      let idRop = obj?.Id;

      for(let i = 0; i<receivingProducts.length; i++){
        if(receivingProducts[i].Id_ReceivingOrder_Product === idRop){
          let overridingArrayProductParameterizedVariableValues = receivingProducts[i].overridingArrayProductParameterizedVariableValues;
          if(overridingArrayProductParameterizedVariableValues){
            for(let j = 0; j<overridingArrayProductParameterizedVariableValues.length; j++){
              let overrideIdProductPv = overridingArrayProductParameterizedVariableValues[j].Id_Product_ParameterizedVariable;
              for(let k = 0; k<defineProductParameterizedVariables.length; k++){
                let defIdProductPv = defineProductParameterizedVariables[k].Id_Product_ParameterizedVariable;
                if(defIdProductPv === overrideIdProductPv){
                  defineProductParameterizedVariables[k].Value = overridingArrayProductParameterizedVariableValues[j].Value;
                  break;
                }
              }
            }
          }
        }
      }
 
      // override defineProductParameterizedVariables where it was defined
      setPopupDefineParameterizedVariableValues({obj:obj, index:index, popupData:{name:productData.name,code:productData.code,arrayProductParameterizedVariables:defineProductParameterizedVariables}});
    }else{
      setIsPopupDefineProductQuantityOpen(true);
    }
  }

  const getObjProductCode = (obj) => {
    let productData = JSON.parse(obj.productData);
    return productData?.code;
  }
  const getReceivingOrderProductElement = (obj, index) => {
    let productData = getPreparedProductDataObj(obj);

    let defineProductParameterizedVariables = []; //if any pvv is null it means it has to be defined here in the receiving order before asking the quantity


    obj = { ...obj };
    let productParams = [];
    if (obj?.arrayProductParameterizedVariables) {
      let arrayProductParameterizedVariables = obj.arrayProductParameterizedVariables;
      for(let i = 0; i<arrayProductParameterizedVariables.length; i++){

        productParams.push({
          paramCode:arrayProductParameterizedVariables[i].code,
          paramName:arrayProductParameterizedVariables[i].name,
          paramVal:arrayProductParameterizedVariables[i].Value
        });

        if(arrayProductParameterizedVariables[i].Value === "" || arrayProductParameterizedVariables[i].Value === null){
          defineProductParameterizedVariables.push(arrayProductParameterizedVariables[i]);
        }
      }

    }

    return (
      <IonCardHeader
        data-receiving-order-product-code={productData.code}
        className={styles.elemElement}
        onClick={() => {
          attemptOpenProduct(obj,index);
        }}
      >
        <div className={styles.elemBackgroundContainer}>
          <HighlightAltIcon />
        </div>
        <div className={styles.orderNameCodeContainer}>
          <div className={styles.orderTopLabel}>
            <b>{t('product')}</b>
          </div>
          <div className={styles.orderNameCode}>
            <div
              className={` ${styles.orderLabel} ${styles.doubleLineOverFlowElipsis}`}
            >
              ({productData.code}) {productData.name}
            </div>
          </div>

        </div>

        {productParams.length > 0 && (
          <div className={styles.orderNameCodeContainer}>
            <div className={styles.orderTopLabel}>
              <b>{t("parameterizedvariables")}</b>
            </div>
            <div>
              {productParams.map((paramObj, idxParamObj) => (
                <span className={styles.pvContainer} key={"rop_" + index + "_ppv_" + idxParamObj}>
                  <b> {paramObj.paramName}: </b>
                  {paramObj.paramVal}
                </span>
              ))}
            </div>
          </div>
        )}

        <div className={styles.progressBarContainer}>
          <TaskProgressBar
            width={"325px"}
            tasksDone={getFinishedReceivingOrderProductQuantity(obj.Id, obj)}
            totalTasks={getReceivingOrderProductTotalQuantity(
              obj.Quantity,
              obj
            )}
            totalTasksNumberLabel={getTaskUomLabel(obj)}
          />
        </div>
        {getAddingQtyElem(obj.Id, obj)}
      </IonCardHeader>
    );
  };

  const isMatchingAll = () => {
    if (loadedObjsRef?.current === null) {
      return false;
    }

    for (let i = 0; i < loadedObjsRef.current.length; i++) {
      let matched = false;
      for (let j = 0; j < receivingProducts.length; j++) {
        if (
          loadedObjsRef.current[i].Id ===
          receivingProducts[j].Id_ReceivingOrder_Product
        ) {
          let totalQty = loadedObjsRef.current[i].Quantity;
          if (receivingProducts[j].quantity >= totalQty) {
            matched = true;
            break;
          }
        }
      }

      if (!matched) {
        return false;
      }
    }

    return true;
  };
  const getReceivingLocationElement = () => {
    if (!receivingLocation?.Id_WarehouseLocation) {
      return (
        <>
          <b>{t("location")}:</b> {t("shopfloor")}
        </>
      );
    }

    return (
      <div>
        <div className={styles.locDiv}>
          <div>
            <b>{t("warehouselocation")}:</b>
          </div>
          <div>{receivingLocation?.temp_warehouseLocationLabel}</div>
        </div>

        {!receivingLocation?.temp_warehouseLabel ? (
          <div className={styles.incompleteDefinedStockLocation}>
            {t("no.warehouse.defined")}.
          </div>
        ) : (
          <>
            <div className={styles.locDiv}>
              <div>
                <b>{t("warehouse")}:</b>
              </div>
              <div>{receivingLocation?.temp_warehouseLabel}</div>
            </div>
          </>
        )}

        {!receivingLocation?.temp_lotLabel ? (
          <div className={styles.incompleteDefinedStockLocation}>
            {t("no.lot.defined")}.
          </div>
        ) : (
          <>
            <div className={styles.locDiv}>
              <div>
                <b>{t("lot")}:</b>
              </div>
              <div>{receivingLocation?.temp_lotLabel}</div>
            </div>
          </>
        )}
      </div>
    );
  };
  const getInfoOrderBuyOrder = () => {
    let buyOrderData = infoOrderData?.buyOrderData;
  

    if (!buyOrderData?.Id_BuyOrder) {
      return <></>;
    }
    buyOrderData = JSON.parse(buyOrderData);
    return (
      <div>
        <div>
          <b>{t('buy.order')}:</b>
        </div>
        <div>
          ({buyOrderData.code}) {buyOrderData.name}
        </div>
      </div>
    );
  };

  const callGoBack = () => {
    let buyOrderData = infoOrderData?.buyOrderData;
    if(buyOrderData){
      buyOrderData = JSON.parse(buyOrderData);
      if (buyOrderData?.Id_BuyOrder) {
        props.history.push({
          pathname: "/feature/receivings/receiving-orders",
          state: {
            applyFilters: [
              {
                filterKey: "Id_BuyOrder",
                Id_BuyOrder: buyOrderData?.Id_BuyOrder,
                orderCode: buyOrderData.code,
                orderName: buyOrderData.name,
              },
            ],
          },
        });
        return;
      }
     
    }

     props.history.push("/feature/receivings");
    
  };
  const loadMore = useCallback(
    (passedFilters = null) => {
      let currentPage = allowedInfiniteScrollLoadPageRef.current;

      let extraFiltersApply = {};
      if (passedFilters) {
        extraFiltersApply = getFormattedFiltersForRequest(passedFilters);
      } else {
        extraFiltersApply = getFormattedFiltersForRequest(filtersApplied);
      }
      setLoadingPage(true);
      receivingOrderProductsController()
        .getReceivingOrderProducts({
          page: currentPage,
          size: sizePage,
          getDefaultReceivingLocation: true,
          includeProductReceivingActions:true,
          openOnly: true,
          getAutoFillSettings: true,
          Id_ReceivingOrder: receivingOrderId,
          ...receivingOrdersSearchFilters.current,
          ...extraFiltersApply,
        })
        .then((resp) => {
          setLoadingPage(false);
          syncSettings.check(resp);
          let posData = resp.data.response.data.return;
          let posTotalSize = resp.data.response.data.totalSize;
          let posExtraData = resp.data.response.data.extra;

          if (currentPage === 0) {
            //first load only
            //loads the extraData that loads in the backend only when the page = 0
            //this is done to avoid double requests just to gather extra unrelated data

            if(posExtraData?.shippingOrders_sendAllButton){
              setEnableSendAll(true);
            }
            
            if(posExtraData?.receivingOrders_addProductBtn){
              setEnableSendCustomProduct(true);
            }
            if (posExtraData?.receivingOrderData) {
              setInfoOrderData(posExtraData.receivingOrderData);
            }

            if (posExtraData?.receivingOrderProducts_received) {
              setReceivingOrderProductsReceivedData(
                posExtraData?.receivingOrderProducts_received
              );
            }
           
            if(posExtraData?.receivingOrders_addProductBtn){
              setAllowAddProduct(true);
            }
            if(posExtraData?.wsReceivingOrderProduct_defaultView){
              let defaultView = posExtraData?.wsReceivingOrderProduct_defaultView;
              if(defaultView === "MOBILE" || defaultView === "TABLE"){
                setViewMode(defaultView);
                setDefaultViewMode(defaultView);
              }

            }

            if (posExtraData?.defaultReceivingLocation) {
              let useShopfloor =
                posExtraData.defaultReceivingLocation.useShopfloor === 1
                  ? true
                  : false;
              if (useShopfloor) {
                setReceivingLocation({});
              } else if (
                !posExtraData?.defaultReceivingLocation?.Id_WarehouseLocation ||
                posExtraData.defaultReceivingLocation.Id_WarehouseLocation ===
                  null
              ) {
                setReceivingLocation(null);
              } else {
                let newReceivingLocation = {};

                let idWarehouseLocation =
                  posExtraData?.defaultReceivingLocation?.Id_WarehouseLocation;
                let warehouseLocationLabel =
                  posExtraData?.defaultReceivingLocation
                    ?.warehouseLocationLabel;
                let idWarehouse =
                  posExtraData?.defaultReceivingLocation?.Id_Warehouse;
                let warehouseLabel =
                  posExtraData?.defaultReceivingLocation?.warehouseLabel;

                let idLot = posExtraData?.defaultReceivingLocation?.Id_Lot;
                let lotLabel = posExtraData?.defaultReceivingLocation?.lotLabel;

                let defaultNoLotObj =
                  posExtraData?.defaultReceivingLocation?.defaultNoLotObj;

                newReceivingLocation["Id_WarehouseLocation"] =
                  idWarehouseLocation;
                newReceivingLocation["temp_warehouseLocationLabel"] =
                  warehouseLocationLabel;

                if (idWarehouse) {
                  newReceivingLocation["Id_Warehouse"] = idWarehouse;
                  newReceivingLocation["temp_warehouseLabel"] = warehouseLabel;
                }

                if (idLot) {
                  newReceivingLocation["Id_Lot"] = idLot;
                  newReceivingLocation["temp_lotLabel"] = lotLabel;
                }

                if (defaultNoLotObj) {
                  newReceivingLocation["defaultNoLotObj"] = defaultNoLotObj;

                  if (!idLot && setDefaultNoLotAsLotOnLoad) {
                    newReceivingLocation["Id_Lot"] = defaultNoLotObj.Id_Lot;
                    newReceivingLocation["temp_lotLabel"] =
                      "(" + defaultNoLotObj.code + ") " + defaultNoLotObj.name;
                  }
                }

                setReceivingLocation(newReceivingLocation);
              }
            }
          }

          let newArrayLoadedObjs = [];

          if (loadedObjsRef.current !== null) {
            newArrayLoadedObjs = [...loadedObjsRef.current, ...posData];
          } else {
            newArrayLoadedObjs = posData;
          }
          loadedObjsRef.current = newArrayLoadedObjs;
          let hasMoreElements =
            newArrayLoadedObjs.length < posTotalSize && posData.length !== 0;
          if (hasMoreElements) {
            allowedInfiniteScrollLoadPageRef.current =
              allowedInfiniteScrollLoadPageRef.current + 1;
            infiniteScrollEnabledRef.current = true;
          } else {
            infiniteScrollEnabledRef.current = false;
          }
          if (currentPage === 0) {
            setLoadingPage(false);
          }
          //setLoadedObjs(loadedObjsRef.current);
          setIsLoadingMore(false);
          setIndexReRender(indexReRender + 1);
        })
        .catch((resp) => {
          setLoadingPage(false);
          console.log("THE ERROR: ", resp);
          if (resp["error"] && resp["error"]["error_type"] == "offline_fail") {
            if (failedToLoadPosError) {
              setFailedToLoadPosError(false);
            }
            setRequiresNetworkLoadError(true);
          } else {
            if (requiresNetworkLoadError) {
              setRequiresNetworkLoadError(false);
            }
            setFailedToLoadPosError(true);
          }
          setLoadingPage(false);
        });
    },
    [
      setDefaultNoLotAsLotOnLoad,
      receivingOrderId,
      failedToLoadPosError,
      indexReRender,
      requiresNetworkLoadError,
      filtersApplied,
      syncSettings
    ]
  );

  const canShowReceiveAll = () => {

    if(!enableSendAll){
      return false;
    }
    let arrayRecProducts =  loadedObjsRef?.current;

    if(arrayRecProducts){
      for (let i = 0; i < arrayRecProducts.length; i++) {
        let arrayProductParameterizedVariables = arrayRecProducts[i]?.arrayProductParameterizedVariables;
        if(arrayProductParameterizedVariables){
          for(let i = 0; i<arrayProductParameterizedVariables.length; i++){
            if(arrayProductParameterizedVariables[i].Value === null || arrayProductParameterizedVariables[i].Value === ""){
              return false; //currently we dont want to allow "receive all" when there are variables to define, due to the fact it would create stock movements with null variables, we can later enable it by rethinking its engineering for this scneario
            }
          }
        }
      }
    }

    return true;
  }

  const resetCurrentStates = () => {
    setReceivingProducts([]);
    restartSearch();
    productTransfActionsManager.reset();
  }
  const restartSearch = useCallback(() => {
    allowedInfiniteScrollLoadPageRef.current = 0;
    loadedObjsRef.current = null;

    loadMore();
  }, [loadMore, loadedObjsRef, allowedInfiniteScrollLoadPageRef]);
  useEffect(() => {
    if (!didMount) {
      let loadingFilters = props?.location?.state?.applyFilters;

      if (loadingFilters) {
        setFiltersApplied(loadingFilters);
      } else {
        loadingFilters = null;
      }

      window.history.replaceState({}, document.title); //clear history state

      loadMore(loadingFilters);
      productTransfActionsManager.reset();
      setDidMount(true);
    }
  }, [
    props?.location?.state?.applyFilters,
    didMount,
    failedToLoadPosError,
    requiresNetworkLoadError,
    sessionState,
    loadMore,
  ]);

  useEffect(() => {
    if (previousDidMount === didMount && didMount) {
      if (previousFiltersApplied !== filtersApplied) {
        console.log("filters were changed");
        restartSearch();
      }
    }
  }, [
    filtersApplied,
    previousDidMount,
    didMount,
    previousFiltersApplied,
    restartSearch,
  ]);

  if (!didMount) {
    return <></>;
  }

  if (!receivingOrderId) {
    return (
      <InAppTemplate>
        <CustomArrowBack
          onClick={() => {
            callGoBack();
          }}
        />
        <div className={styles.pageTitle}>{t("receivingorders.products")}</div>
      </InAppTemplate>
    );
  }

  return (
    <InAppTemplate>
      {receivingActionsPopup && !receivingActionsPopup?.isHandlingReceiveProduct && (
        <ProductGenericTransfActionsPopup startFromActionIndex={receivingActionsPopup?.startFromActionIndex} type="RECEIVING" onClose={() => {
          setReceivingActionsPopup(null);
        }} onFinishProcess={() => {
          //finished all actions
          setReceivingActionsPopup(null);
        }}  onRequestReceiveProductAction={(currentIndex) => {
          
          callActionReceiveQty(receivingActionsPopup.index, receivingActionsPopup.obj,receivingActionsPopup.defineProductParameterizedVariables);
          setReceivingActionsPopup({...receivingActionsPopup, startFromActionIndex:currentIndex+1, isHandlingReceiveProduct:true});
        }} indexObj={receivingActionsPopup.index} objTransfProduct={receivingActionsPopup.obj} />
    
      )}
      {isPopupReceivingLocationOpen && (
        <DefineStockLocationPopup
          allowLotCreation={true}
          subtitle={t("receivings.define.receiving.to")}
          currentData={receivingLocation}
          title={t("receivinglocation")}
          onClosePopup={() => {
            setIsPopupReceivingLocationOpen(false);
          }}
          onSuccessConfirm={(result) => {
            if (!result?.Id_Lot && result?.defaultNoLotObj) {
              result.Id_Lot = result.defaultNoLotObj.Id_Lot;
              result.temp_lotLabel =
                "(" +
                result.defaultNoLotObj.code +
                ") " +
                result.defaultNoLotObj.name;
            }

            setReceivingLocation(result);
            setIsPopupReceivingLocationOpen(false);
            console.log("success", result);
          }}
        />
      )}

      {isPopupDefineProductQuantityOpen && (
        <DefineProductQuantityPopup
          productData={popupQuantityProductData}
          title={t('receiving.qty')}
          onClosePopup={() => {
            setIsPopupDefineProductQuantityOpen(false);
            setPopupQuantityProductData(null); //so that when called again, it doesnt render hxfkeyboard before loading the data
          }}
          onSuccessConfirm={(res) => {
            let idReceivingProduct = popupQuantityProductData.Id_ReceivingOrder_Product;

            let quantityResult = res.quantityResult;

            if (quantityResult === 0) {
              removeQtyReceivingProduct(idReceivingProduct);
            } else {
              if (res?.newProductData?.Id_UoM_Selected !== -1) {
                //convert qty received back to base uom
                quantityResult = UomService().convertQuantity(
                  quantityResult,
                  res.newProductData.Id_UoM_Selected,
                  -1,
                  res.newProductData.uomConversionsData
                );
              }
              updateQtyReceivingProduct(idReceivingProduct, quantityResult);
            }

            setIsPopupDefineProductQuantityOpen(false);
            setPopupQuantityProductData(null); //so that when called again, it doesnt render hxfkeyboard before loading the data
            if(receivingActionsPopup?.isHandlingReceiveProduct){
              setReceivingActionsPopup({...receivingActionsPopup, isHandlingReceiveProduct:false});
           
              //resume
            }
          }}
        />
      )}

      {popupDefineParameterizedVariableValues && (
        <>
        <DefineProductParameterizedVariableValuesPopup
            allowCurrentDataValuesLoad={true}
            currentData={popupDefineParameterizedVariableValues.popupData}
            title={t('product.parameters')}
            onClosePopup={() => {
              setPopupDefineParameterizedVariableValues(null);
            }}
            onSuccessConfirm={(res) => {
              /*
              let objProductData = { ...objProduct };
              objProductData.productParameterizedVariables = res;
              setObjProduct(objProductData);*/
              if(!res || res.length === 0){
                return;
              }
              let ppvsToOverride = res;
              let idReceivingOrderProduct = popupDefineParameterizedVariableValues.obj.Id;
              updateOverridingPPVV(idReceivingOrderProduct,ppvsToOverride);

              setIsPopupDefineProductQuantityOpen(true);
              setPopupDefineParameterizedVariableValues(null);
            }}/>
        </>
      )}

      <div className={styles.topPageTitleBtnsContainer}>
        <div className={styles.arrowSpaceFiller}>
          <CustomArrowBack
            onClick={() => {
              callGoBack();
            }}
          />
        </div>
        <div className={styles.pageTitle}>{t("receivingorders.products")}</div>
        <div className={styles.cornerBtnsContainer}>
          <CornerButton
            onClick={() => {
              present({
                cssClass: "my-css",
                header: t("receivingorder.leave"),
                message: t("receivingorder.exit.confirm"),
                buttons: [
                  t("cancel"),
                  {
                    text: t("yes"),
                    handler: (d) => {
                      callGoBack();
                    },
                  },
                ],
                onDidDismiss: (e) => {},
              });
            }}
            btnType={"danger"}
            label={t("cancel")}
            iconPassed={closeCircle}
          />
        </div>
      </div>
      {addingProduct?.step === "QUANTITY" && (
            <DefineInputsDataPopup
            onClose={() => {
              setAddingProduct(null);
            }}
            windowSettings={{contentHeightPct:85,customPopupMaxHeight:'600px'}}
            title={t('quantity.to.receive')}
            mapValuesErrors={addingProduct?.mapValuesErrors ? addingProduct.mapValuesErrors : {}}
            arrayInputs={[
              {
                customInputIdentifier:'QTY_INPUT',
                label:t('quantity'),
                inputType:"TEXTFIELD_NUMERIC",
                defaultValue:'',
                typeInputTextAutoFocus:true,
                aboveContent:(
                  <div>
                    <div className={styles.typeQtyContainer}>{t('type.in.quantity.to.receive')}</div>
                    <div className={styles.iconContainer}>
                      <div className={styles.iconRec}><WarehouseIcon/></div>
                    </div>
                  </div>
                )
              }
            ]}
            arrayConfirmationButtons={[
              {
                label:t('confirm'),
                labelStyles:{
                  fontSize:25,
                  margin:20
                },
                btnStyles:{
                  minHeight:80,
                  display:'flex',
                  justifyContent:'center'
                },
                onClick: (data) => {
                  let newQty = data?.mapInputsValues?.[0];
                  if(!newQty || parseFloat(newQty) <= parseFloat(0)){
                    setAddingProduct({...addingProduct,mapValuesErrors:{'QTY_INPUT':true}});
                  }else{
                    let newAddingProduct = {...addingProduct};
                    newAddingProduct.Quantity = newQty;
                    delete newAddingProduct.step;
                    delete newAddingProduct.mapValuesErrors;
                    let autoReceiveAddedProducts = true;
                    setIsLoadingAction(true);
                    let optsAdd = {
                      Id_ReceivingOrder:receivingOrderId,
                      productsToAdd:[newAddingProduct],
                      autoReceiveAddedProducts:autoReceiveAddedProducts
                    };

                    if(autoReceiveAddedProducts){
                      optsAdd.Id_WarehouseLocation = receivingLocation?.Id_WarehouseLocation ? receivingLocation?.Id_WarehouseLocation : null;
                      optsAdd.Id_Warehouse = receivingLocation?.Id_Warehouse ? receivingLocation?.Id_Warehouse : null;
                      optsAdd.Id_Lot = receivingLocation?.Id_Lot ? receivingLocation?.Id_Lot : null;
                    }
                    
                    receivingOrderProductsController().addProductReceivingOrderProducts(optsAdd).then((res) => {
                      setIsLoadingAction(false);
                      if(!res?.data?.done){
                        throw "unexpected";
                      }
                      feedbackService.notifyToast(t('product.added.successfully'), "success");
                      resetStateReceiving();
                      loadMore(filtersApplied);
                    }).catch((res) => {
                      setIsLoadingAction(false);
                      feedbackService.notifyToast(t('error.unexpected'), "error");
                    });
                    setAddingProduct(null);
                  
                  }
            

                  
                }
              }
            ]}
          />
      )}
      {addProductPopup && (
        <DefineProductPopup onClose={() => {
          setAddProductPopup(null);
        }} onConfirm={(resultProductSelected) => {
          
          setAddingProduct({...resultProductSelected, step:"QUANTITY"});
          setAddProductPopup(null);
        }}/>
      )}
      {loadingPage ? (
        <>
          <LoadingSpinnerImg />
        </>
      ) : (
        <>
        {isLoadingAction && (
          <>
          <HxfOverlayLoading />
          </>
        )}
        {!failedToLoadPosError && (
          <div className={styles.infoAndSelection}>
            {infoOrderData?.code && (
              <div className={styles.orderInfoContainer}>
                <div className={styles.orderInfo}>
                  <div className={styles.orderInfoContent}>
                    <div>
                      <div>
                        <b>{t("receivingorder")}:</b>
                      </div>
                      <div>
                        ({infoOrderData.code}) {infoOrderData.name}
                      </div>
                    </div>
                    {getInfoOrderBuyOrder()}
                    <div>
                      <div>
                        <b>{t("date.due")}</b>
                      </div>
                      <div>
                        {infoOrderData?.dueDate
                          ? DatesConversionService().formattedDate(
                              infoOrderData.dueDate
                            )
                          : " " + t("date.any") +" "}
                      </div>
                    </div>


                    {infoOrderData?.receivingFrom_Id_Supplier && (
                      <div>
                        <div>
                          <b>{t("supplier")}:</b>
                        </div>
                        <div>
                          {infoOrderData?.receivingFrom_supplierName}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className={styles.receivingLocationContainer}>
              <div className={styles.receivingLocation}>
                <div className={styles.receivingLocationContent}>
                  <div className={styles.rlocTitle}>{t("receivinglocation")}</div>
                  {receivingLocation !== null ? (
                    <>
                      <div>{getReceivingLocationElement()}</div>
                    </>
                  ) : (
                    <div>{t("receiving.location.define")}</div>
                  )}
                </div>

                <IonButton
                  disabled={false}
                  className={styles.modifyLocationBtn}
                  onClick={() => {
                    setIsPopupReceivingLocationOpen(true);
                  }}
                >
                  <IonIcon slot="start" size="large" icon={albumsOutline} />
                   {t("location.modify")}
                </IonButton>
              </div>
            </div>
          </div>
          )}
          {/*<div className={styles.settingsContainer}>
              <div className={styles.customSearchBarContainer}>
              <CustomSearchBar 
              value={searchbarInput}
              onResetTextClick={() => {
                initiateFilterCode("");
              }}
              onChange={(evt) => {
                initiateFilterCode(evt.target.value);
                

              }}
              iconElement={<div className={styles.searchIconStyles}><IonIcon icon={searchCircleOutline}/></div>} placeholder={"Search Product"}/>
              </div>

              </div>*/}
          {filtersApplied.length > 0 && (
            <div className={styles.appliedFiltersContainer}>
              {filtersApplied.map((obj, index) => (
                <div key={"cafc_" + index}>
                  {getProperFilterChip(obj, index)}
                </div>
              ))}
            </div>
          )}
          {ALLOW_SWAP_VIEWS && (
              <div className={styles.displayViews}>
              {getViewModes().map((objMode, indexMode) => (
                <>
                    <div key={"viewMode_" + indexMode}>
                      {objMode === "MOBILE" && (
                          <IonButton
                          style={{width:64}}
                          color={viewMode === "MOBILE" ? "tertiary" : "medium"}
                          className={styles.closeWindowButton}
                          onClick={() => {
                            setViewMode("MOBILE");
                          }}
                        >
                          
                          <SettingsCellIcon/>
                        </IonButton>
                      )}
                      {objMode === "TABLE" && (
                          <IonButton
                          style={{width:64}}
                          color={viewMode === "TABLE" ? "tertiary" : "medium"}
                          className={styles.closeWindowButton}
                          onClick={() => {
                            setViewMode("TABLE");
                          }}
                        >
                          <IonIcon slot="icon-only" size="small" icon={listOutline} />
                    
                        </IonButton>
                      )}
                    </div>
                </>
              ))}

               
              </div>
          )}

          {!failedToLoadPosError && (
          <div className={styles.receivingOrdersContainer}>

          {viewMode === "TABLE" && (
            <div className={styles.tableViewMode}>
                <div className={styles.tableFormatContainer}>

                {allowAddProduct && (
                  <div className={styles.toolbtns}>
                    <IonButton
                      color="primary"
                      className={styles.closeWindowButton}
                      onClick={() => {
                        openAddCustomProduct();
                      }}
                    >
                      <IonIcon slot="start" size="large" icon={addOutline} />
                      {t('add.product')}
                    </IonButton>
                  </div>
                )}



                <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                              <CustomTableHead>
                                  <TableRow>
                                      <CustomTableCell><b>{t('product')}</b></CustomTableCell>
                                      {showProductStockLocations.current && (
                                        <CustomTableCell align="center">
                                                                              
                                            <b>{t('warehouse')}</b>
                                          
                                        </CustomTableCell>
                                      )}
                                      <CustomTableCell align="center"><b>{t('progress')}</b></CustomTableCell>
                                      <CustomTableCell align="center">
                                        
                                          <b>{t('action')}</b>
                                       
                                      </CustomTableCell>
                                  </TableRow>
                              </CustomTableHead>
                              <TableBody>
                                
                                
                              {!failedToLoadPosError && loadedObjsRef.current !== null && (
                                  <>
                                    {loadedObjsRef.current.map((obj, index) => (
                                      <TableRow
                                        key={"sop_ship_" + index}
                                        data-receiving-order-row-product-code={getPreparedProductDataObj(obj).code}
                                        data-receiving-order-row-shipping-order-product-id={obj.Id}
                                        data-receiving-order-row-idx={index}
                                        className={`${latestScannedIndex === index ? styles.latestScannedRow : ""}`}
                                        onClick={() => {
                                          if(index !== latestScannedIndex){
                                            setLatestScannedIndex(null);
                                          }
                                        }}
                                      >
                                        <CustomTableCell>
                                          <>
                                           <div>({getPreparedProductDataObj(obj).code})</div>
                                           <div>{getPreparedProductDataObj(obj).name}</div>
                                           {getObjPPVVS(obj).length > 0 && (
                                            <>
                                                {getObjPPVVS(obj).map((paramObj, idxParamObj) => (
                                                  <div className={styles.pvvs} key={"shptablep_" + index + "_ppv_" + idxParamObj}>
                                                    <div><b> {paramObj.paramName} </b></div>
                                                    <div>{paramObj.paramVal}</div>
                                                  </div>
                                                ))}
                                            </>
                                           )}

                                          {obj?.description && obj?.description !== "" && (
                                            <div>
                                              <div>
                                                <b>{t('shippingorder.product.desc.short')}</b>
                                              </div>
                                              <div>
                                                {obj.description}
                                              </div>
                                            </div>
                                          )}
                                          </>
                                        </CustomTableCell>

                                        {showProductStockLocations.current && (
                                          <CustomTableCell  align="center">
                                            <div style={{maxWidth:100,overflowWrap:'anywhere'}}>
                                             {getLabelLocationStock(obj?._stockAvailableLocations)?.warehousesLabel}
                                            </div>
                                            
                                          </CustomTableCell>
                                        )}
                                        <CustomTableCell align="center">

                                          <div style={{display:'flex',justifyContent:'center'}}>
                                              <div className={`${styles.tableProgressContainer} `}>
                                                <TaskProgressBarV2
                                                fillerColor={isProductCompleted(obj) ? '#a0dca070' : null}
                                                  width={"125px"}
                                                  tasksDone={getFinishedReceivingOrderProductQuantity(obj.Id, obj)}
                                                  totalTasks={getReceivingOrderProductTotalQuantity(obj.Quantity, obj)}
                                                  totalTasksNumberLabel={getTaskUomLabel(obj)}
                                                />
                                              </div>
                                          </div>
                                           
                                          </CustomTableCell>
                                          <CustomTableCell align="center">
                                            <div>
                                              <div>
                                                <IonButton
                                                  color="primary"
                                                  className={styles.closeWindowButton}
                                                  onClick={() => {
                                                    attemptOpenProduct(obj,index);
                                                  }}
                                                >
                                                  <IonIcon slot="icon-only" size="large" icon={addOutline} />
                                                  {t('quantity.short')}
                                                </IonButton>
                                              </div>
                                              <div>{getAddingQtyElem(obj.Id, obj)}</div>
                                            </div>
                                          </CustomTableCell>
                                      </TableRow>
                                    ))}{" "}
                                  </>
                              )}

                              </TableBody>
                          </Table>
                      </TableContainer>

                </div>
            </div>
            )}    
          {viewMode === "MOBILE" && (
            <div
              id="receivingOrdersSelectionDiv"
              key={"rerender_ref_selection_" + indexReRender}
              className={styles.receivingOrdersSelection}
              ref={posContainerRef}
            >
              {enableSendCustomProduct && (
                  <IonCardHeader
                  className={`${styles.elemElement} ${styles.customProductElem}`}
                    onClick={() => {
                      openAddCustomProduct();
                    }}
                  >
                    <div className={styles.elemBackgroundContainer}>
                      <LibraryAddIcon />
                    </div>
  
                    <div className={styles.receiveAllTitle}>{t("add.product")}</div>
                  </IonCardHeader>
              )}
              {canShowReceiveAll() && (
                <IonCardHeader
                className={`${styles.elemElement} ${styles.receiveAllElem} ${
                  isMatchingAll() ? styles.receiveAllMatched : ""
                }`}
                  onClick={() => {
                    let newReceivingProducts = [];
                    for (let i = 0; i < loadedObjsRef.current.length; i++) {
                      let totalQty = loadedObjsRef.current[i].Quantity;
                      let idReceivingProduct = loadedObjsRef.current[i].Id;

                      let currentAddedQty =
                        getQtyReceivingProductByIdReceivingProduct(
                          idReceivingProduct
                        );
                      let currentReceivedQty =
                        getFinishedReceivingOrderProductQuantity(
                          idReceivingProduct
                        );
                      let totalAddedAndReceivedQty = currentAddedQty + currentReceivedQty;
                      let newAddingQtyVal = 0;
                      if (totalAddedAndReceivedQty < totalQty) {
                        newAddingQtyVal = totalQty - currentReceivedQty;
                      } else {
                        newAddingQtyVal = currentAddedQty;
                      }
                      if(newAddingQtyVal <= 0){
                        continue;
                      }
                      newReceivingProducts.push({
                        Id_ReceivingOrder_Product: idReceivingProduct,
                        quantity: newAddingQtyVal,
                      });
                    }

                    setReceivingProducts(newReceivingProducts);
                  }}
                >
                  <div className={styles.elemBackgroundContainer}>
                    <AddRoadIcon />
                  </div>

                  <div className={styles.receiveAllTitle}>{t("receive.all")}</div>
                </IonCardHeader>
              )}
              {!failedToLoadPosError && loadedObjsRef.current !== null ? (
                <>
                  {loadedObjsRef.current.map((obj, index) => (
                    <div
                      key={"ro_" + index}
                      className={styles.receivingOrderElement}
                    >
                      {getReceivingOrderProductElement(obj, index)}
                    </div>
                  ))}{" "}
                </>
              ) : (
                <></>
              )}

              {isLoadingMore && (
                <IonCardHeader
                  className={styles.elemElement}
                  onClick={() => {}}
                >
                  <div className={styles.elemBackgroundContainer}>
                    <HighlightAltIcon />
                  </div>
                  <IonCardSubtitle>
                    <div className={styles.elemElementTitle}>Loading...</div>
                  </IonCardSubtitle>
                  <IonCardTitle className={styles.elemElementDescription}>
                    <IonSpinner />
                  </IonCardTitle>
                </IonCardHeader>
              )}
              {!DISABLE_LOAD_MORE_RECEIVING_PRODUCTS && (
              <HxfInfiniteScroll
                
                containerElementRef={posContainerRef}
                requiresContainerHavingScrollbar={true}
                onLoadMore={() => {
                  if (infiniteScrollEnabledRef.current) {
                    setIsLoadingMore(true);
                    loadMore();
                  }
                }}
                allowedLoadPageRef={allowedInfiniteScrollLoadPageRef}
              />)}
            </div>)}
          </div>)}
          {failedToLoadPosError ? (
            <div className={styles.errorArea}>
              <CustomUnavailableErrorNotice />
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      {requiresNetworkLoadError ? (
        <div className={styles.errorArea}>
          <ErrorNoticeRequiresNetworkLoadObject />
        </div>
      ) : (
        <></>
      )}

      <div className={styles.confirmationBottomContainer}>
        <div className={styles.confirmationBottomContent}>
          <div className={styles.lineBreaker}></div>

          <div className={styles.centeredElem}>
            <IonButton
              disabled={failedToLoadPosError}
              data-btn={"receiving-confirm"}
              className={styles.confirmButton}
              onClick={() => {

                if (receivingProducts.length === 0) {
                  present({
                    buttons: [
                      {
                        text: "hide",
                        handler: () => {
                          console.log("called dismiss");
                        },
                      },
                    ],
                    message: t("not.added.any.products"),
                    onDidDismiss: () => console.log("dismissed"),
                    onWillDismiss: () => console.log("will dismiss"),
                  });
                  return;
                }

                let properLocationDefined = false;
                if (
                  (receivingLocation !== null &&
                    receivingLocation?.Id_WarehouseLocation &&
                    receivingLocation?.Id_Warehouse &&
                    receivingLocation?.Id_Lot) ||
                  (receivingLocation !== null &&
                    !receivingLocation?.Id_WarehouseLocation &&
                    !receivingLocation?.Id_Warehouse &&
                    !receivingLocation?.Id_Lot)
                ) {
                  properLocationDefined = true;
                }

                if (!properLocationDefined) {
                  /*present(
                    {
                        buttons: [{ text: 'hide', handler: () => { console.log("called dismiss"); } }],
                        message: 'Please define a location to receive stock',
                        onDidDismiss: () => console.log('dismissed'),
                        onWillDismiss: () => console.log('will dismiss')
                    }
                  );   */
                  setIsPopupReceivingLocationOpen(true);
                  return;
                }

                let receivingActionsSubmitData = productTransfActionsManager.getSubmitResult();
             
                let preparedReceivingProducts = [...receivingProducts];

                for (let i = 0; i < preparedReceivingProducts.length; i++) {
                  let idWarehouseLocation =
                    receivingLocation?.Id_WarehouseLocation
                      ? receivingLocation.Id_WarehouseLocation
                      : null;
                  let idWarehouse = receivingLocation?.Id_Warehouse
                    ? receivingLocation.Id_Warehouse
                    : null;
                  let idLot = receivingLocation?.Id_Lot
                    ? receivingLocation.Id_Lot
                    : null;

                  preparedReceivingProducts[i]["Id_WarehouseLocation"] =
                    idWarehouseLocation;
                  preparedReceivingProducts[i]["Id_Warehouse"] = idWarehouse;
                  preparedReceivingProducts[i]["Id_Lot"] = idLot;

                  if (idLot === "new") {
                    preparedReceivingProducts[i]["newLotNameCode"] =
                      receivingLocation.temp_lotLabel;
                  }

                  let receivingActionsData = receivingActionsSubmitData?.[preparedReceivingProducts[i].Id_ReceivingOrder_Product]?.resultingDataMap ? receivingActionsSubmitData?.[preparedReceivingProducts[i].Id_ReceivingOrder_Product]?.resultingDataMap : null;
                  if(receivingActionsData){
                    preparedReceivingProducts[i]["receivingActionsData"] = receivingActionsData;
                  }
                }
                setIsLoadingAction(true);
                receivingOrderProductsController()
                  .receiveReceivingOrderProducts(receivingProducts)
                  .then((res) => {
                    setIsLoadingAction(false);
                    if(res?.data?.code !== 200){
                      throw "unexpected error";
                    }
                    feedbackService.notifyToast(t("received.successfully"), "success");
                    //props.history.push("/");

                    resetCurrentStates(); //and reloads
                  })
                  .catch((res) => {
                    setIsLoadingAction(false);
                    feedbackService.notifyToast(t("generic.critical.error"), "error");
                  });
              }}
            >
              {t("confirm")}
            </IonButton>
          </div>
        </div>
      </div>
    </InAppTemplate>
  );
}

export default ReceivingOrderProductsShow;
