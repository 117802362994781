import HxfSelectorField from "../HxfSelectorField/HxfSelectorField";
import warehouseLocationsController from "../../../barrel/controllers/warehouseLocationsController";
import warehousesController from "../../../barrel/controllers/warehousesController";
import lotsController from "../../../barrel/controllers/lotsController";
import styles from "./HxfStockLocationForm.module.scss";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import { useState } from "react";
interface IHxfStockLocationForm {
  onWarehouseLocationChange: any;
  warehouseLocationValue: any;
  onWarehouseChange: any;
  warehouseValue: any;
  onLotChange: any;
  lotValue: any;
  warehouseSearchFilters: any;
  lotSearchFilters: any;
  callbackAddWarehouseLocationCacheItems: any;
  callbackAddWarehouseCacheItems: any;
  callbackAddLotCacheItems?: any;
  warehouseLocationError: boolean;
  warehouseError: boolean;
  lotError: boolean;
  defaultNoLot: any;
  disableNoLotUseDefault?:boolean;
  allowLotCreation: boolean | null;
}

function HxfStockLocationForm(props: IHxfStockLocationForm) {
  const {t} = useHxfTranslation();

  const [lotValue, setLotValue] = useState(props?.lotValue ? props.lotValue : null);

  return (
    <>
      <div className={styles.selectorLoc}>
        <HxfSelectorField
          dataInputFieldAttributeValue="hxf-stock-loc-form-whloc"
          error={props?.warehouseLocationError}
          callbackCacheSearchResults={
            props.callbackAddWarehouseLocationCacheItems
          }
          searchingFilterKey={"searchBarFilter"}
          disableInternalFiltering={true}
          onChange={(evt, val) => {
            if (props?.onWarehouseLocationChange) {
              props.onWarehouseLocationChange(val);
            }
          }}
          value={
            props?.warehouseLocationValue ? props.warehouseLocationValue : null
          }
          listJsonFunction={
            warehouseLocationsController().getWarehouseLocationsListJson
          }
          uniqueId={"whLoc_op_tgt_stock_form"}
          labelPlaceholder={t("select.a.warehouselocation")}
        />
      </div>
      <div className={styles.selectorLoc}>
        <HxfSelectorField
          dataInputFieldAttributeValue="hxf-stock-loc-form-wh"
          error={props?.warehouseError}
          callbackCacheSearchResults={props.callbackAddWarehouseCacheItems}
          searchingFilterKey={"searchBarFilter"}
          disableInternalFiltering={true}
          onChange={(evt, val) => {
            if (props?.onWarehouseChange) {
              props.onWarehouseChange(val);
            }
          }}
          disabled={
            !props.warehouseLocationValue?.value || props.warehouseLocationValue?.value === null
          }
          value={props?.warehouseValue ? props.warehouseValue : null}
          listJsonFunction={warehousesController().getWarehousesListJson}
          uniqueId={"wh_op_tgt_stock_form"}
          labelPlaceholder={t("select.a.warehouse")}
          listJsonOptions={{
            includeWarehouseLocationData: "true",
            includeLotDefaultNoLot: "true",
            ...props.warehouseSearchFilters,
          }}
        />
      </div>

      <div className={styles.selectorLoc}>
        <HxfSelectorField
        dataInputFieldAttributeValue="hxf-stock-loc-form-lot"
          allowCreation={props.allowLotCreation === true}
          creationSettings={{
            onCustomConditionAllowCreateNew:(filteredElements:any, inputtingValue:any) => {
              //todo guarantee finished request listjson
              if(props?.allowLotCreation){
                let didNotFindExactMatchLotCode = true;
               
              
                if(inputtingValue &&  inputtingValue !== ""){
               
                  for(let i = 0; i<filteredElements.length; i++){
                    if(filteredElements[i].fetchedData?.code === inputtingValue){
                      didNotFindExactMatchLotCode = false;
                    }
                  }

                  if(didNotFindExactMatchLotCode){
                    return true;
                  }
                }

              }
              return false;
            }
          }}
          //allowFreeTypePersist={props?.allowLotCreation ? true : false}
          error={props?.lotError}
          callbackCacheSearchResults={props.callbackAddLotCacheItems}
          disabled={
            !props.warehouseValue?.value || props.warehouseValue?.value === null
          }
          searchingFilterKey={"searchBarFilter"}
          disableInternalFiltering={true}
          onChange={(evt, val) => {
            if (props?.onLotChange) {
              props.onLotChange(val);
            }
          }}
          value={props?.lotValue ? props.lotValue : null}
          listJsonFunction={lotsController().getLotsListJson}
          uniqueId={"lot_op_tgt_stock_form"}
          labelPlaceholder={t("select.a.lot")}
          listJsonOptions={{
            includeWarehouseData: "true",
            includeWarehouseLocationData: "true",
            ...props.lotSearchFilters,
          }}
        />
        {props?.defaultNoLot && !props?.disableNoLotUseDefault ? (
          <>
            <small>({t("leave.empty.use.default")})</small>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default HxfStockLocationForm;
