import RvHookupIcon from '@mui/icons-material/RvHookup';
import useHxfTranslation from '../../../../../../../../barrel/hooks/useHxfTranslation';
import styles from "./OperationExternalTip.module.scss";
function OperationExternalTip(){
    const {t} = useHxfTranslation();
    
    return (
        <div className={styles.externalIndicatorContainer}>
            <div><RvHookupIcon/></div>
            <div className={styles.externalLabel}>{t('external')}</div>
        </div>
    )
}

export default OperationExternalTip;