function MathsService(){

    const bytesToGB = (bytes:any)  => {
        return bytes / (1024 ** 3);
    }
    const distanceBetweenTwoCoordinates = (x1:any,y1:any, x2:any,y2:any) => {
        let a = x1 - x2;
        let b = y1 - y2;

        let c = Math.sqrt( a*a + b*b );
        return c;
    }

    const roundTwoDecimals = (numb:any) => {
        let roundedVal = Math.round((numb + Number.EPSILON) * 100) / 100;

        return roundedVal;

    }

    /**
     * 
     * @param {*} value 
     * @param {*} maxPrecision 
     * @returns ex: 0.07*100 becomes 7.00000000001, if floatConvert(0.07*100) it returns: 7.00 but since it converts to float, it removes trailing zeroes. result is 7
     */
    const floatConvert = (value:any, maxPrecision:number) => {

        return parseFloat((value).toFixed(maxPrecision));
    }

    const truncateNumberDecimals = (value:any, maxDecimals:any) => {
        if (typeof value !== 'number' || isNaN(value)) {
            throw new Error('Invalid input: value must be a number');
        }
    
        if (typeof maxDecimals !== 'number' || isNaN(maxDecimals)) {
            throw new Error('Invalid input: maxDecimals must be a number');
        }
    
        const factor = 10 ** maxDecimals;
        const truncatedValue = Math.floor(value * factor) / factor;
    
        return truncatedValue;
    }

    return {bytesToGB,truncateNumberDecimals,distanceBetweenTwoCoordinates,roundTwoDecimals,floatConvert};

}

export default MathsService;