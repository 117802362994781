import { IonButton } from "@ionic/react";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import ASPTFMAAddingIrregularitiesComponent from "../../../../CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenProduceTransformationFromMachineAdjustment/Components/ASPTFMAAddingIrregularitiesComponent";
import styles from "./AddingIrregularTransformedProductsView.module.scss";
interface IProps{
    productBom:any;
    operationProductActionData?:any;
    irregularityData?:any;
    onUpdateOperationProductActionData?:(newObj:any) => void;
    onConfirm:()=>void;
}
function AddingIrregularTransformedProductsView(props:IProps){
    const {t} = useHxfTranslation();

    let getSelectedAdjustedProduct = () => {
        let LOAD_TRANSFORMATIONS_FROM_PRODUCT_BOM = true; //unlike the production which loads transformations, if the machine is no longer adjusted and we need to add irregularities, we can simply show the BoM of the worked product

        let irregularityData = props?.irregularityData;
        let ignoreProductsWithoutPvs = irregularityData?.resultingWaste_ignoreProductsWithoutPVS === 1;
         //only supports one
        let adjustedProducts = props?.operationProductActionData?.action_config?.adjustedProducts;
        if(adjustedProducts && adjustedProducts.length > 0){
            let adjustedProduct = adjustedProducts[0];
            let irregularTransformations = [];
            let transformations = adjustedProduct?.transformations ? adjustedProduct.transformations : [];
            if(!adjustedProduct?.irregularTransformations){
                if(LOAD_TRANSFORMATIONS_FROM_PRODUCT_BOM){
                    let arrayBomProducts = props.productBom;
                    
                    for(let j = 0; j<arrayBomProducts.length; j++){
                        let arrayppvvs = arrayBomProducts[j].arrayProductParameterizedVariables;
                        if(ignoreProductsWithoutPvs && !arrayppvvs || arrayppvvs.length === 0){
                            continue;
                        }
                        let irrTransform = {
                            Id_Product:arrayBomProducts[j].Id_ProductChild,
                            arrayProductParameterizedVariables: arrayppvvs,
                            irregularQuantity:0,
                            name:arrayBomProducts[j].childProduct_name,
                            code:arrayBomProducts[j].childProduct_code
                            
                        };
                        irregularTransformations.push(irrTransform);
                    }
                
                }else{
                    for(let j = 0; j<transformations.length; j++){

                        let irrTransform = JSON.parse(JSON.stringify(transformations[j]));
                        delete irrTransform.producedQuantity;
                        irrTransform.irregularQuantity = 0;
                        irregularTransformations.push(irrTransform);
                      }
                }


              adjustedProduct.irregularTransformations = irregularTransformations;
            

              
            }
            return adjustedProduct;
        }
        return null;
    }

    return (<>
    {getSelectedAdjustedProduct() ? (
        <div style={{height:'100%'}}>
        
            <div style={{height:'80%',overflow:'auto'}}>
                <div style={{marginBottom:'120px'}}>
                    <ASPTFMAAddingIrregularitiesComponent adjustedProduct={getSelectedAdjustedProduct()} onModifyIrregularQuantity={(idxTransformation:any, newQtIrregular:any) => {
                        let curAdjProduct = {...getSelectedAdjustedProduct()};

                        let irregularTransformations = [...curAdjProduct?.irregularTransformations];
                        irregularTransformations[idxTransformation].irregularQuantity = newQtIrregular;
                        curAdjProduct.irregularTransformations = irregularTransformations;

                        let newOperationProductActionData = {...props.operationProductActionData};
                        newOperationProductActionData.action_config.adjustedProducts[0] = curAdjProduct;
                        if(props?.onUpdateOperationProductActionData){
                            props.onUpdateOperationProductActionData(newOperationProductActionData);
                        }
                    }} />
                </div>
            </div>
            <div style={{height:'20%'}}>
                <div className={styles.separatorBar}></div>
                <div className={styles.optsContainer}>
                <IonButton
                    data-btn="collabStartNew"
                    disabled={false}
                    className={styles.confirmButton}
                    onClick={() => {
                        if(props?.onConfirm){
                            props.onConfirm();
                        }
                    }}
                    size="large"
                    >
                
                    {t("confirm")}
                </IonButton>
                </div>
            </div>
        </div>
    ) : (<>N/A</>)}
    
    </>)
}
export default AddingIrregularTransformedProductsView;