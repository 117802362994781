import { useEffect, useRef, useState } from "react";
import styles from "./HxfDigitKeyboard.module.scss";
import { utils_isNumeric } from "../../../barrel/utils/NumberUtils";
import BackspaceIcon from "@mui/icons-material/Backspace";
import Ripples from "react-ripples";
import HxfKeyRippleEffectCustom from "./HxfKeyRippleEffectCustom/HxfKeyRippleEffectCustom";
import UomService from "../../../barrel/services/uomService";
/**
 *
 * @param props uomLabel : ex: Kg, shows Kg next to the number
 * @returns
 */

interface IhxfDigitKeyboard {
  uomLabel?: string;
  Id_Selected_UoM?: number;
  uomConversionsData?: any;
  showErrorInput?: boolean;
  confirmationButtonEnabled?: null | boolean;
  onConfirmation?: (args?: any) => any;
  defaultValue?: any;
  onChange?: (args?: any) => void;
  enableButtonOnMount?: null | boolean;
  placeholder?: string | null;
  subPlaceholder?: string | null;
  maxValue?: number;
  enableScanners?: boolean; //enable for rfid scan
  disallowEdittingQuantity?:boolean; //allows clicking OK only

  disableParsingInput?:boolean;
}

const HxfDigitKeyboard = (props: IhxfDigitKeyboard) => {
  const ENABLE_INSTA_KEYPAD = false; //currently if the user is using a keyboard he needs to manually click the input and then he can type, disabled because this would also make the "code" visible if using a card, eventually turn into a setting?
  const TIME_CLEANUP = 250; //if we allow instakey pad the reset time must be inexistent. OR, if keys typed too fast within few ms's it is a "reader" otherwise it is the keyboard, test with scanners.
  const [insertedNumber, setInsertedNumber] = useState(
    props.defaultValue !== undefined ? props.defaultValue : ""
  );
  const maxInputLength = 14;
  const uomLabel = props.uomLabel != null ? props.uomLabel : "";
  const subPlaceholder = props?.subPlaceholder ? props.subPlaceholder : "";
  const [didMount, setDidMount] = useState(false);
  const confirmationButtonEnabled =
    props.confirmationButtonEnabled != null
      ? props.confirmationButtonEnabled
      : true;

  const [internalButtonEnabled, setInternalButtonEnabled] = useState(false);
  const [showErrorInput, setShowErrorInput] = useState(
    props.showErrorInput ? props.showErrorInput : false
  );

  const isInputFocused = useRef(false);

  const addNumber = (digit: any) => {
    if (insertedNumber.length + 1 > maxInputLength) {
      return;
    }

    let curInsertedNumber = insertedNumber;

    let newInserted = curInsertedNumber + digit.toString();
    /* if(digit == "."){
            digit = ".0";
        }*/

    let checkingNumber = newInserted;
    if (
      newInserted.length > 0 &&
      newInserted.charAt(newInserted.length - 1) == "."
    ) {
      checkingNumber = newInserted.replace(".", "");
    }
    let re = new RegExp("(^\\d*\\.?\\d*$)");
    if (re.test(checkingNumber) || checkingNumber == "") {
      setShowErrorInput(false);
      setInsertedNumber(newInserted);
    }
  };

  const deleteOneDigit = () => {
    let strNumber = insertedNumber.toString();
    let newInserted = strNumber.slice(0, -1);
    setInsertedNumber(newInserted);
    setShowErrorInput(false);
  };

  const calculatePaddingRight = () => {
    if (uomLabel !== null) {
      if (uomLabel.length >= 5) {
        return "55px";
      }
      if (uomLabel.length >= 4) {
        return "50px";
      }
    }

    return "50px";
  };

  const checkIfRespectsLimits = (value: number) => {
    if (props?.maxValue) {
      if (value > props.maxValue) {
        return false;
      }
    }

    return true;
  };

  const confirmationAction = (virtualInsertedNumber?: any) => {

    if (!confirmationButtonEnabled && !internalButtonEnabled) {
      return;
    }

    let usedInsertedNumber = virtualInsertedNumber
      ? virtualInsertedNumber
      : insertedNumber;
    let finalQuantity = 0;
    
    if(props.disableParsingInput){
      finalQuantity = usedInsertedNumber;
      if(props.onConfirmation){
        props.onConfirmation(finalQuantity);
      }
      return;
    }else{
      finalQuantity = parseFloat(usedInsertedNumber);
    }
    

    let respectsLimits = checkIfRespectsLimits(finalQuantity);

    if (!respectsLimits) {
      setShowErrorInput(true);
    }
    console.log("CLICKING #1");
    if (
      respectsLimits &&
      props !== null &&
      props.onConfirmation &&
      props.onConfirmation !== null
    ) {
      console.log("CLICKING #2");

      //check for uom conversion back to base
      if (props?.Id_Selected_UoM && props.Id_Selected_UoM !== -1) {
        let baseQty = UomService().convertQuantity(
          usedInsertedNumber,
          props.Id_Selected_UoM,
          -1,
          props.uomConversionsData
        );
        finalQuantity = baseQty;
      }
    
      props.onConfirmation(finalQuantity);
    }
  };
  useEffect(() => {
    if (props.onChange) {
      props.onChange(insertedNumber);
    }
  }, [insertedNumber, props]);
  useEffect(() => {
    if (props.showErrorInput) {
      setShowErrorInput(true);
    }
  }, [props.showErrorInput]);

  useEffect(() => {
    if (!didMount) {
      if (props.enableButtonOnMount) {
        setInternalButtonEnabled(true);
      }

      setDidMount(true);
    }
  }, [didMount, props.enableButtonOnMount]);

  //scanner detector functionality auto type
  const detectedPossibleScannedNumber = useRef("");
  const timerClearerRef: any = useRef(null);
  useEffect(() => {
    const handlerKeyPressDetect = (e: KeyboardEvent) => {
      // console.log('key detected', e);
      let keyTyped: any = e.key;
    

      if(isInputFocused.current){
        return;
      }

      if (!isNaN(keyTyped)) {
        //is a number

        detectedPossibleScannedNumber.current =
          detectedPossibleScannedNumber.current + "" + keyTyped;
        console.log(
          "Setting detectedPoss:",
          detectedPossibleScannedNumber.current
        );

        if(!isInputFocused.current && ENABLE_INSTA_KEYPAD){
          setInsertedNumber(detectedPossibleScannedNumber.current);
        }
        if (timerClearerRef?.current) {
          clearTimeout(timerClearerRef.current);
        }
        timerClearerRef.current = setTimeout(() => {

          if(isInputFocused.current){
            //input was focused manually
            return;
          }

          detectedPossibleScannedNumber.current = "";
          setInsertedNumber("");
          console.log("Cleared detected poss");
        }, TIME_CLEANUP);//250);
      }

      if (
        keyTyped === "Enter" &&
        detectedPossibleScannedNumber?.current &&
        detectedPossibleScannedNumber?.current !== ""
      ) {
        clearTimeout(timerClearerRef.current);
        console.log(
          "Confirming detected poss: ",
          detectedPossibleScannedNumber.current
        );
        setInsertedNumber(detectedPossibleScannedNumber.current);
        confirmationAction(detectedPossibleScannedNumber.current);
      }
    };
    if (props.enableScanners) {
      console.log("enabled scanenrs on");
      window.addEventListener("keydown", handlerKeyPressDetect, false);
      return () =>
        window.removeEventListener("keydown", handlerKeyPressDetect, false);
    }
    // eslint-disable-next-line
  }, []);
  //-----------------

  return (
    <div className={styles.pageContainer}>
      <div className={styles.digitKeyboardContainer}>
        <div className={styles.keyboardLines}>
          <div className={styles.keyboardLineContainer}>
            <div className={styles.keyInputDel}>
              <div
                data-keyboard-input-error={ showErrorInput ? true : false}
                className={`${styles.keyboardInput} ${
                  showErrorInput ? styles.inputError : ""
                }`}
              >
                <input
                  data-keyboard-input={"value_field"}
                  disabled={props?.disallowEdittingQuantity}
                  onKeyDown={(e) => {
                    if(!isInputFocused.current ){
                      return;
                    }
                    let keyTyped = e.key;
                    if (
                      keyTyped === "Enter"
                    ) {
            
                      confirmationAction();
                    }
                  }}
                  onFocus={() => {

                    isInputFocused.current = true;
                  }}
                  onBlur={() => {
                    isInputFocused.current = false;
                  }}
                  placeholder={props?.placeholder ? props.placeholder : ""}
                  type="text"
                  style={{ paddingRight: calculatePaddingRight() }}
                  value={insertedNumber}
                  onChange={(e: any) => {
                    let insertingD = e.target.value;

                    let numberofDots = insertingD.split(".").length - 1;
                    if (numberofDots > 1) {
                      return;
                    }

                    let checkingNumber = insertingD;
                    if (
                      insertingD.length > 0 &&
                      insertingD.charAt(insertingD.length - 1) == "."
                    ) {
                      checkingNumber = insertingD.replace(".", "");
                    }

                    if (insertingD.length > maxInputLength) {
                      return;
                    }
                    let re = new RegExp("(^\\d*\\.?\\d*$)");

                    if (!re.test(checkingNumber) && checkingNumber != "") {
                      return;
                    }

                    let respectsLimits = checkIfRespectsLimits(
                      parseFloat(checkingNumber)
                    );

                    if (!respectsLimits) {
                      setShowErrorInput(true);
                    } else {
                      setShowErrorInput(false);
                    }

                    setInsertedNumber(e.target.value);
                    console.log("E: ", e);
                  }}
                  autoFocus={false}
                  tabIndex={200}
                />
                {uomLabel !== null && (
                  <div className={styles.uomLabel}>
                    <div className={styles.uomLabelField}>{uomLabel}</div>
                  </div>
                )}
                {subPlaceholder && (
                  <div className={styles.subPlaceholderContainer}>
                    <div className={styles.subPlaceholder}>
                      {subPlaceholder}
                    </div>
                  </div>
                )}
              </div>
              <HxfKeyRippleEffectCustom overrideType={4}>
                <div
                  id="hxfDigitKeyboard_deleteKey"
                  className={`  ${styles.keyNoSelection} ${styles.keyboardDeleteKey} ${props?.disallowEdittingQuantity ? styles.disabledButton : ""}`}
                  onClick={() => {
                    if(props?.disallowEdittingQuantity){
                      return;
                    }
                    deleteOneDigit();
                  }}
                >
                  <BackspaceIcon />
                </div>
              </HxfKeyRippleEffectCustom>
            </div>
          </div>
          <div className={styles.keyboardLineContainer}>
            <HxfKeyRippleEffectCustom>
              <div
                id="hxfDigitKeyboard_key1"
                className={`${styles.keyNoSelection} ${styles.keyboardKey} ${props?.disallowEdittingQuantity ? styles.disabledButton : ""}`}
                onClick={() => {
                  if(props?.disallowEdittingQuantity){
                    return;
                  }
                  addNumber(1);
                }}
              >
                1
              </div>
            </HxfKeyRippleEffectCustom>

            <HxfKeyRippleEffectCustom>
              <div
              id="hxfDigitKeyboard_key2"
                className={`${styles.keyNoSelection} ${styles.keyboardKey} ${props?.disallowEdittingQuantity ? styles.disabledButton : ""}`}
                onClick={() => {
                  if(props?.disallowEdittingQuantity){
                    return;
                  }
                  addNumber(2);
                }}
              >
                2
              </div>
            </HxfKeyRippleEffectCustom>

            <HxfKeyRippleEffectCustom>
              <div
                id="hxfDigitKeyboard_key3"
                className={`${styles.keyNoSelection} ${styles.keyboardKey} ${props?.disallowEdittingQuantity ? styles.disabledButton : ""}`}
                onClick={() => {
                  if(props?.disallowEdittingQuantity){
                    return;
                  }
                  addNumber(3);
                }}
              >
                3
              </div>
            </HxfKeyRippleEffectCustom>
          </div>

          <div className={styles.keyboardLineContainer}>
            <HxfKeyRippleEffectCustom>
              <div
                id="hxfDigitKeyboard_key4"
                className={`${styles.keyNoSelection} ${styles.keyboardKey} ${props?.disallowEdittingQuantity ? styles.disabledButton : ""}`}
                onClick={() => {
                  if(props?.disallowEdittingQuantity){
                    return;
                  }
                  addNumber(4);
                }}
              >
                4
              </div>
            </HxfKeyRippleEffectCustom>

            <HxfKeyRippleEffectCustom>
              <div
                id="hxfDigitKeyboard_key5"
                className={`${styles.keyNoSelection} ${styles.keyboardKey} ${props?.disallowEdittingQuantity ? styles.disabledButton : ""}`}
                onClick={() => {
                  if(props?.disallowEdittingQuantity){
                    return;
                  }
                  addNumber(5);
                }}
              >
                5
              </div>
            </HxfKeyRippleEffectCustom>

            <HxfKeyRippleEffectCustom>
              <div
                id="hxfDigitKeyboard_key6"
                className={`${styles.keyNoSelection} ${styles.keyboardKey} ${props?.disallowEdittingQuantity ? styles.disabledButton : ""}`}
                onClick={() => {
                  if(props?.disallowEdittingQuantity){
                    return;
                  }
                  addNumber(6);
                }}
              >
                6
              </div>
            </HxfKeyRippleEffectCustom>
          </div>

          <div className={styles.keyboardLineContainer}>
            <HxfKeyRippleEffectCustom>
              <div
                id="hxfDigitKeyboard_key7"
                className={`${styles.keyNoSelection} ${styles.keyboardKey} ${props?.disallowEdittingQuantity ? styles.disabledButton : ""}`}
                onClick={() => {
                  if(props?.disallowEdittingQuantity){
                    return;
                  }
                  addNumber(7);
                }}
              >
                7
              </div>
            </HxfKeyRippleEffectCustom>
            <HxfKeyRippleEffectCustom>
              <div
                id="hxfDigitKeyboard_key8"
                className={`${styles.keyNoSelection} ${styles.keyboardKey} ${props?.disallowEdittingQuantity ? styles.disabledButton : ""}`}
                onClick={() => {
                  if(props?.disallowEdittingQuantity){
                    return;
                  }
                  addNumber(8);
                }}
              >
                8
              </div>
            </HxfKeyRippleEffectCustom>

            <HxfKeyRippleEffectCustom>
              <div
                id="hxfDigitKeyboard_key9"
                className={`${styles.keyNoSelection} ${styles.keyboardKey} ${props?.disallowEdittingQuantity ? styles.disabledButton : ""}`}
                onClick={() => {
                  if(props?.disallowEdittingQuantity){
                    return;
                  }
                  addNumber(9);
                }}
              >
                9
              </div>
            </HxfKeyRippleEffectCustom>
          </div>
          <div className={styles.keyboardLineContainer}>
            <HxfKeyRippleEffectCustom overrideType={4}>
              <div
                id="hxfDigitKeyboard_key0"
                className={`${styles.keyNoSelection} ${styles.twoSlotsKeyboardKey} ${props?.disallowEdittingQuantity ? styles.disabledButton : ""}`}
                onClick={() => {
                  if(props?.disallowEdittingQuantity){
                    return;
                  }
                  addNumber(0);
                }}
              >
                0
              </div>
            </HxfKeyRippleEffectCustom>
            <HxfKeyRippleEffectCustom>
              <div
                id="hxfDigitKeyboard_keyDot"
                className={`${styles.keyNoSelection} ${styles.keyboardKey} ${props?.disallowEdittingQuantity ? styles.disabledButton : ""}`}
                onClick={() => {
                  if(props?.disallowEdittingQuantity){
                    return;
                  }
                  if (
                    insertedNumber.length > 0 &&
                    !insertedNumber.includes(".")
                  ) {
                    addNumber(".");
                  }
                }}
              >
                .
              </div>
            </HxfKeyRippleEffectCustom>
          </div>
          <div className={styles.keyboardLineContainer}>
            <HxfKeyRippleEffectCustom>
              <div
                id="hxfDigitKeyboard_keyClear"
                className={`${styles.keyboardKey} ${styles.keyNoSelection} ${props?.disallowEdittingQuantity ? styles.disabledButton : ""}`}
                onClick={() => {
                  if(props?.disallowEdittingQuantity){
                    return;
                  }
                  setInsertedNumber("");
                }}
              >
                {" "}
                clr
              </div>
            </HxfKeyRippleEffectCustom>

            <HxfKeyRippleEffectCustom overrideType={4} effectEnabled={confirmationButtonEnabled}>
              <div
                id="hxfDigitKeyboard_keyOK"
                className={`${styles.twoSlotsKeyboardKey} ${
                  styles.confirmButton
                } ${styles.keyNoSelection} ${
                  !confirmationButtonEnabled && !internalButtonEnabled
                    ? styles.disabledButton
                    : ""
                }`}
                onClick={() => {
                  confirmationAction();
                }}
              >
                OK
              </div>
            </HxfKeyRippleEffectCustom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HxfDigitKeyboard;
