
import { IonButton, IonIcon } from "@ionic/react";
import { addOutline, removeOutline } from "ionicons/icons";
import useHxfTranslation from "../../../../../../../barrel/hooks/useHxfTranslation";
import HxfTextfieldNumber from "../../../../../HxfTextfieldNumber/HxfTextfieldNumber";


import styles from "./ASPTFMAAddingIrregularitiesComponent.module.scss";

interface IProps{
    adjustedProduct?:any;
    onModifyIrregularQuantity?:(index:any,newQt:any) => void;
}
function ASPTFMAAddingIrregularitiesComponent(props:IProps){

    const {t} = useHxfTranslation();

    const getIrregularQuantity = (indexIrregularTransformation:any) => {
        let selectedAdjustedProduct = props.adjustedProduct;
        let irregularTransformations = selectedAdjustedProduct?.irregularTransformations;
        if(irregularTransformations?.[indexIrregularTransformation]){
          return irregularTransformations?.[indexIrregularTransformation]?.irregularQuantity ? irregularTransformations?.[indexIrregularTransformation]?.irregularQuantity : 0;
        }
        return 0;
    }

    return (<>
        <div style={{textAlign:'center'}}><b>{t('irregular.units')}</b></div>
        {props.adjustedProduct && props.adjustedProduct?.irregularTransformations?.length > 0 && (
            <>
            {props.adjustedProduct?.irregularTransformations?.map((objResultingTransformProduct:any, index:any) => (
                <div key={"adj_restproduct_" + index} className={styles.irregularTransfProductContainer}>
                    <div style={{width:'100%',overflow:"hidden"}}>
                        <div className={styles.irrContainerTop}>
                            <b>{objResultingTransformProduct.name}</b>
                        </div>
                        <div style={{marginLeft:20,marginRight:20,marginTop:10,marginBottom:10,display:'flex',justifyContent:'center'}}>
                        {objResultingTransformProduct?.arrayProductParameterizedVariables?.length > 0 && (
                            <>
                            {objResultingTransformProduct?.arrayProductParameterizedVariables.map((objResTransProductPPV:any, indexPPV:any) => (
                                <div style={{marginLeft:5,marginRight:5}} key={"adjrestproduct_" + index +"_ppv_" + indexPPV}>
                                    <b>{objResTransProductPPV.name}:</b> {objResTransProductPPV.Value}
                                </div>
                            ))}
                            </>
                        )}
                        </div>
                        <div className={styles.inputContainerQtyWorked}>
                                    <IonButton
                                        data-btn={"irr-btn-subtract-idx-" + index}
                                        disabled={false}
                                        className={styles.addrembtn}
                                        onClick={() => {
                                        
                                            let qt = getIrregularQuantity(index);
                                            if(!qt){
                                                qt = 0;
                                            }

                                            let newQt = parseInt(qt) - 1;
                                            if(newQt < 0){
                                                newQt = 0;
                                            }
                                
                                
                                            //setIrregularQuantity(index, newQt);
                                            if(props?.onModifyIrregularQuantity){
                                                props.onModifyIrregularQuantity(index, newQt);
                                            }
                                            
                                        }}
                                    >
                                        <IonIcon slot="icon-only" icon={removeOutline}></IonIcon>
                                    </IonButton>

                                        <div>
                                        <HxfTextfieldNumber
                                        dataInputFieldAttributeValue={"input-irr-qty-idx-" + index}
                                        disabled={false}
                                    fullWidth={true} centerInputText={true} error={false} 
                                    label={t('quantity')} placeholder={t('quantity')} onChange={(evt:any) => {

                                        if(evt !== null && evt !== ""){
                                            evt = parseInt(evt); //ints only
                                        }
                                        //setIrregularQuantity(index, evt);
                                        if(props?.onModifyIrregularQuantity){
                                            props.onModifyIrregularQuantity(index, evt);
                                        }
                                    }} value={getIrregularQuantity(index) }/> 
                                        </div>


                                    <IonButton
                                    disabled={false}
                                        data-btn={"irr-btn-add-idx-" + index}
                                        className={styles.addrembtn}
                                        onClick={() => {
                                        
                                            let qt = getIrregularQuantity(index);
                                            if(!qt){
                                                qt = 0;
                                            }

                                            let newQt = parseInt(qt) +1;
                                            //setIrregularQuantity(index, newQt);
                                            if(props?.onModifyIrregularQuantity){
                                                props.onModifyIrregularQuantity(index, newQt);
                                            }
                                        }}
                                    >
                                        <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
                                    </IonButton>
                        </div>
                    </div>
                </div>
            ))}
            </>
        )}
        </>
    )
}

export default ASPTFMAAddingIrregularitiesComponent;