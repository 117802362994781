import OperationsAccessService, { IOperationOperationsAccessData } from "./operationsAccessService";

interface IAWTDOperationProduct{
    Id:any;
    UI_WS_productionOrderProductTimesExecutedOperation:any;
    UI_WS_productionOrderProductTotalOperationExecutionQuantity:any;
    temp_productionOrderProductTotalOperationExecutionQuantityIncludingSubcontract?:any;
    UI_WS_productionOrderProductTimesExecutedOperationRelevantUICapped:any;
    tempToday_productionOrderProductTimesExecutedOperation:any;
    tempToday_productionOrderProductTotalOperationExecutionQuantity:any;
    allowExternalRecords:any;
}

interface IWorkerTeamAccessibleProductOperation{
  Id:any; //operation id
}

function AvailableWorkTodoService(){

    const getWorkAvailableAndWorkDoneInfo = (operationsData:IAWTDOperationProduct[],entityAccountOperationsAccessData:IOperationOperationsAccessData | null = null, workerTeamAcessibleProductOperations:IWorkerTeamAccessibleProductOperation[] | null | "ALL" = null) => {
        //todo filter out operations this worker doesnt have access to
       
        let totalTimesDone = 0;
        let totalTimesMustBeDone = 0;
        let totalTimesDoneIgnoredOverflow = 0;

        let totalTodayTimesDone = 0;
        let totalTodayTimesMustBeDone = 0;
        let totalTodayTimesDoneIgnoredOverflow = 0;
       
        for (let i = 0; i < operationsData.length; i++) {

          let allowExternalRecords = operationsData[i]?.allowExternalRecords === 1;
          if(entityAccountOperationsAccessData){
            let hasAccess = OperationsAccessService().checkIfOperationsAccessArrayIncludesOperation(entityAccountOperationsAccessData, operationsData[i].Id);
            if(!hasAccess){
                continue;
            }
          }


          if(workerTeamAcessibleProductOperations && workerTeamAcessibleProductOperations !== "ALL"){
            let isAccessibleToThisWorkerTeams = false;
            for(let j = 0; j<workerTeamAcessibleProductOperations.length; j++){
              if(workerTeamAcessibleProductOperations[j].Id === operationsData[i].Id){
                isAccessibleToThisWorkerTeams = true;
                break;
              }
            }
            if(!isAccessibleToThisWorkerTeams){
              continue;
            }
          }


          let timesDone = operationsData[i].UI_WS_productionOrderProductTimesExecutedOperationRelevantUICapped; //temp_productionOrderProductTimesExecutedOperation;
          let timesMustBeDone = 0;
          
          if(allowExternalRecords){
            timesMustBeDone += operationsData[i]?.temp_productionOrderProductTotalOperationExecutionQuantityIncludingSubcontract;
          }else{
            timesMustBeDone += operationsData[i]?.UI_WS_productionOrderProductTotalOperationExecutionQuantity;
          }

          totalTimesDone += timesDone;
          totalTimesMustBeDone += timesMustBeDone;
          if(timesDone > timesMustBeDone){
            totalTimesDoneIgnoredOverflow += timesMustBeDone;
          }else{
            totalTimesDoneIgnoredOverflow += timesDone;
          }

          if(operationsData[i]?.hasOwnProperty('tempToday_productionOrderProductTimesExecutedOperation')){
            let todayTimesDone = operationsData[i].tempToday_productionOrderProductTimesExecutedOperation;
            let todayTimesMustBeDone = operationsData[i].tempToday_productionOrderProductTotalOperationExecutionQuantity;
            totalTodayTimesDone += todayTimesDone;
            totalTodayTimesMustBeDone += todayTimesMustBeDone;
            if(todayTimesDone > todayTimesMustBeDone){
              totalTodayTimesDoneIgnoredOverflow += todayTimesMustBeDone;
            }else{
              totalTodayTimesDoneIgnoredOverflow += todayTimesDone;
            }
  
          }


        }
    

        return {
          totalTimesDone:totalTimesDone,
          totalTimesMustBeDone:totalTimesMustBeDone,
          totalTimesDoneIgnoredOverflow:totalTimesDoneIgnoredOverflow,
          totalTodayTimesDone:totalTodayTimesDone,
          totalTodayTimesMustBeDone:totalTodayTimesMustBeDone,
          totalTodayTimesDoneIgnoredOverflow:totalTodayTimesDoneIgnoredOverflow
        };
    
    }

    return {
        getWorkAvailableAndWorkDoneInfo
    };
}

export default AvailableWorkTodoService;