
import GenericListJsonService from "../services/genericListJsonService";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
function inventoryEvaluationOrderRecordsController() {


  const getListJson = (
    options: any = { page: 0, size: 10, openOnly: true }, postOptions = {}
  ) => {
    let requestUrl = "inventory-evaluation-order-records/listJson/?";



    requestUrl = GenericListJsonService().getUrlFromOptions(requestUrl, {...options});

    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      postOptions
    );
  };

  const cancelRecord = (data:any) => {
    
    let requestUrl = "inventory-evaluation-order-record/cancel";


    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      data
    );
  }
  return { getListJson , cancelRecord};
}

export default inventoryEvaluationOrderRecordsController;
