import { useCustomPopupsStore } from "./CustomPopupsStore";
import { withRouter } from "react-router";
import OnGoingProductionsPopupDeprecated from "./OnGoingProductionsPopup/OnGoingProductionsPopupDeprecated";
import AssociatePreviousProductionRecordPopup from "./AssociatePreviousProductionRecordPopup/AssociatePreviousProductionRecordPopup";
import MultiPopProductionsStarterPopup from "./MultiPopProductionsStarterPopup/MultiPopProductionsStarterPopup";
import OnGoingMultiProductionsPopup from "./OnGoingMultiProductionsPopup/OnGoingMultiProductionsPopup";
import ZebraPrintersPopup from "./ZebraPrintersPopup/ZebraPrintersPopup";
import GenericOverlayPopup from "./GenericOverlayPopup/GenericOverlayPopup";
import CustomIntegrationsPopup from "./CustomIntegrationsPopup/CustomIntegrationsPopup";
import CollabCancelPopup from "./CollabCancelPopup/CollabCancelPopup";
function CustomSystemPopups(props: any) {
  return (
    <>
      <OnGoingMultiProductionsPopup />
      <AssociatePreviousProductionRecordPopup />
      <MultiPopProductionsStarterPopup />
      <ZebraPrintersPopup />
      <GenericOverlayPopup/>
      <CustomIntegrationsPopup/>
      <CollabCancelPopup/>
      
    </>
  );
}

export default withRouter(CustomSystemPopups);
