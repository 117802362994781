
import styles from "./PreActionSelectPopSubcontractConfirmButtons.module.scss";

import { IonButton } from "@ionic/react";
import useHxfTranslation from "../../../../../../../../barrel/hooks/useHxfTranslation";

interface IProps {
  onClickConfirm: any;
  typeShow: any;
  enabled: any;
}

function PreActionSelectPopSubcontractConfirmButtons(props: IProps) {
  const {t} = useHxfTranslation();
  
  return (
    <div className={styles.container}>
      <div className={styles.lineBreaker}></div>
      <div className={styles.buttonsContainer}>
        <div className={styles.confirmationContainer}>
          <IonButton
            data-btn={"confirm-selected-machine"}
            disabled={props?.enabled ? !props.enabled : true}
            className={styles.confirmButton}
            onClick={() => {
              if (props.onClickConfirm) {
                props.onClickConfirm();
              }
            }}
          >
            {props.typeShow === "CONFIRM" ? t("confirm") : t("confirm")}
          </IonButton>
        </div>
      </div>
    </div>
  );
}

export default PreActionSelectPopSubcontractConfirmButtons;
